import type { GraphQLClient, RequestOptions } from 'graphql-request';
import gql from 'graphql-tag';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
type GraphQLClientRequestHeaders = RequestOptions['requestHeaders'];
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  DateTime: { input: string; output: string; }
  JSONObject: { input: Record<string, unknown>; output: Record<string, unknown>; }
  Upload: { input: string; output: string; }
};

export type AddOrderStatusesToOrderStatusSetInput = {
  /** The ids of the order statuses that will be added to the set */
  orderStatusIds: Array<Scalars['Int']['input']>;
};

/** Address types [one of 'delivery','invoice' or 'home'] */
export enum AddressType {
  /** Delivery address */
  delivery = 'delivery',
  /** Home address */
  home = 'home',
  /** Invoice address */
  invoice = 'invoice'
}

/** Supported attribute classes. Objects from this enum can have attributes. */
export enum AttributeClass {
  contact = 'contact',
  customer = 'customer',
  product = 'product'
}

export type AttributeDescriptionSearchInput = {
  class: Scalars['String']['input'];
  /** An array of attributes groups you want to return. */
  group?: InputMaybe<Array<Scalars['String']['input']>>;
  /**
   * Only show attributes that are either hidden or not hidden.
   *     Ommmit this argument to ignore this field
   */
  isHidden?: InputMaybe<Scalars['Boolean']['input']>;
  /**
   * Only show attributes that are either public or not public.
   *     Ommmit this argument to ignore this field
   */
  isPublic?: InputMaybe<Scalars['Boolean']['input']>;
  /**
   * Only show attributes that are either searchable or not searchable.
   *     Ommmit this argument to ignore this field
   */
  isSearchable?: InputMaybe<Scalars['Boolean']['input']>;
  /** An array of attributes names you want to return. */
  name?: InputMaybe<Array<Scalars['String']['input']>>;
  offset?: Scalars['Int']['input'];
  page?: Scalars['Int']['input'];
  /** An array of attributes types you want to return. */
  type?: InputMaybe<Array<AttributeType>>;
};

export type AttributeFilterInput = {
  /** An array of attributes groups you want to return. */
  group?: InputMaybe<Array<Scalars['String']['input']>>;
  /**
   * Only show attributes that are either hidden or not hidden.
   *     Ommmit this argument to ignore this field
   */
  isHidden?: InputMaybe<Scalars['Boolean']['input']>;
  /**
   * Only show attributes that are either public or not public.
   *     Ommmit this argument to ignore this field
   */
  isPublic?: InputMaybe<Scalars['Boolean']['input']>;
  /**
   * Only show attributes that are either searchable or not searchable.
   *     Ommmit this argument to ignore this field
   */
  isSearchable?: InputMaybe<Scalars['Boolean']['input']>;
  /** An array of attributes names you want to return. */
  name?: InputMaybe<Array<Scalars['String']['input']>>;
  offset?: Scalars['Int']['input'];
  page?: Scalars['Int']['input'];
  /** An array of attributes types you want to return. */
  type?: InputMaybe<Array<AttributeType>>;
};

/** All attribute types */
export enum AttributeType {
  color = 'color',
  date = 'date',
  datetime = 'datetime',
  decimal = 'decimal',
  enum = 'enum',
  enumlist = 'enumlist',
  integer = 'integer',
  list = 'list',
  object = 'object',
  text = 'text'
}

export type AttributeValueObjectSearchInput = {
  /** Object class for which the search applies to. */
  class: AttributeClass;
  /** Decimal value for the attribute, required when attribute type equals decimal. */
  decimalValue?: InputMaybe<Scalars['Float']['input']>;
  /** Enumeration value for the attribute, required when type equals enum or enumlist. Values in this field can be multivalue. */
  enumValue?: InputMaybe<Array<Scalars['String']['input']>>;
  /** Integer value for the attribute, required when attribute type equals numeric or object. */
  intValue?: InputMaybe<Scalars['Int']['input']>;
  /** Only search attributes that are either hidden or not hidden. Ommmit this argument to ignore this field */
  isHidden?: InputMaybe<Scalars['Boolean']['input']>;
  /** Only search attributes that are either public or not public. Ommmit this argument to ignore this field */
  isPublic?: InputMaybe<Scalars['Boolean']['input']>;
  /** Only search attributes that are either searchable or not searchable. Ommmit this argument to ignore this field */
  isSearchable?: InputMaybe<Scalars['Boolean']['input']>;
  /** Attribute name for which the search is applied for. */
  name: Scalars['String']['input'];
  /** Textual value(s) for the attribute, required when attribute type equals text, list or color. This value is suitable for Localization. */
  textValue?: InputMaybe<LocalizedStringArrayInput>;
  /** Attribute type. */
  type: AttributeType;
};

/** Auto image optimization */
export enum Auto {
  /** If the browser's Accept header indicates compatibility, deliver an AVIF image. */
  AVIF = 'AVIF',
  /** If the browser's Accept header indicates compatibility, deliver a WebP image. */
  WEBP = 'WEBP'
}

export type Base64FileInput = {
  /** The base64 representation of the file contents. */
  base64: Scalars['String']['input'];
  /** The contentType of the base64 content. */
  contentType: Scalars['String']['input'];
  /** Indication of the fileName to use when converting the base64 to a file. */
  fileName: Scalars['String']['input'];
};

export type BundleAddItemsInput = {
  /** Items for this bundle. */
  items?: InputMaybe<Array<BundleItemInput>>;
};

/** Bundle discount condition */
export enum BundleCondition {
  ALL = 'ALL',
  EP = 'EP'
}

export type BundleItemInput = {
  /** Indicates whether the product is leader for this bundle. */
  isLeader?: InputMaybe<YesNo>;
  /** The bundles primary product identifier */
  productId: Scalars['Int']['input'];
};

export type BundleSearchInput = {
  /** Bundle identifier */
  id: Scalars['Int']['input'];
  /** Tax zone */
  taxZone?: InputMaybe<Scalars['String']['input']>;
};

export type BusinessHoursInput = {
  closingTime?: InputMaybe<Scalars['String']['input']>;
  dayOfWeek: Scalars['Float']['input'];
  lunchBeakEndTime?: InputMaybe<Scalars['String']['input']>;
  lunchBeakStartTime?: InputMaybe<Scalars['String']['input']>;
  openingTime?: InputMaybe<Scalars['String']['input']>;
};

/**
 * Cache Control Input options!
 *
 *   Defaults:
 *
 *   	- type=public
 *
 *   	- maxAge=7days
 */
export type CacheControlInput = {
  /** Cache max-age value */
  maxAge?: InputMaybe<Scalars['Int']['input']>;
  /** Cache control type */
  type?: InputMaybe<CacheControlType>;
};

/** Cache Control Type [no-store, public or none] */
export enum CacheControlType {
  /** Adds 'no-store' to the Cache Control header */
  NO_STORE = 'NO_STORE',
  /** Adds 'public' to the Cache Control header */
  PUBLIC = 'PUBLIC'
}

/**
 * Change the size of the image canvas.
 *
 * Sets the size of the image canvas, without changing the size of the image itself, which has the effect of adding space around the image.
 *
 * This parameter takes multiple values. The first two represent the desired width and height, either as measurements of pixels, separated with a comma, or as a ratio, separated with a colon. The remaining parameters allow the placement of the image within the canvas to be adjusted. On each dimension, placement can be made either with a position coordinate (x or y, which are relative to the top left of the newly-enlarged canvas) or as a percentage offset from the center of the image using offset-x and offset-y. These can be mixed and matched, but only one method can be used for each dimension (i.e., x can be combined with offset-y but x cannot be combined with offset-x).
 *
 * The remaining parameters determine the position of the cropped region.
 *
 * Offset positioning acts to distribute the remaining space according to the specified offset proportions. For example, offset-y10 would place the image so that 10% of the leftover space is above the image and 90% below it.
 *
 * Notes
 * - x and y can be set as a value in pixels (e.g., 40 is 40 pixels) or as a percentage suffixed with p (e.g., 50p is 50%).
 * - When x and y are percentages, they are calculated as percentages of the image size, not the canvas size.
 * - offset-x and offset-y are always interpreted as percentages of the canvas size (e.g., 25 is 25%).
 * - If no x, y, offset-x, or offset-y parameters are supplied, the image is positioned in the center of the canvas.
 * - The background color of the canvas will default to transparency for image output formats that support transparency and white for formats that don't. This behavior can be changed by adding the bg-color parameter.
 * - When using canvas and pad at the same time, pad will be ignored.
 * - Any fractional pixel measurements will be rounded to the nearest whole pixel.
 */
export type CanvasInput = {
  /** Height as Pixels or Percentage */
  height?: InputMaybe<Scalars['Int']['input']>;
  /** HeightRatio as Number */
  heightRatio?: InputMaybe<Scalars['Int']['input']>;
  /** X Offset as Percentage */
  offsetX?: InputMaybe<Scalars['Int']['input']>;
  /** Y Offset as Percentage */
  offsetY?: InputMaybe<Scalars['Int']['input']>;
  /** Width as Pixels or Percentage */
  width?: InputMaybe<Scalars['Int']['input']>;
  /** WidthRatio as Number */
  widthRatio?: InputMaybe<Scalars['Int']['input']>;
  /** X as Pixels or Percentage */
  x?: InputMaybe<Scalars['Int']['input']>;
  /** Y as Pixels or Percentage */
  y?: InputMaybe<Scalars['Int']['input']>;
};

export type CarrierCreateInput = {
  /** Carrier descriptions per language */
  descriptions: Array<LocalizedStringInput>;
  /** Logo url, must be public reachable url [maxLogoSize: 200kB, maxLogoDimensions: 200x200px ] */
  logo?: InputMaybe<Scalars['String']['input']>;
  /** Carrier unique name */
  name: Scalars['String']['input'];
  /** Carrier specific shipping cost */
  shippingCost: Scalars['Float']['input'];
  /** Track and Trace redirect URL */
  trackAndTraceURL?: InputMaybe<Scalars['String']['input']>;
  /** Carrier type! One of: [PICKUP, DELIVERY] */
  type: CarrierType;
  /** List of supported warehouse ids for this carrier */
  warehouseIds?: InputMaybe<Array<Scalars['Int']['input']>>;
};

export enum CarrierSortField {
  CREATED_AT = 'CREATED_AT',
  ID = 'ID',
  LAST_MODIFIED_AT = 'LAST_MODIFIED_AT',
  NAME = 'NAME'
}

export type CarrierSortInput = {
  /** Field to sort by */
  field: CarrierSortField;
  /** Order option to sort by. [Default to `ASC`] */
  order: SortOrder;
};

export enum CarrierType {
  DELIVERY = 'DELIVERY',
  PICKUP = 'PICKUP'
}

export type CarrierUpdateInput = {
  /** Carrier descriptions per language */
  descriptions?: InputMaybe<Array<LocalizedStringInput>>;
  /** Logo url, must be public reachable url [maxLogoSize: 200kB, maxLogoDimensions: 200x200px ] */
  logo?: InputMaybe<Scalars['String']['input']>;
  /** Carrier unique name */
  name?: InputMaybe<Scalars['String']['input']>;
  /** Carrier specific shipping cost */
  shippingCost?: InputMaybe<Scalars['Float']['input']>;
  /** Track and Trace redirect URL */
  trackAndTraceURL?: InputMaybe<Scalars['String']['input']>;
  /** Carrier type! One of: [PICKUP, DELIVERY] */
  type?: InputMaybe<CarrierType>;
  /** List of supported warehouse ids for this carrier */
  warehouseIds?: InputMaybe<Array<Scalars['Int']['input']>>;
};

export type CarriersSearchInput = {
  /** Search by date created */
  createdAt?: InputMaybe<DateSearchInput>;
  /** Search by ids */
  ids?: InputMaybe<Array<Scalars['Int']['input']>>;
  /** Search by date changed */
  lastModifiedAt?: InputMaybe<DateSearchInput>;
  /** Search by carrier names */
  names?: InputMaybe<Array<Scalars['String']['input']>>;
  /** Pagination offset number. [Default to `12`] */
  offset?: InputMaybe<Scalars['Int']['input']>;
  /** Pagination page number. [Default to `1`] */
  page?: InputMaybe<Scalars['Int']['input']>;
  /** Inputs to sort by */
  sortInputs?: InputMaybe<Array<CarrierSortInput>>;
  /** Search by carrier types */
  types?: InputMaybe<Array<CarrierType>>;
  /** Search by warehouse ids */
  warehouseIds?: InputMaybe<Array<Scalars['Int']['input']>>;
};

export type CartAddBundleInput = {
  /** Bundle ID of the cart item. */
  bundleId: Scalars['Int']['input'];
  /** Cart identifier for this cart item. */
  cartId: Scalars['String']['input'];
  notes?: InputMaybe<Scalars['String']['input']>;
  /** Quantity value for this cart item. */
  quantity?: InputMaybe<Scalars['Int']['input']>;
};

export type CartAddItemInput = {
  /** Cart identifier for this cart item. */
  cartId: Scalars['String']['input'];
  /** Adds child items to this cart item, this field is used when adding configurable clusters to the Cart. */
  childItems?: InputMaybe<Array<CartChildItemInput>>;
  notes?: InputMaybe<Scalars['String']['input']>;
  /** Cart item unit price. Defaults to calculated price. Only use for external pricing. */
  price?: InputMaybe<Scalars['Float']['input']>;
  /** Product ID of the cart item. */
  productId: Scalars['Int']['input'];
  /** Quantity value for this cart item. */
  quantity?: InputMaybe<Scalars['Int']['input']>;
};

/** Address types for Carts */
export enum CartAddressType {
  delivery = 'delivery',
  invoice = 'invoice'
}

export type CartChildItemInput = {
  /** Cart item unit price. Defaults to calculated price. Only use for external pricing. */
  price?: InputMaybe<Scalars['Float']['input']>;
  /** Product ID of the child item. */
  productId: Scalars['Int']['input'];
};

export type CartDeleteItemInput = {
  /** Cart identifier. */
  cartId: Scalars['String']['input'];
  /** Cart item identifier. */
  itemId: Scalars['Int']['input'];
};

export type CartPaymentDataInput = {
  /** The selected paymethod for this cart. Defaults to REKENING. */
  method?: InputMaybe<Scalars['String']['input']>;
};

export type CartPostageDataInput = {
  /** Is partial delivery allowed for this cart. */
  partialDeliveryAllowed?: InputMaybe<YesNo>;
  /** Postage cost excluding VAT based on external postage calculation. If value is set, postage priceMode is set to 'EXTERNAL' for this cart. */
  postage?: InputMaybe<Scalars['Float']['input']>;
  /** The preferred delivery date for this order as requested by the user. */
  requestDate?: InputMaybe<Scalars['DateTime']['input']>;
  /** The selected shipping method for this cart. Defaults to VERZENDEN. */
  shippingMethod?: InputMaybe<Scalars['String']['input']>;
};

export type CartProcessInput = {
  /** Cart identifier. */
  cartId: Scalars['String']['input'];
  /** Language of the order. Defaults to user's primary language */
  language?: InputMaybe<Scalars['String']['input']>;
  /** Order status for this cart. */
  orderStatus: Scalars['String']['input'];
};

export type CartSetUserInput = {
  /** Cart identifier. */
  cartId: Scalars['String']['input'];
  /** User identifier for this cart. */
  userId: Scalars['Int']['input'];
};

export type CartUpdateAddressInput = {
  /** Cart identifier. */
  cartId: Scalars['String']['input'];
  /** City. */
  city: Scalars['String']['input'];
  /** Address code. */
  code?: InputMaybe<Scalars['String']['input']>;
  /** Company name. */
  company?: InputMaybe<Scalars['String']['input']>;
  /** Country. */
  country?: InputMaybe<Scalars['String']['input']>;
  /** Email address. */
  email?: InputMaybe<Scalars['String']['input']>;
  /** First name. */
  firstName: Scalars['String']['input'];
  /** Gender. */
  gender?: InputMaybe<Gender>;
  /** Marks wether this address an ICP address. */
  icp?: InputMaybe<YesNo>;
  /** Last name. */
  lastName: Scalars['String']['input'];
  /** Middle name. */
  middleName?: InputMaybe<Scalars['String']['input']>;
  /** Mobile phone number. */
  mobile?: InputMaybe<Scalars['String']['input']>;
  /** Customer's address notes. */
  notes?: InputMaybe<Scalars['String']['input']>;
  /** House number. */
  number?: InputMaybe<Scalars['String']['input']>;
  /** House number extension. */
  numberExtension?: InputMaybe<Scalars['String']['input']>;
  /** Phone number. */
  phone?: InputMaybe<Scalars['String']['input']>;
  /** Postal code. */
  postalCode: Scalars['String']['input'];
  /** Region. */
  region?: InputMaybe<Scalars['String']['input']>;
  /** Street. */
  street: Scalars['String']['input'];
  /** Address type. */
  type: CartAddressType;
  /** Url. */
  url?: InputMaybe<Scalars['String']['input']>;
};

export type CartUpdateInput = {
  /** The selected carrier for this cart. */
  carrier?: InputMaybe<Scalars['String']['input']>;
  /** Cart identifier. */
  cartId: Scalars['String']['input'];
  /** Additional information field that can be stored with a cart and order. */
  extra3?: InputMaybe<Scalars['String']['input']>;
  /** Additional information field that can be stored with a cart and order. */
  extra4?: InputMaybe<Scalars['String']['input']>;
  /** Customer's notes for this cart. */
  notes?: InputMaybe<Scalars['String']['input']>;
  /** Payment data for this cart. */
  paymentData?: InputMaybe<CartPaymentDataInput>;
  /** Postage data for this cart. */
  postageData?: InputMaybe<CartPostageDataInput>;
  /** Customer's reference for this cart. */
  reference?: InputMaybe<Scalars['String']['input']>;
};

export type CartUpdateItemInput = {
  /** Cart identifier for this cart item. */
  cartId: Scalars['String']['input'];
  /** Cart item identifier. */
  itemId: Scalars['Int']['input'];
  notes?: InputMaybe<Scalars['String']['input']>;
  /** Cart item unit price. Defaults to calculated price. Only use for external pricing. */
  price?: InputMaybe<Scalars['Float']['input']>;
  /** Quantity value for this cart item. */
  quantity?: InputMaybe<Scalars['Int']['input']>;
};

export type CartUpdateItemsInput = {
  /** Cart item identifier. */
  itemId: Scalars['Int']['input'];
  notes?: InputMaybe<Scalars['String']['input']>;
  /** Cart item unit price. Defaults to calculated price. Only use for external pricing. */
  price?: InputMaybe<Scalars['Float']['input']>;
  /** Quantity value for this cart item. */
  quantity?: InputMaybe<Scalars['Int']['input']>;
};

export type CategorySearchInput = {
  /** Find categories by one or more categoryIds */
  categoryId?: InputMaybe<Array<Scalars['Int']['input']>>;
  /** The language to use when searching categories by name or slug. When omitted the categories default language is used */
  language?: InputMaybe<Scalars['String']['input']>;
  /** Find categories by name */
  name?: InputMaybe<Scalars['String']['input']>;
  /** The amount of categories return per page */
  offset?: InputMaybe<Scalars['Int']['input']>;
  /** The page number to return */
  page?: InputMaybe<Scalars['Int']['input']>;
  /** Find categories by one or more parentCategoryIds */
  parentCategoryId?: InputMaybe<Array<Scalars['Int']['input']>>;
  /** Find categories by one or more slugs */
  slug?: InputMaybe<Array<Scalars['String']['input']>>;
  /** Available sortable fields One of: [name, dateCreated, entityDateChanged] */
  sortField?: InputMaybe<CategorySortableFields>;
  /** Sort ordering ['desc' or 'asc'] */
  sortOrder?: SortOrder;
};

/** Fields available for sorting categories */
export enum CategorySortableFields {
  dateChanged = 'dateChanged',
  dateCreated = 'dateCreated',
  name = 'name'
}

/** Ways to dsplay a cluster's drilldown selection. */
export enum ClusterDrillDownDisplayTypes {
  color = 'color',
  dropdown = 'dropdown',
  image = 'image',
  radio = 'radio'
}

export type CompanyAddressCreateInput = {
  /** Address city */
  city: Scalars['String']['input'];
  /** Address code */
  code?: InputMaybe<Scalars['String']['input']>;
  /** User company the address applies to */
  company?: InputMaybe<Scalars['String']['input']>;
  /** Address company primary identifier */
  companyId: Scalars['Int']['input'];
  /** Address country */
  country: Scalars['String']['input'];
  /** Address email [valid email format required] */
  email?: InputMaybe<Scalars['String']['input']>;
  /** User first name the address applies to */
  firstName?: InputMaybe<Scalars['String']['input']>;
  /** User gender the address applies to */
  gender?: InputMaybe<Gender>;
  /** ICP address field defines whether tax should be applied to an order if the address is selected as a delivery address. Used when shipping b2b order abroad */
  icp?: InputMaybe<YesNo>;
  /** Indicates whether the address is default for its type */
  isDefault?: InputMaybe<YesNo>;
  /** User last name the address applies to */
  lastName?: InputMaybe<Scalars['String']['input']>;
  /** User middle name the address applies to */
  middleName?: InputMaybe<Scalars['String']['input']>;
  /** Address mobile number [min: 6, max: 30] */
  mobile?: InputMaybe<Scalars['String']['input']>;
  /** Address delivery notes */
  notes?: InputMaybe<Scalars['String']['input']>;
  /** User street number */
  number?: InputMaybe<Scalars['String']['input']>;
  /** User street number extension */
  numberExtension?: InputMaybe<Scalars['String']['input']>;
  /** Address fixed phone number [min: 6, max: 30] */
  phone?: InputMaybe<Scalars['String']['input']>;
  /** Address postal code */
  postalCode: Scalars['String']['input'];
  /** Address region */
  region?: InputMaybe<Scalars['String']['input']>;
  /** User street address */
  street: Scalars['String']['input'];
  /** Address type [one of 'home', 'delivery' or 'invoice'] */
  type: AddressType;
};

export type CompanyAddressDeleteInput = {
  /** Company primary identifier */
  companyId: Scalars['Int']['input'];
  /** Address primary identifier */
  id: Scalars['Int']['input'];
};

export type CompanyAddressUpdateInput = {
  /** Address city */
  city?: InputMaybe<Scalars['String']['input']>;
  /** Address code */
  code?: InputMaybe<Scalars['String']['input']>;
  /** User company the address applies to */
  company?: InputMaybe<Scalars['String']['input']>;
  /** Address company primary identifier */
  companyId: Scalars['Int']['input'];
  /** Address country */
  country?: InputMaybe<Scalars['String']['input']>;
  /** Address email [valid email format required] */
  email?: InputMaybe<Scalars['String']['input']>;
  /** User first name the address applies to */
  firstName?: InputMaybe<Scalars['String']['input']>;
  /** User gender the address applies to */
  gender?: InputMaybe<Gender>;
  /** ICP address field defines whether tax should be applied to an order if the address is selected as a delivery address. Used when shipping b2b order abroad */
  icp?: InputMaybe<YesNo>;
  /** Address primary identifier */
  id: Scalars['Int']['input'];
  /** Indicates whether the address is default for its type */
  isDefault?: InputMaybe<YesNo>;
  /** User last name the address applies to */
  lastName?: InputMaybe<Scalars['String']['input']>;
  /** User middle name the address applies to */
  middleName?: InputMaybe<Scalars['String']['input']>;
  /** Address mobile number [min: 6, max: 30] */
  mobile?: InputMaybe<Scalars['String']['input']>;
  /** Address delivery notes */
  notes?: InputMaybe<Scalars['String']['input']>;
  /** User street number */
  number?: InputMaybe<Scalars['String']['input']>;
  /** User street number extension */
  numberExtension?: InputMaybe<Scalars['String']['input']>;
  /** Address fixed phone number [min: 6, max: 30] */
  phone?: InputMaybe<Scalars['String']['input']>;
  /** Address postal code */
  postalCode?: InputMaybe<Scalars['String']['input']>;
  /** Address region */
  region?: InputMaybe<Scalars['String']['input']>;
  /** User street address */
  street?: InputMaybe<Scalars['String']['input']>;
};

export type CompanySearchArguments = {
  /** List of unique company ids */
  companyIds?: InputMaybe<Array<Scalars['Int']['input']>>;
  /** Search by date changed */
  lastModifiedDate?: InputMaybe<DateSearchInput>;
  /** When true, only companies managed by the viewer will be listed */
  managedCompaniesOnly?: InputMaybe<Scalars['Boolean']['input']>;
  /** Company's name */
  name?: InputMaybe<Scalars['String']['input']>;
  offset?: Scalars['Int']['input'];
  page?: Scalars['Int']['input'];
  /** List of company sort filters */
  sort?: InputMaybe<Array<CompanySortInput>>;
  usergroupId?: InputMaybe<Scalars['Int']['input']>;
};

export type CompanySortInput = {
  /** Available sortable fields */
  field: CompanySortableFields;
  /** Sort ordering ['desc' or 'asc'] */
  order?: SortOrder;
};

/** Company sortable fields */
export enum CompanySortableFields {
  lastModifiedDate = 'lastModifiedDate',
  name = 'name'
}

export type ContactSearchArguments = {
  /** Search by Customer's attributes */
  attribute?: InputMaybe<UserManagementAttributeSearchInput>;
  /** List of unique contact ids */
  contactIds?: InputMaybe<Array<Scalars['Int']['input']>>;
  /** Contact's email */
  email?: InputMaybe<Scalars['String']['input']>;
  /** Contact's first name */
  firstName?: InputMaybe<Scalars['String']['input']>;
  /** Contact's gender */
  gender?: InputMaybe<Gender>;
  /** Search by date changed */
  lastModifiedDate?: InputMaybe<DateSearchInput>;
  /** Contact's last name */
  lastName?: InputMaybe<Scalars['String']['input']>;
  /** Contact's middle name */
  middleName?: InputMaybe<Scalars['String']['input']>;
  offset?: Scalars['Int']['input'];
  page?: Scalars['Int']['input'];
  /** List of contact sort filters */
  sort?: InputMaybe<Array<ContactSortInput>>;
};

export type ContactSortInput = {
  /** Available contact sortable fields */
  field: ContactSortableFields;
  /** Sort contact ordering ['desc' or 'asc'] */
  order?: SortOrder;
};

/** Contact sortable fields */
export enum ContactSortableFields {
  firstName = 'firstName',
  lastModifiedDate = 'lastModifiedDate',
  lastName = 'lastName'
}

/** Input for creating the initial claims on authentication creation */
export type CreateAuthenticationClaimsInput = {
  /** The class to store in the init claims when creating the authentication */
  class: Scalars['String']['input'];
  /**
   * The companyId of the contact's original company to store in the init claims when creating the authentication.
   *       this value will be used in queries and mutation where a contactId is supplied without a companyId
   */
  companyId?: InputMaybe<Scalars['Int']['input']>;
  /** All the companies the contact belongs to */
  companyIds?: InputMaybe<Array<Scalars['Int']['input']>>;
  /** The initial roles the user to store with the user */
  roles?: InputMaybe<Array<Scalars['String']['input']>>;
  /** The userId to store in the init claims when creating the authentication */
  userId: Scalars['Int']['input'];
};

export type CreateAuthenticationInput = {
  /** Initial claims to store with the user */
  claims?: InputMaybe<CreateAuthenticationClaimsInput>;
  /** The display name of the user */
  displayName?: InputMaybe<Scalars['String']['input']>;
  /** The email of the user */
  email: Scalars['String']['input'];
  /** The password of the user */
  password?: InputMaybe<Scalars['String']['input']>;
  /** The first name of the user */
  phoneNumber?: InputMaybe<Scalars['String']['input']>;
  /** Optionally an external ID of the user */
  uid?: InputMaybe<Scalars['String']['input']>;
};

export type CreateBundleInput = {
  /** Items for this bundle. */
  addBundleItems?: InputMaybe<Array<BundleItemInput>>;
  /** The discount condition for this bundle. Discount can be applied for Extra Products (EP) or All Products (ALL). */
  condition?: InputMaybe<BundleCondition>;
  /** The description for this bundle. */
  description: Scalars['String']['input'];
  /** The discount percentage this bundle. */
  discount?: InputMaybe<Scalars['Float']['input']>;
  /** The name for this bundle. */
  name: Scalars['String']['input'];
  /** The bundle prices. */
  price?: InputMaybe<Scalars['Float']['input']>;
  /** The bundle tax zone. */
  taxZone?: InputMaybe<Scalars['String']['input']>;
};

export type CreateCategoryInput = {
  defaultLanguage: Scalars['String']['input'];
  /**
   * The long description for this category,
   *     this field can contain html formatting,
   *     this field can be translated is case of multilingual catalog.
   */
  description: Array<LocalizedStringInput>;
  /**
   * Localized category name as it is available in
   *     the supported language defaults to the category's default language.
   */
  name: Array<LocalizedStringInput>;
  /** The category's parent Category when available */
  parent?: InputMaybe<Scalars['Int']['input']>;
  /**
   * The long description for this category,
   *     this field can contain html formatting,
   *     this field can be translated is case of multilingual catalog.
   */
  shortDescription: Array<LocalizedStringInput>;
};

export type CreateCompanyInput = {
  cocNumber?: InputMaybe<Scalars['String']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  notes?: InputMaybe<Scalars['String']['input']>;
  /** The id of the usergroup the company should be created in. */
  parentId?: InputMaybe<Scalars['Int']['input']>;
  phone?: InputMaybe<Scalars['String']['input']>;
  taxNumber?: InputMaybe<Scalars['String']['input']>;
};

export type CreateContactInput = {
  /** Contacts's custom attribute values. This field is used to set value on attributes for the selected contact. Attributes that are used here (selected by name) must already be defined in Propeller. */
  attributes?: InputMaybe<Array<UserManagementAttributeValueInput>>;
  dateOfBirth?: InputMaybe<Scalars['String']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  gender?: InputMaybe<Gender>;
  homepage?: InputMaybe<Scalars['String']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  mailingList?: InputMaybe<YesNo>;
  middleName?: InputMaybe<Scalars['String']['input']>;
  mobile?: InputMaybe<Scalars['String']['input']>;
  /** The id of the company the contact should be created in. */
  parentId?: InputMaybe<Scalars['Int']['input']>;
  phone?: InputMaybe<Scalars['String']['input']>;
  /** The primary language of the contact. */
  primaryLanguage?: InputMaybe<Scalars['String']['input']>;
};

export type CreateInventoryInput = {
  /** Inventory cost price */
  costPrice?: InputMaybe<Scalars['Float']['input']>;
  /** Inventory location */
  location?: InputMaybe<Scalars['String']['input']>;
  /** Inventory estimated next delivery date in ISO8601 format */
  nextDeliveryDate?: InputMaybe<Scalars['String']['input']>;
  /** Inventory note */
  notes?: InputMaybe<Scalars['String']['input']>;
  /** Inventory product identifier */
  productId: Scalars['Int']['input'];
  /** Inventory quantity */
  quantity: Scalars['Int']['input'];
  /**
   * Inventory supplier!
   *
   *     defaults:
   *
   *       - 'INTERN' for local inventory type
   *
   *       - '[inherited from product supplier]' for supplier inventory type
   */
  supplier?: InputMaybe<Scalars['String']['input']>;
  /** Inventory type to create */
  type: InventoryType;
  /** Inventory warehouse identifier */
  warehouseId?: InputMaybe<Scalars['Int']['input']>;
};

export type CreateOrUpdateOrderItemInput = {
  /** OrderItem create payload */
  create?: InputMaybe<OrderItemCreateInput>;
  /** OrderItem update payload */
  update?: InputMaybe<OrderItemUpdateInput>;
};

export type CreateOrderStatusInput = {
  /** The order status set to add this order status to */
  addOrderStatusToSet?: InputMaybe<OrderStatusSetSearchByInput>;
  /** The order status code value */
  code: Scalars['String']['input'];
  /** The order status description */
  description?: InputMaybe<Scalars['String']['input']>;
  /** Marks if the order status can be archived */
  isArchivable?: InputMaybe<Scalars['Boolean']['input']>;
  /** Marks if the order is confirmable */
  isConfirmable?: InputMaybe<Scalars['Boolean']['input']>;
  /** Marks if the order status is default */
  isDefault?: InputMaybe<Scalars['Boolean']['input']>;
  /** Marks if the order is deletable */
  isDeletable?: InputMaybe<Scalars['Boolean']['input']>;
  /** Marks if the order is editable */
  isEditable?: InputMaybe<Scalars['Boolean']['input']>;
  /** Marks if the order is exportable */
  isExportable?: InputMaybe<Scalars['Boolean']['input']>;
  /** Marks if the order is public */
  isPublic?: InputMaybe<Scalars['Boolean']['input']>;
  /** The order status name */
  name: Scalars['String']['input'];
  /** The IDs of the next possible order statuses */
  nextStatusesIds?: InputMaybe<Array<Scalars['Int']['input']>>;
  /** The order status for order type.  */
  orderType?: Scalars['String']['input'];
  /** The order status priority */
  priority?: InputMaybe<Scalars['Int']['input']>;
  /** The order status type. SYSTEM types are only created by the system */
  type?: Scalars['String']['input'];
};

export type CreateOrderStatusSetInput = {
  /** The order status set description */
  description?: InputMaybe<Scalars['String']['input']>;
  /** The order status set name */
  name: Scalars['String']['input'];
  /** The ids of the order statuses that will be added to the set */
  orderStatusIds?: InputMaybe<Array<Scalars['Int']['input']>>;
};

export type CreatePaymentInput = {
  /** Add a transaction related to the specified payment */
  addTransaction?: InputMaybe<CreateTransactionInput>;
  /** Payment amount [denomination in cents] */
  amount: Scalars['Int']['input'];
  /** Guest User ID */
  anonymousId?: InputMaybe<Scalars['Int']['input']>;
  /** Payment currency - ISO 4217 currency code => https://www.xe.com/iso4217.php */
  currency: Scalars['String']['input'];
  /** Payment method used by the PSP */
  method: Scalars['String']['input'];
  /** Unique orderId reference for the Payment */
  orderId: Scalars['Int']['input'];
  /** Unique paymentId reference for the Payment - provided by the PSP */
  paymentId?: InputMaybe<Scalars['String']['input']>;
  /** Payment status */
  status: PaymentStatuses;
  /** Logged in User ID */
  userId?: InputMaybe<Scalars['Int']['input']>;
};

export type CreateProductInput = {
  /** The id of the category the product should be created in. */
  categoryId?: InputMaybe<Scalars['Int']['input']>;
  /** Product cost price */
  costPrice?: InputMaybe<Scalars['Float']['input']>;
  /** The descriptions of the product and it's translations in different languages. */
  description?: InputMaybe<Array<LocalizedStringInput>>;
  /** The EAN code of the product. */
  eanCode?: InputMaybe<Scalars['String']['input']>;
  /** The language of the product. */
  language: Scalars['String']['input'];
  /** The manufacturer of the product. */
  manufacturer?: InputMaybe<Scalars['String']['input']>;
  /** The manugacturer (OEM) code of the product. */
  manufacturerCode?: InputMaybe<Scalars['String']['input']>;
  /** Product minimum quantity. Defaults to 1. */
  minimumQuantity?: InputMaybe<Scalars['Int']['input']>;
  /** The names of the product and it's translations in different languages. */
  name?: InputMaybe<Array<LocalizedStringInput>>;
  /** Notes of the product and it's translations in different languages. */
  notes?: InputMaybe<Array<LocalizedStringInput>>;
  /** Original price of the product */
  originalPrice?: InputMaybe<Scalars['Float']['input']>;
  /** The default package type for this product. */
  package?: InputMaybe<Scalars['String']['input']>;
  /** The package type descriptions of the product and it's translations in different languages. */
  packageDescription?: InputMaybe<Array<LocalizedStringInput>>;
  /** The short descriptions of the product and it's translations in different languages. */
  shortDescription?: InputMaybe<Array<LocalizedStringInput>>;
  /**
   * The short name product.
   *       Commonly used for connections with third parties that
   *       have a size limitation on the product name field.
   */
  shortName?: InputMaybe<Scalars['String']['input']>;
  /** The sku of the product. */
  sku?: InputMaybe<Scalars['String']['input']>;
  /** The status of the product. */
  status?: InputMaybe<ProductStatus>;
  /** Product suggested price */
  suggestedPrice?: InputMaybe<Scalars['Float']['input']>;
  /** The supplier of the product. */
  supplier?: InputMaybe<Scalars['String']['input']>;
  /** The supplier code of the product. */
  supplierCode?: InputMaybe<Scalars['String']['input']>;
  /** The tax code of the product. Defaults to H. */
  taxCode?: InputMaybe<TaxCode>;
  /** Product unit. Defaults to 1. */
  unit?: InputMaybe<Scalars['Int']['input']>;
};

export type CreateSparePartInput = {
  /** Spare PartsMachine name */
  name?: InputMaybe<Array<LocalizedStringInput>>;
  quantity: Scalars['Int']['input'];
  sku: Scalars['String']['input'];
};

export type CreateSparePartsMachineInput = {
  /** Machine description */
  description?: InputMaybe<Array<LocalizedStringInput>>;
  externalReference: ExternalReferenceInput;
  /** Level 1 SubMachines input */
  machines?: InputMaybe<Array<CreateSparePartsMachineInput>>;
  /** Spare PartsMachine name */
  name: Array<LocalizedStringInput>;
  /** Spare parts for this machine */
  parts?: InputMaybe<Array<CreateSparePartInput>>;
};

export type CreateTaxInput = {
  /** Tax code */
  code?: InputMaybe<Scalars['String']['input']>;
  /** Tax export code */
  exportCode?: InputMaybe<Scalars['String']['input']>;
  /** Tax percentage expressed as ratio */
  ratio?: InputMaybe<Scalars['Float']['input']>;
  /** Shop identifier for the tax to apply to... If not provided the default will be inferred from the channel */
  shopId?: InputMaybe<Scalars['Int']['input']>;
  /** Tax zone */
  zone?: Scalars['String']['input'];
};

export type CreateTransactionInput = {
  /** Transaction amount [denomination in cents] */
  amount: Scalars['Int']['input'];
  /** Transaction currency - ISO 4217 currency code => https://www.xe.com/iso4217.php */
  currency: Scalars['String']['input'];
  /** Description of the transaction */
  description?: InputMaybe<Scalars['String']['input']>;
  /** Payment ID - usually provided by the PSP */
  paymentId?: InputMaybe<Scalars['String']['input']>;
  /** PSP Provider Name */
  provider?: InputMaybe<Scalars['String']['input']>;
  /** Transaction status */
  status: TransactionStatuses;
  /** Transaction timestamp */
  timestamp?: InputMaybe<Scalars['DateTime']['input']>;
  /** Unique transactionId reference for the Payment - usually provided by the PSP */
  transactionId: Scalars['String']['input'];
  /** Transaction type */
  type: TransactionTypes;
};

export type CreateUserInput = {
  cocNumber?: InputMaybe<Scalars['String']['input']>;
  company?: InputMaybe<Scalars['String']['input']>;
  dateOfBirth?: InputMaybe<Scalars['String']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  gender?: InputMaybe<Gender>;
  homepage?: InputMaybe<Scalars['String']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  mailingList?: InputMaybe<YesNo>;
  middleName?: InputMaybe<Scalars['String']['input']>;
  mobile?: InputMaybe<Scalars['String']['input']>;
  /** The id of the usergroup the user should be created in. */
  parentId?: InputMaybe<Scalars['Int']['input']>;
  phone?: InputMaybe<Scalars['String']['input']>;
  /** The primary language of the user. */
  primaryLanguage?: InputMaybe<Scalars['String']['input']>;
  taxNumber?: InputMaybe<Scalars['String']['input']>;
};

export type CreateUsergroupInput = {
  name?: InputMaybe<Scalars['String']['input']>;
  /** The id of the usergroup the company should be created in. */
  parentId?: InputMaybe<Scalars['Int']['input']>;
};

export type CreateWarehouseAddressInput = {
  /** Address city */
  city: Scalars['String']['input'];
  /** Address code */
  code?: InputMaybe<Scalars['String']['input']>;
  /** User company the address applies to */
  company?: InputMaybe<Scalars['String']['input']>;
  /** Address country */
  country: Scalars['String']['input'];
  /** Address email [valid email format required] */
  email?: InputMaybe<Scalars['String']['input']>;
  /** User first name the address applies to */
  firstName?: InputMaybe<Scalars['String']['input']>;
  /** User gender the address applies to */
  gender?: InputMaybe<Gender>;
  /** User last name the address applies to */
  lastName?: InputMaybe<Scalars['String']['input']>;
  /** User middle name the address applies to */
  middleName?: InputMaybe<Scalars['String']['input']>;
  /** Address mobile number [min: 6, max: 30] */
  mobile?: InputMaybe<Scalars['String']['input']>;
  /** Address delivery notes */
  notes?: InputMaybe<Scalars['String']['input']>;
  /** User street number */
  number?: InputMaybe<Scalars['String']['input']>;
  /** User street number extension */
  numberExtension?: InputMaybe<Scalars['String']['input']>;
  /** Address fixed phone number [min: 6, max: 30] */
  phone?: InputMaybe<Scalars['String']['input']>;
  /** Address postal code */
  postalCode: Scalars['String']['input'];
  /** Address region */
  region?: InputMaybe<Scalars['String']['input']>;
  /** User street address */
  street: Scalars['String']['input'];
};

export type CreateWarehouseInput = {
  /**
   * Warehouse business hours per week day
   *
   *     Example:
   *         [{
   *           dayOfWeek: 0
   *           openingTime: "08:00"
   *           closingTime: "20:00"
   *           lunchBeakStartTime: "12:00"
   *           lunchBeakEndTime: "12:30"
   *         },
   *         {
   *           dayOfWeek: 1
   *           openingTime: "08:00"
   *           closingTime: "20:00"
   *           lunchBeakStartTime: "12:00"
   *           lunchBeakEndTime: "12:30"
   *         },
   *         {
   *           dayOfWeek: 2
   *           openingTime: "08:00"
   *           closingTime: "20:00"
   *           lunchBeakStartTime: "12:00"
   *           lunchBeakEndTime: "12:30"
   *         },
   *         {
   *           dayOfWeek: 3
   *           openingTime: "08:00"
   *           closingTime: "20:00"
   *           lunchBeakStartTime: "12:00"
   *           lunchBeakEndTime: "12:30"
   *         },
   *         {
   *           dayOfWeek: 4
   *           openingTime: "08:00"
   *           closingTime: "20:00"
   *           lunchBeakStartTime: "12:00"
   *           lunchBeakEndTime: "12:30"
   *         },
   *         {
   *           dayOfWeek: 5
   *           openingTime: "08:00"
   *           closingTime: "20:00"
   *           lunchBeakStartTime: "12:00"
   *           lunchBeakEndTime: "12:30"
   *         },
   *         {
   *           dayOfWeek: 6
   *           openingTime: "08:00"
   *           closingTime: "20:00"
   *           lunchBeakStartTime: "12:00"
   *           lunchBeakEndTime: "12:30"
   *         }]
   */
  businessHours: Array<BusinessHoursInput>;
  /** Add a address related to the specified warehouse */
  createWarehouseAddress?: InputMaybe<CreateWarehouseAddressInput>;
  /** Warehouse description */
  description: Scalars['String']['input'];
  /** Warehouse isActive status */
  isActive: Scalars['Boolean']['input'];
  /** Warehouse isPickupLocation status */
  isPickupLocation: Scalars['Boolean']['input'];
  /** Warehouse isStore status */
  isStore: Scalars['Boolean']['input'];
  /** Warehouse name */
  name: Scalars['String']['input'];
  /** Warehouse notes */
  notes: Scalars['String']['input'];
  /** Optional warehouse shopId! Default value will be internally inferred from the appropriate channel */
  shopId?: InputMaybe<Scalars['Int']['input']>;
};

/**
 * Removes pixels from an image.
 *
 * When specifying a crop parameter, the value starts with the desired width and height, either as measurements of pixels, separated with a comma, or as a ratio, separated with a colon (for example, crop=4:3 or crop=640,480 or crop=0.8,0.4).
 *
 * The remaining parameters determine the position of the cropped region. On each dimension, placement can be made either with a position coordinate (x or y, which are relative to the top left of the uncropped image) or as a percentage offset from the center of the image using offset-x and offset-y. These can be mixed and matched, but only one method can be used for each dimension (i.e., x can be combined with offset-y but x cannot be combined with offset-x).
 *
 * Offset positioning acts to distribute the remaining space according to the specified offset proportions. If an image is 2000 pixels wide and is being cropped to 1000 pixels wide, offset-x10 would crop 10% (100 pixels) from the left of the image and 90% (900 pixels) from the right. An offset of 50 centers the crop in the middle of the image.
 *
 * Appending ,smart to the parameter value enables content-aware algorithms to attempt to crop the image to the desired aspect ratio while intelligently focusing on the most important visual content, including the detection of faces.
 *
 * If the specified cropped region is outside the bounds of the image, the transformation will fail with the error "Invalid transformation for requested image: Invalid crop, region out of bounds". Append ,safe to the parameter value to override this. In safe mode, the image gets delivered as an intersection of the origin image and the specified cropped region. This avoids the error, but the resulting image may not be of the specified dimensions.
 */
export type CropInput = {
  /** Height as Pixels or Percentage */
  height?: InputMaybe<Scalars['Int']['input']>;
  /** HeightRatio as Number */
  heightRatio?: InputMaybe<Scalars['Int']['input']>;
  /** X coordinate offset as Percentage */
  offsetX?: InputMaybe<Scalars['Int']['input']>;
  /** Y coordinate offset as Percentage */
  offsetY?: InputMaybe<Scalars['Int']['input']>;
  /** safe */
  safe?: InputMaybe<Scalars['String']['input']>;
  /** smart */
  smart?: InputMaybe<Scalars['String']['input']>;
  /** Width as Pixel or Percentage */
  width?: InputMaybe<Scalars['Int']['input']>;
  /** WidthRatio as Number */
  widthRatio?: InputMaybe<Scalars['Int']['input']>;
  /** X coordinate as Pixels or Percentage */
  x?: InputMaybe<Scalars['Int']['input']>;
  /** Y coordinate as Pixels or Percentage */
  y?: InputMaybe<Scalars['Int']['input']>;
};

export type CrossupsellInput = {
  /** Cross/Up Sell cluster identifier */
  clusterId?: InputMaybe<Scalars['Int']['input']>;
  /** Cross/Up Sell product identifier */
  productId?: InputMaybe<Scalars['Int']['input']>;
  /** Crossupsell subtypes */
  subTypes?: InputMaybe<Array<Scalars['String']['input']>>;
  /** Crossupsell types */
  types?: InputMaybe<Array<CrossupsellTypes>>;
};

/** Available Cross/Up Sell types */
export enum CrossupsellTypes {
  ACCESSORIES = 'ACCESSORIES',
  ALTERNATIVES = 'ALTERNATIVES',
  OPTIONS = 'OPTIONS',
  PARTS = 'PARTS',
  RELATED = 'RELATED'
}

export type CrossupsellTypesInput = {
  /** Crossupsell subtypes */
  subTypes?: InputMaybe<Array<Scalars['String']['input']>>;
  /** Crossupsell types */
  types?: InputMaybe<Array<CrossupsellTypes>>;
};

export type CustomerAddressCreateInput = {
  /** Address city */
  city: Scalars['String']['input'];
  /** Address code */
  code?: InputMaybe<Scalars['String']['input']>;
  /** User company the address applies to */
  company?: InputMaybe<Scalars['String']['input']>;
  /** Address country */
  country: Scalars['String']['input'];
  /** Address customer primary identifier */
  customerId: Scalars['Int']['input'];
  /** Address email [valid email format required] */
  email?: InputMaybe<Scalars['String']['input']>;
  /** User first name the address applies to */
  firstName?: InputMaybe<Scalars['String']['input']>;
  /** User gender the address applies to */
  gender?: InputMaybe<Gender>;
  /** ICP address field defines whether tax should be applied to an order if the address is selected as a delivery address. Used when shipping b2b order abroad */
  icp?: InputMaybe<YesNo>;
  /** Indicates whether the address is default for its type */
  isDefault?: InputMaybe<YesNo>;
  /** User last name the address applies to */
  lastName?: InputMaybe<Scalars['String']['input']>;
  /** User middle name the address applies to */
  middleName?: InputMaybe<Scalars['String']['input']>;
  /** Address mobile number [min: 6, max: 30] */
  mobile?: InputMaybe<Scalars['String']['input']>;
  /** Address delivery notes */
  notes?: InputMaybe<Scalars['String']['input']>;
  /** User street number */
  number?: InputMaybe<Scalars['String']['input']>;
  /** User street number extension */
  numberExtension?: InputMaybe<Scalars['String']['input']>;
  /** Address fixed phone number [min: 6, max: 30] */
  phone?: InputMaybe<Scalars['String']['input']>;
  /** Address postal code */
  postalCode: Scalars['String']['input'];
  /** Address region */
  region?: InputMaybe<Scalars['String']['input']>;
  /** User street address */
  street: Scalars['String']['input'];
  /** Address type [one of 'home', 'delivery' or 'invoice'] */
  type: AddressType;
};

export type CustomerAddressDeleteInput = {
  /** Customer primary identifier */
  customerId: Scalars['Int']['input'];
  /** Address primary identifier */
  id: Scalars['Int']['input'];
};

export type CustomerAddressUpdateInput = {
  /** Address city */
  city?: InputMaybe<Scalars['String']['input']>;
  /** Address code */
  code?: InputMaybe<Scalars['String']['input']>;
  /** User company the address applies to */
  company?: InputMaybe<Scalars['String']['input']>;
  /** Address country */
  country?: InputMaybe<Scalars['String']['input']>;
  /** Address customer primary identifier */
  customerId: Scalars['Int']['input'];
  /** Address email [valid email format required] */
  email?: InputMaybe<Scalars['String']['input']>;
  /** User first name the address applies to */
  firstName?: InputMaybe<Scalars['String']['input']>;
  /** User gender the address applies to */
  gender?: InputMaybe<Gender>;
  /** ICP address field defines whether tax should be applied to an order if the address is selected as a delivery address. Used when shipping b2b order abroad */
  icp?: InputMaybe<YesNo>;
  /** Address primary identifier */
  id: Scalars['Int']['input'];
  /** Indicates whether the address is default for its type */
  isDefault?: InputMaybe<YesNo>;
  /** User last name the address applies to */
  lastName?: InputMaybe<Scalars['String']['input']>;
  /** User middle name the address applies to */
  middleName?: InputMaybe<Scalars['String']['input']>;
  /** Address mobile number [min: 6, max: 30] */
  mobile?: InputMaybe<Scalars['String']['input']>;
  /** Address delivery notes */
  notes?: InputMaybe<Scalars['String']['input']>;
  /** User street number */
  number?: InputMaybe<Scalars['String']['input']>;
  /** User street number extension */
  numberExtension?: InputMaybe<Scalars['String']['input']>;
  /** Address fixed phone number [min: 6, max: 30] */
  phone?: InputMaybe<Scalars['String']['input']>;
  /** Address postal code */
  postalCode?: InputMaybe<Scalars['String']['input']>;
  /** Address region */
  region?: InputMaybe<Scalars['String']['input']>;
  /** User street address */
  street?: InputMaybe<Scalars['String']['input']>;
};

export type CustomerInput = {
  /** Customer's custom attribute values. This field is used to set value on attributes for the selected customer. Attributes that are used here (selected by name) must already be defined in Propeller. */
  attributes?: InputMaybe<Array<UserManagementAttributeValueInput>>;
  customerId?: InputMaybe<Scalars['Int']['input']>;
  dateOfBirth?: InputMaybe<Scalars['String']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  gender?: InputMaybe<Gender>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  mailingList?: InputMaybe<YesNo>;
  middleName?: InputMaybe<Scalars['String']['input']>;
  mobile?: InputMaybe<Scalars['String']['input']>;
  /** The id of the usergroup the customer should be created in. */
  parentId?: InputMaybe<Scalars['Int']['input']>;
  phone?: InputMaybe<Scalars['String']['input']>;
  /** The primary language of the customer. */
  primaryLanguage?: InputMaybe<Scalars['String']['input']>;
};

export type CustomerSearchArguments = {
  /** Search by Customer's attributes */
  attribute?: InputMaybe<UserManagementAttributeSearchInput>;
  /** List of unique customer ids */
  customerIds?: InputMaybe<Array<Scalars['Int']['input']>>;
  /** Customer's email */
  email?: InputMaybe<Scalars['String']['input']>;
  /** Customer's first name */
  firstName?: InputMaybe<Scalars['String']['input']>;
  /** Customer's gender */
  gender?: InputMaybe<Gender>;
  /** Search by date changed */
  lastModifiedDate?: InputMaybe<DateSearchInput>;
  /** Customer's last name */
  lastName?: InputMaybe<Scalars['String']['input']>;
  /** Customer's middle name */
  middleName?: InputMaybe<Scalars['String']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  /** List of customer sort filters */
  sort?: InputMaybe<Array<CustomerSortInput>>;
};

export type CustomerSortInput = {
  /** Available customer sortable fields */
  field: CustomerSortableFields;
  /** Sort customer ordering ['desc' or 'asc'] */
  order?: SortOrder;
};

/** Customer sortable fields */
export enum CustomerSortableFields {
  lastModifiedDate = 'lastModifiedDate',
  name = 'name'
}

export type DateSearchInput = {
  /** Date greater than provided date */
  greaterThan?: InputMaybe<Scalars['String']['input']>;
  /** Date less than provided date */
  lessThan?: InputMaybe<Scalars['String']['input']>;
};

export type DecimalSearchInput = {
  /** greater than or equal */
  greaterThan?: InputMaybe<Scalars['Float']['input']>;
  /** less than or equal */
  lessThan?: InputMaybe<Scalars['Float']['input']>;
};

export type DeleteWarehouseAddressInput = {
  /** Address primary identifier */
  id: Scalars['Int']['input'];
};

/** Discount type */
export enum DiscountType {
  amount = 'amount',
  percentage = 'percentage'
}

export type EmailEventInput = {
  /** Attachments to add to the email */
  attachments?: InputMaybe<Array<Base64FileInput>>;
  /** The content of the email, use when the content can not be derived from other inputs */
  content?: InputMaybe<Scalars['String']['input']>;
  /** The sender of the email, use when the sender can not be derived from other inputs */
  from?: InputMaybe<EmailSendContactInput>;
  /** The language of the generated email */
  language?: InputMaybe<Scalars['String']['input']>;
  /** The id of the Propeller letter to use when type is 'campaign' */
  letterId?: InputMaybe<Scalars['String']['input']>;
  /** The id of the order to use when triggering an event of type orderconfirm */
  orderId?: InputMaybe<Scalars['Int']['input']>;
  /** The id of the site to use when sending the email, required when the site can not be derived from other inputs */
  siteId?: InputMaybe<Scalars['Int']['input']>;
  /** The subject of the email, use when the subject can not be derived from other inputs */
  subject?: InputMaybe<Scalars['String']['input']>;
  /** The recipient(s) of the email, use when the recipient(s) can not be derived from other inputs */
  to?: InputMaybe<Array<EmailSendContactInput>>;
  /** The type of email event to trigger */
  type: EmailEventType;
  /** The id of the user to send to email to. Can be used to replace to, when the user can't be derived from other inputs */
  userId?: InputMaybe<Scalars['Int']['input']>;
  /** Variables that can be used in the email template */
  variables?: InputMaybe<Scalars['JSONObject']['input']>;
};

/** Type of e-mail events that can be triggered */
export enum EmailEventType {
  campaign = 'campaign',
  custom = 'custom',
  error = 'error',
  orderconfirm = 'orderconfirm',
  quotation = 'quotation',
  registration = 'registration',
  system = 'system',
  transactional = 'transactional'
}

export type EmailSendContactInput = {
  /** The email address of the contact */
  email: Scalars['String']['input'];
  /** The name of the contact */
  name?: InputMaybe<Scalars['String']['input']>;
};

export type EmailSendEventInput = {
  /** The attachment(s) of the email */
  attachments?: InputMaybe<Array<Base64FileInput>>;
  /** The bcc recipient(s) of the email */
  bcc?: InputMaybe<Array<EmailSendContactInput>>;
  /** The campaingId of the email */
  campaignId?: InputMaybe<Scalars['String']['input']>;
  /** The cc recipient(s) of the email */
  cc?: InputMaybe<Array<EmailSendContactInput>>;
  /** The content of the email */
  content: Scalars['String']['input'];
  /** The sender of the email */
  from: EmailSendContactInput;
  /** The messageId of the email */
  messageId?: InputMaybe<Scalars['String']['input']>;
  /** The subject of the email */
  subject: Scalars['String']['input'];
  /** The recipient(s) of the email */
  to: Array<EmailSendContactInput>;
  /** The utmTags of the email */
  utmTags?: InputMaybe<Scalars['String']['input']>;
  /** Variables that can be used in the email template */
  variables?: InputMaybe<Scalars['JSONObject']['input']>;
};

export type ExchangeRefreshTokenInput = {
  refreshToken: Scalars['String']['input'];
};

export type ExternalAddressCreateInput = {
  /** Address city */
  city: Scalars['String']['input'];
  /** Address code */
  code?: InputMaybe<Scalars['String']['input']>;
  /** User company the address applies to */
  company?: InputMaybe<Scalars['String']['input']>;
  /** Address country */
  country: Scalars['String']['input'];
  /** Address email [valid email format required] */
  email?: InputMaybe<Scalars['String']['input']>;
  /** User first name the address applies to */
  firstName?: InputMaybe<Scalars['String']['input']>;
  /** User gender the address applies to */
  gender?: InputMaybe<Gender>;
  /** ICP address field defines whether tax should be applied to an order if the address is selected as a delivery address. Used when shipping b2b order abroad */
  icp?: InputMaybe<YesNo>;
  /** User last name the address applies to */
  lastName?: InputMaybe<Scalars['String']['input']>;
  /** User middle name the address applies to */
  middleName?: InputMaybe<Scalars['String']['input']>;
  /** Address mobile number [min: 6, max: 30] */
  mobile?: InputMaybe<Scalars['String']['input']>;
  /** Address delivery notes */
  notes?: InputMaybe<Scalars['String']['input']>;
  /** User street number */
  number?: InputMaybe<Scalars['String']['input']>;
  /** User street number extension */
  numberExtension?: InputMaybe<Scalars['String']['input']>;
  /** Address fixed phone number [min: 6, max: 30] */
  phone?: InputMaybe<Scalars['String']['input']>;
  /** Address postal code */
  postalCode: Scalars['String']['input'];
  /** Address region */
  region?: InputMaybe<Scalars['String']['input']>;
  /** User street address */
  street: Scalars['String']['input'];
};

export type ExternalAddressDeleteInput = {
  /** Address primary identifier */
  id: Scalars['Int']['input'];
};

export type ExternalAddressUpdateInput = {
  /** Address city */
  city?: InputMaybe<Scalars['String']['input']>;
  /** Address code */
  code?: InputMaybe<Scalars['String']['input']>;
  /** User company the address applies to */
  company?: InputMaybe<Scalars['String']['input']>;
  /** Address country */
  country?: InputMaybe<Scalars['String']['input']>;
  /** Address email [valid email format required] */
  email?: InputMaybe<Scalars['String']['input']>;
  /** User first name the address applies to */
  firstName?: InputMaybe<Scalars['String']['input']>;
  /** User gender the address applies to */
  gender?: InputMaybe<Gender>;
  /** ICP address field defines whether tax should be applied to an order if the address is selected as a delivery address. Used when shipping b2b order abroad */
  icp?: InputMaybe<YesNo>;
  /** Address primary identifier */
  id: Scalars['Int']['input'];
  /** User last name the address applies to */
  lastName?: InputMaybe<Scalars['String']['input']>;
  /** User middle name the address applies to */
  middleName?: InputMaybe<Scalars['String']['input']>;
  /** Address mobile number [min: 6, max: 30] */
  mobile?: InputMaybe<Scalars['String']['input']>;
  /** Address delivery notes */
  notes?: InputMaybe<Scalars['String']['input']>;
  /** User street number */
  number?: InputMaybe<Scalars['String']['input']>;
  /** User street number extension */
  numberExtension?: InputMaybe<Scalars['String']['input']>;
  /** Address fixed phone number [min: 6, max: 30] */
  phone?: InputMaybe<Scalars['String']['input']>;
  /** Address postal code */
  postalCode?: InputMaybe<Scalars['String']['input']>;
  /** Address region */
  region?: InputMaybe<Scalars['String']['input']>;
  /** User street address */
  street?: InputMaybe<Scalars['String']['input']>;
};

export type ExternalReferenceInput = {
  source: Scalars['String']['input'];
  sourceId: Scalars['String']['input'];
};

export type FavoriteListsBaseSearchInput = {
  /** Favorite List is default */
  isDefault?: InputMaybe<Scalars['Boolean']['input']>;
  /** Favorite List name */
  name?: InputMaybe<Scalars['String']['input']>;
  /** Favorite List offset */
  offset?: InputMaybe<Scalars['Int']['input']>;
  /** Favorite List page */
  page?: InputMaybe<Scalars['Int']['input']>;
};

export type FavoriteListsCreateInput = {
  /** Favorite List cluster IDs list */
  clusterIds?: InputMaybe<Array<Scalars['Int']['input']>>;
  /** List of cluster sources */
  clusterSources?: InputMaybe<Array<SourceInput>>;
  /** Favorite List company ID */
  companyId?: InputMaybe<Scalars['Int']['input']>;
  /** A company source */
  companySource?: InputMaybe<SourceInput>;
  /** Favorite List contact ID */
  contactId?: InputMaybe<Scalars['Int']['input']>;
  /** A contact source */
  contactSource?: InputMaybe<SourceInput>;
  /** Favorite List customer ID */
  customerId?: InputMaybe<Scalars['Int']['input']>;
  /** A customer source */
  customerSource?: InputMaybe<SourceInput>;
  /** Favorite List is default */
  isDefault?: InputMaybe<Scalars['Boolean']['input']>;
  /** Favorite List name */
  name: Scalars['String']['input'];
  /** Favorite List product IDs list */
  productIds?: InputMaybe<Array<Scalars['Int']['input']>>;
  /** List of product sources */
  productSources?: InputMaybe<Array<SourceInput>>;
};

export type FavoriteListsItemsInput = {
  /** Favorite List cluster IDs list */
  clusterIds?: InputMaybe<Array<Scalars['Int']['input']>>;
  /** List of cluster sources */
  clusterSources?: InputMaybe<Array<SourceInput>>;
  /** Favorite List product IDs list */
  productIds?: InputMaybe<Array<Scalars['Int']['input']>>;
  /** List of product sources */
  productSources?: InputMaybe<Array<SourceInput>>;
};

export type FavoriteListsSearchInput = {
  /** List of cluster ids */
  clusterIds?: InputMaybe<Array<Scalars['Int']['input']>>;
  /** List of cluster sources */
  clusterSources?: InputMaybe<Array<SourceInput>>;
  /** Favorite List company ID */
  companyId?: InputMaybe<Scalars['Int']['input']>;
  /** A company source */
  companySource?: InputMaybe<SourceInput>;
  /** Favorite List contact ID */
  contactId?: InputMaybe<Scalars['Int']['input']>;
  /** A contact source */
  contactSource?: InputMaybe<SourceInput>;
  /** Search by date created */
  createdAt?: InputMaybe<DateSearchInput>;
  /** Favorite List customer ID */
  customerId?: InputMaybe<Scalars['Int']['input']>;
  /** A customer source */
  customerSource?: InputMaybe<SourceInput>;
  /** Favorite List is default */
  isDefault?: InputMaybe<Scalars['Boolean']['input']>;
  /** Search by date changed */
  lastModifiedAt?: InputMaybe<DateSearchInput>;
  /** Favorite List name */
  name?: InputMaybe<Scalars['String']['input']>;
  /** Favorite List offset */
  offset?: InputMaybe<Scalars['Int']['input']>;
  /** Favorite List page */
  page?: InputMaybe<Scalars['Int']['input']>;
  /** List of product ids */
  productIds?: InputMaybe<Array<Scalars['Int']['input']>>;
  /** List of product sources */
  productSources?: InputMaybe<Array<SourceInput>>;
};

export type FavoriteListsUpdateInput = {
  /** Favorite List cluster IDs list */
  clusterIds?: InputMaybe<Array<Scalars['Int']['input']>>;
  /** List of cluster sources */
  clusterSources?: InputMaybe<Array<SourceInput>>;
  /** Favorite List is default */
  isDefault?: InputMaybe<Scalars['Boolean']['input']>;
  /** Favorite List name */
  name?: InputMaybe<Scalars['String']['input']>;
  /** Favorite List product IDs list */
  productIds?: InputMaybe<Array<Scalars['Int']['input']>>;
  /** List of product sources */
  productSources?: InputMaybe<Array<SourceInput>>;
};

/** Enable/Disable available image optimization */
export enum FeaturesEnableDisable {
  /**
   * [Disable] Prevent images being resized such that the output image's dimensions are larger than the source image.
   *
   *         [Enable] Allow images to be resized such that the output image's dimensions are larger than the source image.
   */
  UPSCALE = 'UPSCALE'
}

export type FilterAvailableAttributeInput = {
  id?: InputMaybe<Array<Scalars['String']['input']>>;
  isPublic?: InputMaybe<Scalars['Boolean']['input']>;
  isSearchable?: InputMaybe<Scalars['Boolean']['input']>;
  searchId?: InputMaybe<Array<Scalars['String']['input']>>;
  type?: InputMaybe<Array<AttributeType>>;
};

/**
 * The fit parameter controls how the image will be constrained within the provided size (width and height) values, in order to maintain the correct proportions.
 *
 *   NOTE: The fit parameter has no effect unless both width and height are specified.
 */
export enum Fit {
  /** Resize the image to fit entirely within the specified region, making one dimension smaller if needed. */
  BOUNDS = 'BOUNDS',
  /** Resize the image to entirely cover the specified region, making one dimension larger if needed. */
  COVER = 'COVER',
  /** Resize and crop the image centrally to exactly fit the specified region. */
  CROP = 'CROP'
}

/**
 * The Format parameter controls how the image will be constrained within the provided size (width and height) values, in order to maintain the correct proportions.
 *
 *   NOTE: The Format parameter has no effect unless both width and height are specified.
 */
export enum Format {
  /** Automatically use the best format based on browser support and image/transform characteristics */
  AUTO = 'AUTO',
  /** AV1 Image File Format */
  AVIF = 'AVIF',
  /** Baseline JPEG (also bjpeg) */
  BJPG = 'BJPG',
  /** Graphics Interchange Format */
  GIF = 'GIF',
  /** JPEG (also jpeg) */
  JPG = 'JPG',
  /** JPEGXL (also jpegxl) */
  JXL = 'JXL',
  /** MP4 (H.264) */
  MP4 = 'MP4',
  /** Progressive JPEG (also pjpeg) */
  PJPG = 'PJPG',
  /** Progressive JPEGXL (also pjpegxl) */
  PJXL = 'PJXL',
  /** Portable Network Graphics */
  PNG = 'PNG',
  /** Portable Network Graphics palette image with 256 colors and 8-bit transparency */
  PNG8 = 'PNG8',
  /** WebP */
  WEBP = 'WEBP',
  /** WebP (Lossless) */
  WEBPLL = 'WEBPLL',
  /** WebP (Lossy) */
  WEBPLY = 'WEBPLY'
}

/** A persons gender */
export enum Gender {
  /** Female */
  F = 'F',
  /** Male */
  M = 'M',
  /** Unknown */
  U = 'U'
}

/** The metadata parameters controls whether to include metadata in the output image */
export enum ImageMetadata {
  /** Preserve copyright notice, creator, credit line, licensor, and web statement of rights fields. */
  COPYRIGHT = 'COPYRIGHT'
}

export type ImageTransformationInput = {
  /** Friendly name for your image transformation */
  name: Scalars['String']['input'];
  /** Image transformation options */
  transformation: TransformationInput;
};

/** Available inventory types */
export enum InventoryType {
  local = 'local',
  supplier = 'supplier'
}

/**
 * When converting animated GIFs to the MP4 format and when used in conjunction with the profile parameter, the level parameter specifies a set of constraints indicating a degree of required decoder performance for a profile.
 *
 *   NOTE: If either level or profile parameters are missing, profile=baseline&level=3.0 will be used as the default.
 */
export enum Level {
  /** Level: 1.0 */
  LEVEL_1_0 = 'LEVEL_1_0',
  /** Level: 1.1 */
  LEVEL_1_1 = 'LEVEL_1_1',
  /** Level: 1.2 */
  LEVEL_1_2 = 'LEVEL_1_2',
  /** Level: 2.0 */
  LEVEL_2_0 = 'LEVEL_2_0',
  /** Level: 2.1 */
  LEVEL_2_1 = 'LEVEL_2_1',
  /** Level: 2.2 */
  LEVEL_2_2 = 'LEVEL_2_2',
  /** Level: 3.0 (Default) */
  LEVEL_3_0 = 'LEVEL_3_0',
  /** Level: 3.1 */
  LEVEL_3_1 = 'LEVEL_3_1',
  /** Level: 3.2 */
  LEVEL_3_2 = 'LEVEL_3_2',
  /** Level: 4.0 */
  LEVEL_4_0 = 'LEVEL_4_0',
  /** Level: 4.1 */
  LEVEL_4_1 = 'LEVEL_4_1',
  /** Level: 4.2 */
  LEVEL_4_2 = 'LEVEL_4_2',
  /** Level: 5.0 */
  LEVEL_5_0 = 'LEVEL_5_0',
  /** Level: 5.1 */
  LEVEL_5_1 = 'LEVEL_5_1',
  /** Level: 5.2 */
  LEVEL_5_2 = 'LEVEL_5_2',
  /** Level: 6.0 */
  LEVEL_6_0 = 'LEVEL_6_0',
  /** Level: 6.1 */
  LEVEL_6_1 = 'LEVEL_6_1',
  /** Level: 6.2 */
  LEVEL_6_2 = 'LEVEL_6_2'
}

export type LocalizedStringArrayInput = {
  /** Language is set as 2 character country code. */
  language: Scalars['String']['input'];
  values?: InputMaybe<Array<Scalars['String']['input']>>;
};

/** A String representation of a type Field that is suitable for Localization */
export type LocalizedStringInput = {
  /** Localized field language code */
  language: Scalars['String']['input'];
  /** Localized field value */
  value: Scalars['String']['input'];
};

export type LoginInput = {
  email: Scalars['String']['input'];
  password: Scalars['String']['input'];
  provider?: InputMaybe<Scalars['String']['input']>;
};

export type MediaAttachmentInput = {
  /** Media item alt description that briefly explains its contents. */
  alt: Array<MediaLocalizedStringInput>;
  /** Unique company identifier that the attachment relates to. [Cannot use it together with customerId] */
  companyId?: InputMaybe<Scalars['Int']['input']>;
  /** Unique customer identifier that the attachment relates to. [Cannot use it together with companyId] */
  customerId?: InputMaybe<Scalars['Int']['input']>;
  /** Media item short description. */
  description: Array<MediaLocalizedStringInput>;
  /** Unique order identifier that the attachment relates to. [Cannot use it together with sparePartsMachineId] */
  orderId?: InputMaybe<Scalars['Int']['input']>;
  /** Media item display priority [Lower value has higher priority] -  default: 1000 */
  priority?: InputMaybe<Scalars['Int']['input']>;
  /** Unique machine identifier that the media item relates to */
  sparePartsMachineId?: InputMaybe<Scalars['ID']['input']>;
  /** Media item tags. */
  tags: Array<MediaLocalizedStringArrayInput>;
  /** Upload File input reference */
  uploadAttachments: Array<UploadFileInput>;
};

export type MediaAttachmentSearchInput = {
  /** Unique company identifier that the attachment relates to. [Cannot use it together with customerId] */
  companyId?: InputMaybe<Scalars['Int']['input']>;
  /** Unique customer identifier that the attachment relates to. [Cannot use it together with companyId] */
  customerId?: InputMaybe<Scalars['Int']['input']>;
  /** Search by media item short description. */
  description?: InputMaybe<MediaLocalizedStringInput>;
  /** Pagination - offset number [default=12] */
  offset?: InputMaybe<Scalars['Int']['input']>;
  /** Unique order identifier that the attachment relates to. [Cannot use it together with sparePartsMachineId] */
  orderId?: InputMaybe<Scalars['Int']['input']>;
  /** Pagination - page number [default=1] */
  page?: InputMaybe<Scalars['Int']['input']>;
  /** Media item search sort [Default=ASC] */
  sort?: InputMaybe<Sort>;
  /** Search by unique spareparts machine identifier */
  sparePartsMachineId?: InputMaybe<Scalars['String']['input']>;
  /** Search by media item tags. */
  tag?: InputMaybe<MediaLocalizedStringInput>;
};

export type MediaBase64FileInput = {
  /** The base64 representation of the file contents. */
  base64: Scalars['String']['input'];
  /** Name to use when converting the base64 to a file. */
  fileName: Scalars['String']['input'];
};

export type MediaDocumentInput = {
  /** Media item alt description that briefly explains its contents. */
  alt: Array<MediaLocalizedStringInput>;
  /** Unique category identifier that the media item relates to */
  categoryId?: InputMaybe<Scalars['ID']['input']>;
  /** Unique cluster identifier that the media item relates to */
  clusterId?: InputMaybe<Scalars['ID']['input']>;
  /** Media item short description. */
  description: Array<MediaLocalizedStringInput>;
  /** Media item display priority [Lower value has higher priority] -  default: 1000 */
  priority?: InputMaybe<Scalars['Int']['input']>;
  /** Unique product identifier that the media item relates to */
  productId?: InputMaybe<Scalars['ID']['input']>;
  /** Unique machine identifier that the media item relates to */
  sparePartsMachineId?: InputMaybe<Scalars['ID']['input']>;
  /** Media item tags. */
  tags: Array<MediaLocalizedStringArrayInput>;
  /** Upload File input reference */
  uploadDocuments: Array<UploadFileInput>;
};

export type MediaDocumentProductSearchInput = {
  /** Search by media item short description. */
  description?: InputMaybe<MediaLocalizedStringInput>;
  /** Pagination - offset number [default=12] */
  offset?: InputMaybe<Scalars['Int']['input']>;
  /** Pagination - page number [default=1] */
  page?: InputMaybe<Scalars['Int']['input']>;
  /** Media item search sort [Default=ASC] */
  sort?: InputMaybe<Sort>;
  /** Search by media item tags. */
  tag?: InputMaybe<MediaLocalizedStringInput>;
};

export type MediaDocumentSearchInput = {
  /** Search by unique category identifier */
  categoryId?: InputMaybe<Scalars['Int']['input']>;
  /** Search by unique cluster identifier */
  clusterId?: InputMaybe<Scalars['Int']['input']>;
  /** Search by media item short description. */
  description?: InputMaybe<MediaLocalizedStringInput>;
  /** Pagination - offset number [default=12] */
  offset?: InputMaybe<Scalars['Int']['input']>;
  /** Pagination - page number [default=1] */
  page?: InputMaybe<Scalars['Int']['input']>;
  /** Search by unique product identifier */
  productId?: InputMaybe<Scalars['Int']['input']>;
  /** Media item search sort [Default=ASC] */
  sort?: InputMaybe<Sort>;
  /** Search by unique spareparts machine identifier */
  sparePartsMachineId?: InputMaybe<Scalars['String']['input']>;
  /** Search by media item tags. */
  tag?: InputMaybe<MediaLocalizedStringInput>;
};

export type MediaImageInput = {
  /** Media item alt description that briefly explains its contents. */
  alt: Array<MediaLocalizedStringInput>;
  /** Unique category identifier that the media item relates to */
  categoryId?: InputMaybe<Scalars['ID']['input']>;
  /** Unique cluster identifier that the media item relates to */
  clusterId?: InputMaybe<Scalars['ID']['input']>;
  /** Media item short description. */
  description: Array<MediaLocalizedStringInput>;
  /** Media item display priority [Lower value has higher priority] -  default: 1000 */
  priority?: InputMaybe<Scalars['Int']['input']>;
  /** Unique product identifier that the media item relates to */
  productId?: InputMaybe<Scalars['ID']['input']>;
  /** Unique machine identifier that the media item relates to */
  sparePartsMachineId?: InputMaybe<Scalars['ID']['input']>;
  /** Media item tags. */
  tags: Array<MediaLocalizedStringArrayInput>;
  /** Upload File input reference */
  uploadImages: Array<UploadFileInput>;
};

export type MediaImageProductSearchInput = {
  /** Search by media item short description. */
  description?: InputMaybe<MediaLocalizedStringInput>;
  /** Pagination - offset number [default=12] */
  offset?: InputMaybe<Scalars['Int']['input']>;
  /** Pagination - page number [default=1] */
  page?: InputMaybe<Scalars['Int']['input']>;
  /** Media item search sort [Default=ASC] */
  sort?: InputMaybe<Sort>;
  /** Search by media item tags. */
  tag?: InputMaybe<MediaLocalizedStringInput>;
};

export type MediaImageSearchInput = {
  /** Search by unique category identifier */
  categoryId?: InputMaybe<Scalars['Int']['input']>;
  /** Search by unique cluster identifier */
  clusterId?: InputMaybe<Scalars['Int']['input']>;
  /** Search by media item short description. */
  description?: InputMaybe<MediaLocalizedStringInput>;
  /** Pagination - offset number [default=12] */
  offset?: InputMaybe<Scalars['Int']['input']>;
  /** Pagination - page number [default=1] */
  page?: InputMaybe<Scalars['Int']['input']>;
  /** Search by unique product identifier */
  productId?: InputMaybe<Scalars['Int']['input']>;
  /** Media item search sort [Default=ASC] */
  sort?: InputMaybe<Sort>;
  /** Search by unique spareparts machine identifier */
  sparePartsMachineId?: InputMaybe<Scalars['String']['input']>;
  /** Search by media item tags. */
  tag?: InputMaybe<MediaLocalizedStringInput>;
};

export type MediaLocalizedStringArrayInput = {
  /** Media localized string language country code [min/max: 2] */
  language: Scalars['String']['input'];
  /** Media localized string value */
  values?: InputMaybe<Array<Scalars['String']['input']>>;
};

/** A String representation of a type Field that is suitable for Localization */
export type MediaLocalizedStringInput = {
  /** Media localized string language country code [min/max: 2] */
  language: Scalars['String']['input'];
  /** Media localized string value */
  value: Scalars['String']['input'];
};

export type MediaVideoInput = {
  /** Media item alt description that briefly explains its contents. */
  alt: Array<MediaLocalizedStringInput>;
  /** Unique category identifier that the media item relates to */
  categoryId?: InputMaybe<Scalars['ID']['input']>;
  /** Unique cluster identifier that the media item relates to */
  clusterId?: InputMaybe<Scalars['ID']['input']>;
  /** Media item short description. */
  description: Array<MediaLocalizedStringInput>;
  /** Media item display priority [Lower value has higher priority] -  default: 1000 */
  priority?: InputMaybe<Scalars['Int']['input']>;
  /** Unique product identifier that the media item relates to */
  productId?: InputMaybe<Scalars['ID']['input']>;
  /** Unique machine identifier that the media item relates to */
  sparePartsMachineId?: InputMaybe<Scalars['ID']['input']>;
  /** Media item tags. */
  tags: Array<MediaLocalizedStringArrayInput>;
  /** Store Video input reference */
  videos: Array<UploadVideoInput>;
};

export type MediaVideoProductSearchInput = {
  /** Search by media item short description. */
  description?: InputMaybe<MediaLocalizedStringInput>;
  /** Pagination - offset number [default=12] */
  offset?: InputMaybe<Scalars['Int']['input']>;
  /** Pagination - page number [default=1] */
  page?: InputMaybe<Scalars['Int']['input']>;
  /** Media item search sort [Default=ASC] */
  sort?: InputMaybe<Sort>;
  /** Search by media item tags. */
  tag?: InputMaybe<MediaLocalizedStringInput>;
};

export type MediaVideoSearchInput = {
  /** Search by unique category identifier */
  categoryId?: InputMaybe<Scalars['Int']['input']>;
  /** Search by unique cluster identifier */
  clusterId?: InputMaybe<Scalars['Int']['input']>;
  /** Search by media item short description. */
  description?: InputMaybe<MediaLocalizedStringInput>;
  /** Pagination - offset number [default=12] */
  offset?: InputMaybe<Scalars['Int']['input']>;
  /** Pagination - page number [default=1] */
  page?: InputMaybe<Scalars['Int']['input']>;
  /** Search by unique product identifier */
  productId?: InputMaybe<Scalars['Int']['input']>;
  /** Media item search sort [Default=ASC] */
  sort?: InputMaybe<Sort>;
  /** Search by unique spareparts machine identifier */
  sparePartsMachineId?: InputMaybe<Scalars['String']['input']>;
  /** Search by media item tags. */
  tag?: InputMaybe<MediaLocalizedStringInput>;
};

export type ObjectMediaSearchInput = {
  /** Search by media item short description. */
  description?: InputMaybe<MediaLocalizedStringInput>;
  /** Pagination - offset number [default=12] */
  offset?: InputMaybe<Scalars['Int']['input']>;
  /** Pagination - page number [default=1] */
  page?: InputMaybe<Scalars['Int']['input']>;
  /** Media item search sort [Default=ASC] */
  sort?: InputMaybe<Sort>;
  /** Search by media item tags. */
  tag?: InputMaybe<MediaLocalizedStringInput>;
};

/**
 * The optimize parameter automatically applies optimal quality compression to produce an output image with as much visual fidelity as possible, while minimizing the file size.
 *
 *   NOTE:
 *   1. Optimize is currently supported by the following output formats: JPEG, WebP.
 *   2. If the quality parameter is also provided, quality overrides optimize because it is more precise.
 *   3. Because optimize attempts to produce an output image with as much visual quality as possible while minimizing the file size, the operations applied are subject to change.
 */
export enum Optimize {
  /** Minor visual artifacts may be visible. This produces the smallest file. */
  HIGH = 'HIGH',
  /** Output image quality will be similar to the input image quality. */
  LOW = 'LOW',
  /** More optimization is allowed. We attempt to preserve the visual quality of the input image. */
  MEDIUM = 'MEDIUM'
}

export type OrderCreateInput = {
  /** User ID of the Account Manager of the order */
  accountManagerId?: InputMaybe<Scalars['Int']['input']>;
  /** Accounting ID of the Order */
  accountingId?: InputMaybe<Scalars['String']['input']>;
  /** Active actioncode that was applied to this Order  */
  actionCode?: InputMaybe<Scalars['String']['input']>;
  /** The ID of the Cart the Order belongs to */
  cartId?: InputMaybe<Scalars['String']['input']>;
  /** Channel ID the Order belongs to */
  channelId?: InputMaybe<Scalars['Int']['input']>;
  /** ID of the Company of the Order */
  companyId?: InputMaybe<Scalars['Int']['input']>;
  /** A company source */
  companySource?: InputMaybe<SourceInput>;
  /** Order currency - ISO 4217 currency code => https://www.xe.com/iso4217.php */
  currency: Scalars['String']['input'];
  /** Currency conversion ratio compared the the Order's Shop's base currency */
  currencyRatio?: InputMaybe<Scalars['Float']['input']>;
  /** Company name of the Debtor [USED FOR ORDER SEARCH ONLY] */
  debtorCompany?: InputMaybe<Scalars['String']['input']>;
  /** First name of the Debtor [USED FOR ORDER SEARCH ONLY] */
  debtorFirstName?: InputMaybe<Scalars['String']['input']>;
  /** Debtor ID of the  User, Contact or Customer  */
  debtorId?: InputMaybe<Scalars['String']['input']>;
  /** Last name of the Debtor [USED FOR ORDER SEARCH ONLY] */
  debtorLastName?: InputMaybe<Scalars['String']['input']>;
  /** Email address used for communication about this Order */
  email: Scalars['String']['input'];
  /** Order export message */
  exportMessage?: InputMaybe<Scalars['String']['input']>;
  /** Order export status of this order */
  exportStatus?: InputMaybe<OrderExportStatus>;
  /** The date and time the order was exported */
  exportedAt?: InputMaybe<Scalars['String']['input']>;
  /** ID of the Order in an external system */
  externalId?: InputMaybe<Scalars['String']['input']>;
  /**
   * extra3: Open text field to add meta data on an Order.
   *     The field will not be visible to Propeller admins, but can be used to be displayed on front-ends or be used in integrations.
   */
  extra3?: InputMaybe<Scalars['String']['input']>;
  /**
   * extra4: Open text field to add meta data on an Order.
   *     The field will not be visible to Propeller admins, but can be used to be displayed on front-ends or be used in integrations.
   */
  extra4?: InputMaybe<Scalars['String']['input']>;
  /** ID of the User, Contact or Customer that should receive the invoice for this Order */
  invoiceUserId?: InputMaybe<Scalars['Int']['input']>;
  /** The OrderItem to create with the Order as a single bulk request */
  items?: Array<OrderItemCreateInput>;
  /** Order language - ISO 639-1 language code => https://localizely.com/iso-639-1-list/ */
  language: Scalars['String']['input'];
  /** Original order id */
  originalOrderId?: InputMaybe<Scalars['Int']['input']>;
  /** Order's payment data */
  paymentData: OrderPaymentInput;
  /** Order's postage data */
  postageData: OrderPostageInput;
  /** Company name of the Recipient [USED FOR ORDER SEARCH ONLY] */
  recipientCompany?: InputMaybe<Scalars['String']['input']>;
  /** First name of the Recipient [USED FOR ORDER SEARCH ONLY] */
  recipientFirstName?: InputMaybe<Scalars['String']['input']>;
  /** Last name of the Recipient [USED FOR ORDER SEARCH ONLY] */
  recipientLastName?: InputMaybe<Scalars['String']['input']>;
  /** Reference by the customer */
  reference?: InputMaybe<Scalars['String']['input']>;
  /** Remarks by the customer */
  remarks?: InputMaybe<Scalars['String']['input']>;
  /** The ID of the shop the Order belongs to */
  shopId: Scalars['Int']['input'];
  /** Source of the Order i.e. webshop, internal, external or api */
  source?: InputMaybe<Scalars['String']['input']>;
  /** Status of the Order */
  status: Scalars['String']['input'];
  /** Order's totals, tax and global discount */
  total: OrderTotalInput;
  /** Type of the Order */
  type: OrderType;
  /** ID of the User, Contact or Customer of the Order */
  userId: Scalars['Int']['input'];
  /** Date and time until the order is valid */
  validUntil?: InputMaybe<Scalars['String']['input']>;
  /** Value points collected with this Order */
  valuePoints?: InputMaybe<Scalars['Int']['input']>;
};

/** Order discount types */
export enum OrderDiscountType {
  /** Absolute discount */
  A = 'A',
  /** No discount */
  N = 'N',
  /** Percentage discount */
  P = 'P'
}

/** Order export status */
export enum OrderExportStatus {
  EMPTY = 'EMPTY',
  EXPORTED = 'EXPORTED',
  FAILED = 'FAILED',
  FINISHED = 'FINISHED'
}

/** Class of the order item */
export enum OrderItemClass {
  incentive = 'incentive',
  payment = 'payment',
  postage = 'postage',
  product = 'product',
  surcharge = 'surcharge'
}

export type OrderItemCreateInput = {
  /** The class of the orderItem. Either product, incentive, surcharge, postage, payment */
  class: OrderItemClass;
  /** The cost price of the OrderItem */
  costPrice?: InputMaybe<Scalars['Float']['input']>;
  /** The calculated customerPrice of the OrderItem at the time the order was placed */
  customerPrice?: InputMaybe<Scalars['Float']['input']>;
  /** The discount of the OrderItem */
  discount?: InputMaybe<Scalars['Float']['input']>;
  /** The eanCode of the product of the OrderItem */
  eanCode?: InputMaybe<Scalars['String']['input']>;
  /** Is the OrderItem a bonusItem? */
  isBonus: YesNo;
  /** The manufacturer of the product of the OrderItem */
  manufacturer?: InputMaybe<Scalars['String']['input']>;
  /** The manufacturerCode of the product of the OrderItem */
  manufacturerCode?: InputMaybe<Scalars['String']['input']>;
  /** The minimum quantity of the product of the OrderItem */
  minimumQuantity?: InputMaybe<Scalars['Int']['input']>;
  /** The minimum quantity of the product of the OrderItem */
  minumumQuantity?: InputMaybe<Scalars['Int']['input']>;
  /** The name of the product of the OrderItem */
  name?: InputMaybe<Scalars['String']['input']>;
  /** Remarks by the customer for the OrderItem */
  notes?: InputMaybe<Scalars['String']['input']>;
  /** The original price of the OrderItem, before applying any discounts */
  originalPrice: Scalars['Float']['input'];
  /** The package of the product of the OrderItem */
  package?: InputMaybe<Scalars['String']['input']>;
  /** The package unit of the product of the OrderItem */
  packageUnit?: InputMaybe<Scalars['String']['input']>;
  /** The package unit quantity of the product of the OrderItem */
  packageUnitQuantity?: InputMaybe<Scalars['String']['input']>;
  /** The ID of the parent OrderItem */
  parentOrderItemId?: InputMaybe<Scalars['Int']['input']>;
  /** The UUID of the parent OrderItem, this can be used in bulk operations where the auto-increment ID's are not known */
  parentOrderItemUUID?: InputMaybe<Scalars['String']['input']>;
  /** The calculated price per unit excluding tax */
  price: Scalars['Float']['input'];
  /** The calculated price per unit including tax */
  priceNet?: InputMaybe<Scalars['Float']['input']>;
  /** The total price of the OrderItem excluding tax */
  priceTotal: Scalars['Float']['input'];
  /** The total price of the OrderItem including tax */
  priceTotalNet?: InputMaybe<Scalars['Float']['input']>;
  /** The productId of the OrderItem */
  productId?: InputMaybe<Scalars['Int']['input']>;
  /** A product source */
  productSource?: InputMaybe<SourceInput>;
  /** The purchase minimum quantity of the product of the OrderItem */
  purchaseMinimumQuantity?: InputMaybe<Scalars['Int']['input']>;
  /** The purchase unit of the product of the OrderItem */
  purchaseUnit?: InputMaybe<Scalars['Int']['input']>;
  /** The quantity of the OrderItem */
  quantity: Scalars['Int']['input'];
  /** Requested delivery date for this orderline */
  requestDate?: InputMaybe<Scalars['String']['input']>;
  /** The SKU of the product of the OrderItem */
  sku: Scalars['String']['input'];
  /** The supplier of the product of the OrderItem */
  supplier?: InputMaybe<Scalars['String']['input']>;
  /** The supplierCode of the product of the OrderItem */
  supplierCode?: InputMaybe<Scalars['String']['input']>;
  /** The total tax of the OrderItem */
  tax?: InputMaybe<Scalars['Float']['input']>;
  /** The tax code of the OrderItem */
  taxCode: Taxcode;
  /** The tax percentage of the OrderItem */
  taxPercentage: Scalars['Int']['input'];
  /** The unit of the product of the OrderItem */
  unit?: InputMaybe<Scalars['Int']['input']>;
  /** The UUID for the OrderItem */
  uuid: Scalars['String']['input'];
};

export type OrderItemUpdateInput = {
  /** The costprice of the orderitem */
  costPrice?: InputMaybe<Scalars['Float']['input']>;
  /** The calculated user specific price of the orderitem, before applying any additional discounts */
  customerPrice?: InputMaybe<Scalars['Float']['input']>;
  /** The discount applied to this orderitem */
  discount: Scalars['Float']['input'];
  /** The ID of the orderitem to update. */
  id: Scalars['Int']['input'];
  /** The orderitem notes */
  notes?: InputMaybe<Scalars['String']['input']>;
  /** The original price of the OrderItem, before applying any discounts */
  originalPrice: Scalars['Float']['input'];
  /** The gross price per unit excluding tax */
  price: Scalars['Float']['input'];
  /** The calculated price per unit including tax */
  priceNet?: InputMaybe<Scalars['Float']['input']>;
  /** The total price of the orderitem excluding tax */
  priceTotal: Scalars['Float']['input'];
  /** The total price of the OrderItem including tax */
  priceTotalNet?: InputMaybe<Scalars['Float']['input']>;
  /** The quantity of the orderitem */
  quantity: Scalars['Int']['input'];
  /** Requested delivery date for this orderline */
  requestDate?: InputMaybe<Scalars['String']['input']>;
  /** The amount of tax applied to this orderitem */
  tax: Scalars['Float']['input'];
  /** The tax code of the OrderItem */
  taxCode: Taxcode;
  /** The tax percentage applied to this orderitem */
  taxPercentage: Scalars['Int']['input'];
};

export type OrderPaymentInput = {
  /** The accountingId that belongs to this order */
  accountingId?: InputMaybe<Scalars['String']['input']>;
  /** The transaction cost excluding tax */
  gross: Scalars['Float']['input'];
  /** The paymethod for this Order */
  method: Scalars['String']['input'];
  /** The transaction cost including tax */
  net: Scalars['Float']['input'];
  /** Whether the transaction costs for this order are overruled, if N, the shipping costs will be recalculated on every mutation */
  overruled?: InputMaybe<YesNo>;
  /** The transaction status */
  status?: InputMaybe<Scalars['String']['input']>;
  /** Last time the transaction status was changed */
  statusDate?: InputMaybe<Scalars['DateTime']['input']>;
  /** The transaction cost tax */
  tax: Scalars['Float']['input'];
  /** The transaction costs tax percentage */
  taxPercentage: Scalars['Float']['input'];
};

export type OrderPaymentUpdateInput = {
  /** The accountingId that belongs to this order */
  accountingId?: InputMaybe<Scalars['String']['input']>;
  /** The transaction cost excluding tax */
  gross?: InputMaybe<Scalars['Float']['input']>;
  /** The paymethod for this Order */
  method?: InputMaybe<Scalars['String']['input']>;
  /** The transaction cost including tax */
  net?: InputMaybe<Scalars['Float']['input']>;
  /** Whether the transaction costs for this order are overruled, if N, the shipping costs will be recalculated on every mutation */
  overruled?: InputMaybe<YesNo>;
  /** The transaction status */
  status?: InputMaybe<Scalars['String']['input']>;
  /** Last time the transaction status was changed */
  statusDate?: InputMaybe<Scalars['DateTime']['input']>;
  /** The transaction cost tax */
  tax?: InputMaybe<Scalars['Float']['input']>;
  /** The transaction costs tax percentage */
  taxPercentage?: InputMaybe<Scalars['Float']['input']>;
};

export type OrderPostageInput = {
  /** The selected carrier for this Order */
  carrier?: InputMaybe<Scalars['String']['input']>;
  /** The shipping costs excluding tax */
  gross: Scalars['Float']['input'];
  /** The chosen shipping method */
  method: Scalars['String']['input'];
  /** The shipping costs including tax */
  net: Scalars['Float']['input'];
  /** Whether the shipping costs for this order are overruled, if N, the shipping costs will be recalculated on every mutation */
  overruled?: InputMaybe<YesNo>;
  /** Is partial delivery allowed for this Order */
  partialDeliveryAllowed?: InputMaybe<YesNo>;
  /** ID of the pick up location when the Order's shipping method is PICKUP */
  pickUpLocationId?: InputMaybe<Scalars['Int']['input']>;
  /** The provided delivery date for the Order */
  requestDate?: InputMaybe<Scalars['DateTime']['input']>;
  /** The shipping costs tax */
  tax: Scalars['Float']['input'];
  /** The shipping costs tax percentage */
  taxPercentage: Scalars['Float']['input'];
};

export type OrderPostageUpdateInput = {
  /** The selected carrier for this Order */
  carrier?: InputMaybe<Scalars['String']['input']>;
  /** The shipping costs excluding tax */
  gross?: InputMaybe<Scalars['Float']['input']>;
  /** The chosen shipping method */
  method?: InputMaybe<Scalars['String']['input']>;
  /** The shipping costs including tax */
  net?: InputMaybe<Scalars['Float']['input']>;
  /** Whether the shipping costs for this order are overruled, if N, the shipping costs will be recalculated on every mutation */
  overruled?: InputMaybe<YesNo>;
  /** Is partial delivery allowed for this Order */
  partialDeliveryAllowed?: InputMaybe<YesNo>;
  /** ID of the pick up location when the Order's shipping method is PICKUP */
  pickUpLocationId?: InputMaybe<Scalars['Int']['input']>;
  /** The provided delivery date for the Order */
  requestDate?: InputMaybe<Scalars['DateTime']['input']>;
  /** The shipping costs tax */
  tax?: InputMaybe<Scalars['Float']['input']>;
  /** The shipping costs tax percentage */
  taxPercentage?: InputMaybe<Scalars['Float']['input']>;
};

export type OrderSearchArguments = {
  /** Search by company ids */
  companyIds?: InputMaybe<Array<Scalars['Int']['input']>>;
  /** List of company sources */
  companySources?: InputMaybe<Array<SourceInput>>;
  /** Search by date created */
  createdAt?: InputMaybe<DateSearchInput>;
  /** Only search for orders with export status */
  exportStatuses?: InputMaybe<Array<OrderExportStatus>>;
  /** Search by export date */
  exportedAt?: InputMaybe<DateSearchInput>;
  /** Search by date changed */
  lastModifiedAt?: InputMaybe<DateSearchInput>;
  /** Pagination offset number */
  offset?: InputMaybe<Scalars['Int']['input']>;
  /** Search by original order ids */
  originalOrderIds?: InputMaybe<Array<Scalars['Int']['input']>>;
  /** Pagination page number */
  page?: InputMaybe<Scalars['Int']['input']>;
  /** Search by price range */
  price?: InputMaybe<DecimalSearchInput>;
  /** Inputs to sort by */
  sortInputs?: InputMaybe<Array<OrderSortInput>>;
  /** Only search for orders in these statuses */
  status?: InputMaybe<Array<Scalars['String']['input']>>;
  /** Search term */
  term?: InputMaybe<Scalars['String']['input']>;
  /** List of fields to search in */
  termFields?: InputMaybe<Array<OrderSearchFields>>;
  /** Only search for orders of the types */
  type?: InputMaybe<Array<OrderType>>;
  /** Pass one order more userId's to retrieve order for */
  userId?: InputMaybe<Array<Scalars['Int']['input']>>;
};

/** Available fields to search for in an order */
export enum OrderSearchFields {
  /** Accounting Unique Identifier */
  ACCOUNTING_ID = 'ACCOUNTING_ID',
  /** Debtor Unique Identifier */
  DEBTOR_ID = 'DEBTOR_ID',
  /** External Order Unique Identifier */
  EXTERNAL_ORDER_ID = 'EXTERNAL_ORDER_ID',
  /** Extra field 3 */
  EXTRA3 = 'EXTRA3',
  /** Extra field 4 */
  EXTRA4 = 'EXTRA4',
  ID = 'ID',
  /** Debtor Company [From Invoice Address] */
  INVOICE_ADDRESS_COMPANY = 'INVOICE_ADDRESS_COMPANY',
  /** Debtor First Name [From Invoice Address] */
  INVOICE_ADDRESS_FIRST_NAME = 'INVOICE_ADDRESS_FIRST_NAME',
  /** Debtor Last Name [From Invoice Address] */
  INVOICE_ADDRESS_LAST_NAME = 'INVOICE_ADDRESS_LAST_NAME',
  /** Item EAN Code */
  ITEM_EAN_CODE = 'ITEM_EAN_CODE',
  /** Item Manufacturer */
  ITEM_MANUFACTURER = 'ITEM_MANUFACTURER',
  /** Item Manufacturer Code */
  ITEM_MANUFACTURER_CODE = 'ITEM_MANUFACTURER_CODE',
  /** Item Name */
  ITEM_NAME = 'ITEM_NAME',
  /** Item Notes */
  ITEM_NOTES = 'ITEM_NOTES',
  /** Item SKU */
  ITEM_SKU = 'ITEM_SKU',
  /** Item Supplier */
  ITEM_SUPPLIER = 'ITEM_SUPPLIER',
  /** Item Supplier Code */
  ITEM_SUPPLIER_CODE = 'ITEM_SUPPLIER_CODE',
  /** Recipient Company [From Delivery Address] */
  RECIPIENT_COMPANY = 'RECIPIENT_COMPANY',
  /** Recipient Email [From Delivery Address] */
  RECIPIENT_EMAIL = 'RECIPIENT_EMAIL',
  /** Recipient First Name [From Delivery Address] */
  RECIPIENT_FIRST_NAME = 'RECIPIENT_FIRST_NAME',
  /** Recipient Last Name [From Delivery Address] */
  RECIPIENT_LAST_NAME = 'RECIPIENT_LAST_NAME',
  /** Reference */
  REFERENCE = 'REFERENCE',
  /** Remarks */
  REMARKS = 'REMARKS'
}

export type OrderSetStatusInput = {
  /** Add the order overview as attached PDF to the order confirmation email */
  addPDFAttachment?: InputMaybe<Scalars['Boolean']['input']>;
  /** Delete the cart that created this order if the cart is still available */
  deleteCart?: InputMaybe<Scalars['Boolean']['input']>;
  /** The id of the order to set the status for */
  orderId: Scalars['Int']['input'];
  /** The payment status of the order */
  payStatus?: InputMaybe<Scalars['String']['input']>;
  /** Send the order confirmation email when the status is changed */
  sendOrderConfirmationEmail?: InputMaybe<Scalars['Boolean']['input']>;
  /** The status of the order */
  status?: InputMaybe<Scalars['String']['input']>;
};

export enum OrderSortField {
  COMPANY = 'COMPANY',
  CREATED_AT = 'CREATED_AT',
  ID = 'ID',
  LAST_MODIFIED_AT = 'LAST_MODIFIED_AT',
  STATUS = 'STATUS',
  TOTAL_GROSS = 'TOTAL_GROSS'
}

export type OrderSortInput = {
  /** Field to sort by */
  field: OrderSortField;
  /** Order option to sort by. [Default to `ASC`] */
  order?: SortOrder;
};

export enum OrderStatusOrderType {
  ORDER = 'ORDER',
  QUOTATION = 'QUOTATION',
  REQUEST = 'REQUEST'
}

export type OrderStatusSearchByInput = {
  /** Order status code */
  code?: InputMaybe<Scalars['String']['input']>;
  /** Order status unique identifier. */
  id?: InputMaybe<Scalars['Int']['input']>;
};

export type OrderStatusSetSearchByInput = {
  /** Order status unique identifier. */
  id?: InputMaybe<Scalars['Int']['input']>;
  /** Order status name */
  name?: InputMaybe<Scalars['String']['input']>;
};

export enum OrderStatusSetSortField {
  ID = 'ID',
  NAME = 'NAME'
}

export type OrderStatusSetSortInput = {
  /** Field to sort by */
  field: OrderStatusSetSortField;
  /** Order option to sort by. [Default to `ASC`] */
  order?: SortOrder;
};

export type OrderStatusSetsSearchInput = {
  /** Order status set name */
  name?: InputMaybe<Scalars['String']['input']>;
  /** Pagination offset number. [Default to `12`] */
  offset?: InputMaybe<Scalars['Int']['input']>;
  /** Pagination page number. [Default to `1`] */
  page?: InputMaybe<Scalars['Int']['input']>;
  /** Inputs to sort by */
  sortInputs?: InputMaybe<Array<OrderStatusSetSortInput>>;
};

export enum OrderStatusSortField {
  CODE = 'CODE',
  CREATED_AT = 'CREATED_AT',
  ID = 'ID',
  LAST_MODIFIED_AT = 'LAST_MODIFIED_AT',
  NAME = 'NAME',
  PRIORITY = 'PRIORITY'
}

export type OrderStatusSortInput = {
  /** Field to sort by */
  field: OrderStatusSortField;
  /** Order option to sort by. [Default to `ASC`] */
  order?: SortOrder;
};

export enum OrderStatusType {
  CUSTOM = 'CUSTOM',
  SYSTEM = 'SYSTEM'
}

export type OrderStatusesSearchInput = {
  /** List of order status codes */
  codes?: InputMaybe<Array<Scalars['String']['input']>>;
  /** Search by date created */
  createdAt?: InputMaybe<DateSearchInput>;
  /** List of order status ids */
  ids?: InputMaybe<Array<Scalars['Int']['input']>>;
  /** Marks if the order can be archived */
  isArchivable?: InputMaybe<Scalars['Boolean']['input']>;
  /** Marks if the order is confirmable */
  isConfirmable?: InputMaybe<Scalars['Boolean']['input']>;
  /** Marks if the order status is default */
  isDefault?: InputMaybe<Scalars['Boolean']['input']>;
  /** Marks if the order is deletable */
  isDeletable?: InputMaybe<Scalars['Boolean']['input']>;
  /** Marks if the order is editable */
  isEditable?: InputMaybe<Scalars['Boolean']['input']>;
  /** Marks if the order is exportable */
  isExportable?: InputMaybe<Scalars['Boolean']['input']>;
  /** Marks if the order is public */
  isPublic?: InputMaybe<Scalars['Boolean']['input']>;
  /** Search by date changed */
  lastModifiedAt?: InputMaybe<DateSearchInput>;
  /** Order status name */
  name?: InputMaybe<Scalars['String']['input']>;
  /** Pagination offset number. [Default to `12`] */
  offset?: InputMaybe<Scalars['Int']['input']>;
  /** Order status for order type */
  orderType?: InputMaybe<Scalars['String']['input']>;
  /** Pagination page number. [Default to `1`] */
  page?: InputMaybe<Scalars['Int']['input']>;
  /** Order status priority */
  priority?: InputMaybe<Scalars['Int']['input']>;
  /** Inputs to sort by */
  sortInputs?: InputMaybe<Array<OrderStatusSortInput>>;
  /** Order status type */
  type?: InputMaybe<Scalars['String']['input']>;
};

export type OrderTotalInput = {
  /** The type of global discount */
  discountType: OrderDiscountType;
  /** The discount value, can be absolute or a percentage depending on the discountType */
  discountValue: Scalars['Float']['input'];
  /** The total of this Order excluding tax */
  gross: Scalars['Float']['input'];
  /** The total of this Order including tax */
  net: Scalars['Float']['input'];
  /** The total amount of tax for this Order */
  tax: Scalars['Float']['input'];
};

export type OrderTotalUpdateInput = {
  /** The type of global discount */
  discountType?: InputMaybe<OrderDiscountType>;
  /** The discount value, can be absolute or a percentage depending on the discountType */
  discountValue?: InputMaybe<Scalars['Float']['input']>;
  /** The total of this Order excluding tax */
  gross?: InputMaybe<Scalars['Float']['input']>;
  /** The total of this Order including tax */
  net?: InputMaybe<Scalars['Float']['input']>;
  /** The total amount of tax for this Order */
  tax?: InputMaybe<Scalars['Float']['input']>;
};

/** Available order types */
export enum OrderType {
  /** Dropshipment order */
  dropshipment = 'dropshipment',
  /** Purchase order */
  purchase = 'purchase',
  /** Quotation order */
  quotation = 'quotation',
  /** Stock order */
  stock = 'stock'
}

export type OrderUpdateAddressInput = {
  /** Address city */
  city?: InputMaybe<Scalars['String']['input']>;
  /** Address code */
  code?: InputMaybe<Scalars['String']['input']>;
  /** User company the address applies to */
  company?: InputMaybe<Scalars['String']['input']>;
  /** Address country */
  country?: InputMaybe<Scalars['String']['input']>;
  /** Address email [valid email format required] */
  email?: InputMaybe<Scalars['String']['input']>;
  /** User first name the address applies to */
  firstName?: InputMaybe<Scalars['String']['input']>;
  /** User gender the address applies to */
  gender?: InputMaybe<Gender>;
  /** ICP address field defines whether tax should be applied to an order if the address is selected as a delivery address. Used when shipping b2b order abroad */
  icp?: InputMaybe<YesNo>;
  /** User last name the address applies to */
  lastName?: InputMaybe<Scalars['String']['input']>;
  /** User middle name the address applies to */
  middleName?: InputMaybe<Scalars['String']['input']>;
  /** Address mobile number [min: 6, max: 20] */
  mobile?: InputMaybe<Scalars['String']['input']>;
  /** Address delivery notes */
  notes?: InputMaybe<Scalars['String']['input']>;
  /** User street number */
  number?: InputMaybe<Scalars['String']['input']>;
  /** User street number extension */
  numberExtension?: InputMaybe<Scalars['String']['input']>;
  /** Address fixed phone number [min: 6, max: 20] */
  phone?: InputMaybe<Scalars['String']['input']>;
  /** Address postal code */
  postalCode?: InputMaybe<Scalars['String']['input']>;
  /** Address region */
  region?: InputMaybe<Scalars['String']['input']>;
  /** User street address */
  street?: InputMaybe<Scalars['String']['input']>;
};

export type OrderUpdateInput = {
  /** User ID of the Account Manager of the order */
  accountManagerId?: InputMaybe<Scalars['Int']['input']>;
  /** Accounting ID of the Order */
  accountingId?: InputMaybe<Scalars['String']['input']>;
  /** Active actioncode that was applied to this Order  */
  actionCode?: InputMaybe<Scalars['String']['input']>;
  /** Channel ID the Order belongs to */
  channelId?: InputMaybe<Scalars['Int']['input']>;
  /** ID of the Company of the Order */
  companyId?: InputMaybe<Scalars['Int']['input']>;
  /** A company source */
  companySource?: InputMaybe<SourceInput>;
  /** Order currency - ISO 4217 currency code => https://www.xe.com/iso4217.php */
  currency?: InputMaybe<Scalars['String']['input']>;
  /** Currency conversion ratio compared the the Order's Shop's base currency */
  currencyRatio?: InputMaybe<Scalars['Float']['input']>;
  /** Company name of the Debtor [USED FOR ORDER SEARCH ONLY] */
  debtorCompany?: InputMaybe<Scalars['String']['input']>;
  /** First name of the Debtor [USED FOR ORDER SEARCH ONLY] */
  debtorFirstName?: InputMaybe<Scalars['String']['input']>;
  /** Debtor ID of the  User, Contact or Customer  */
  debtorId?: InputMaybe<Scalars['String']['input']>;
  /** Last name of the Debtor [USED FOR ORDER SEARCH ONLY] */
  debtorLastName?: InputMaybe<Scalars['String']['input']>;
  /** Email address used for communication about this Order */
  email?: InputMaybe<Scalars['String']['input']>;
  /** Order export message */
  exportMessage?: InputMaybe<Scalars['String']['input']>;
  /** Order export status of this order */
  exportStatus?: InputMaybe<OrderExportStatus>;
  /** The date and time the order was exported */
  exportedAt?: InputMaybe<Scalars['String']['input']>;
  /** ID of the Order in an external system */
  externalId?: InputMaybe<Scalars['String']['input']>;
  /**
   * extra3: Open text field to add meta data on an Order.
   *     The field will not be visible to Propeller admins, but can be used to be displayed on front-ends or be used in integrations.
   */
  extra3?: InputMaybe<Scalars['String']['input']>;
  /**
   * extra4: Open text field to add meta data on an Order.
   *     The field will not be visible to Propeller admins, but can be used to be displayed on front-ends or be used in integrations.
   */
  extra4?: InputMaybe<Scalars['String']['input']>;
  /** ID of the User, Contact or Customer that should receive the invoice for this Order */
  invoiceUserId?: InputMaybe<Scalars['Int']['input']>;
  /** The OrderItem to create or update with the Order as a single bulk request */
  items?: InputMaybe<Array<CreateOrUpdateOrderItemInput>>;
  /** Order language - ISO 639-1 language code => https://localizely.com/iso-639-1-list/ */
  language?: InputMaybe<Scalars['String']['input']>;
  /** Original order id */
  originalOrderId?: InputMaybe<Scalars['Int']['input']>;
  /** Order's payment data */
  paymentData?: InputMaybe<OrderPaymentUpdateInput>;
  /** Order's postage data */
  postageData?: InputMaybe<OrderPostageUpdateInput>;
  /** Company name of the Recipient [USED FOR ORDER SEARCH ONLY] */
  recipientCompany?: InputMaybe<Scalars['String']['input']>;
  /** First name of the Recipient [USED FOR ORDER SEARCH ONLY] */
  recipientFirstName?: InputMaybe<Scalars['String']['input']>;
  /** Last name of the Recipient [USED FOR ORDER SEARCH ONLY] */
  recipientLastName?: InputMaybe<Scalars['String']['input']>;
  /** Reference by the customer */
  reference?: InputMaybe<Scalars['String']['input']>;
  /** Remarks by the customer */
  remarks?: InputMaybe<Scalars['String']['input']>;
  /** Source of the Order i.e. webshop, internal, external or api */
  source?: InputMaybe<Scalars['String']['input']>;
  /** Status of the Order */
  status?: InputMaybe<Scalars['String']['input']>;
  /** Order's totals, tax and global discount */
  total?: InputMaybe<OrderTotalUpdateInput>;
  /** Type of the Order */
  type?: InputMaybe<OrderType>;
  /** ID of the User, Contact or Customer of the Order */
  userId?: InputMaybe<Scalars['Int']['input']>;
  /** Date and time until the order is valid */
  validUntil?: InputMaybe<Scalars['String']['input']>;
  /** Value points collected with this Order */
  valuePoints?: InputMaybe<Scalars['Int']['input']>;
};

export type OrderlistCompaniesInput = {
  /** List of company ids */
  companyIds?: Array<Scalars['Int']['input']>;
  /** List of company sources */
  companySources?: InputMaybe<Array<Source>>;
};

export type OrderlistCreateInput = {
  /** List of cluster ids */
  clusterIds?: InputMaybe<Array<Scalars['Int']['input']>>;
  /** List of cluster sources */
  clusterSources?: InputMaybe<Array<Source>>;
  /** List of company ids */
  companyIds?: InputMaybe<Array<Scalars['Int']['input']>>;
  /** List of company sources */
  companySources?: InputMaybe<Array<Source>>;
  /** Orderlist descriptions per language */
  descriptions?: InputMaybe<Array<LocalizedStringInput>>;
  /** List of product ids */
  productIds?: InputMaybe<Array<Scalars['Int']['input']>>;
  /** List of product sources */
  productSources?: InputMaybe<Array<Source>>;
  /** Orderlist type! One of: [POSITIVE, NEGATIVE] */
  type: OrderlistType;
  /** List of user ids */
  userIds?: InputMaybe<Array<Scalars['Int']['input']>>;
  /** List of user sources */
  userSources?: InputMaybe<Array<Source>>;
};

export type OrderlistItemsInput = {
  /** List of cluster ids */
  clusterIds?: InputMaybe<Array<Scalars['Int']['input']>>;
  /** List of cluster sources */
  clusterSources?: InputMaybe<Array<Source>>;
  /** List of product ids */
  productIds?: InputMaybe<Array<Scalars['Int']['input']>>;
  /** List of product sources */
  productSources?: InputMaybe<Array<Source>>;
};

export type OrderlistSearchInput = {
  /** List of cluster ids */
  clusterIds?: InputMaybe<Array<Scalars['Int']['input']>>;
  /** List of cluster sources */
  clusterSources?: InputMaybe<Array<Source>>;
  /** List of company ids */
  companyIds?: InputMaybe<Array<Scalars['Int']['input']>>;
  /** List of company sources */
  companySources?: InputMaybe<Array<Source>>;
  /** Search by date created */
  createdAt?: InputMaybe<DateSearchInput>;
  /** List of Orderlist primary identifiers */
  ids?: InputMaybe<Array<Scalars['Int']['input']>>;
  /** Search by date changed */
  lastModifiedAt?: InputMaybe<DateSearchInput>;
  /** Pagination offset number. [Default to `12`] */
  offset?: InputMaybe<Scalars['Int']['input']>;
  /** Pagination page number. [Default to `1`] */
  page?: InputMaybe<Scalars['Int']['input']>;
  /** List of product ids */
  productIds?: InputMaybe<Array<Scalars['Int']['input']>>;
  /** List of product sources */
  productSources?: InputMaybe<Array<Source>>;
  /** Inputs to sort by */
  sortInputs?: InputMaybe<Array<OrderlistSortInput>>;
  /** Orderlist type. One of: [POSITIVE, NEGATIVE] */
  type?: InputMaybe<OrderlistType>;
  /** List of user ids */
  userIds?: InputMaybe<Array<Scalars['Int']['input']>>;
  /** List of user sources */
  userSources?: InputMaybe<Array<Source>>;
};

export enum OrderlistSortField {
  CREATED_AT = 'CREATED_AT',
  DESCRIPTIONS = 'DESCRIPTIONS',
  ID = 'ID',
  LAST_MODIFIED_AT = 'LAST_MODIFIED_AT'
}

export type OrderlistSortInput = {
  /** Field to sort by */
  field: OrderlistSortField;
  /** Order option to sort by. [Default to `ASC`] */
  order?: SortOrder;
};

export enum OrderlistType {
  NEGATIVE = 'NEGATIVE',
  POSITIVE = 'POSITIVE'
}

export type OrderlistUpdateInput = {
  /** List of cluster ids */
  clusterIds?: InputMaybe<Array<Scalars['Int']['input']>>;
  /** List of cluster sources */
  clusterSources?: InputMaybe<Array<Source>>;
  /** List of company ids */
  companyIds?: InputMaybe<Array<Scalars['Int']['input']>>;
  /** List of company sources */
  companySources?: InputMaybe<Array<Source>>;
  /** Orderlist descriptions per language */
  descriptions?: InputMaybe<Array<LocalizedStringInput>>;
  /** List of product ids */
  productIds?: InputMaybe<Array<Scalars['Int']['input']>>;
  /** List of product sources */
  productSources?: InputMaybe<Array<Source>>;
  /** Orderlist type! One of: [POSITIVE, NEGATIVE] */
  type?: InputMaybe<OrderlistType>;
  /** List of user ids */
  userIds?: InputMaybe<Array<Scalars['Int']['input']>>;
  /** List of user sources */
  userSources?: InputMaybe<Array<Source>>;
};

export type OrderlistUsersInput = {
  /** List of user ids */
  userIds?: Array<Scalars['Int']['input']>;
  /** List of user sources */
  userSources?: InputMaybe<Array<Source>>;
};

/**
 * The orient parameter controls the cardinal orientation of the image.
 *
 *   NOTE:
 *   - By default, if the source image contains orientation information stored within its metadata, that orientation will be applied to the image data and the orientation override removed from metadata.
 *  - The numerical values are the same as EXIF rotation numbers.
 */
export enum Orient {
  /** Orient the image left. */
  EIGHT = 'EIGHT',
  /** Flip the image horizontally, then orient the image left (also rv or vr). */
  FIVE = 'FIVE',
  /** Flip the image vertically. */
  FOUR = 'FOUR',
  /** Flip the image horizontally. */
  HORIZONTAL_FLIP = 'HORIZONTAL_FLIP',
  /** Flip the image both horizontally and vertically (also vh). */
  HV_FLIP = 'HV_FLIP',
  /** Orient the image left. */
  LEFT = 'LEFT',
  /** Default - don't do anything... */
  ONE = 'ONE',
  /** Orient the image right. */
  RIGHT = 'RIGHT',
  /** Flip the image horizontally, then orient the image right (also lv or vl). */
  SEVEN = 'SEVEN',
  /** Orient the image right. */
  SIX = 'SIX',
  /** Flip the image horizontally and vertically. */
  THREE = 'THREE',
  /** Flip the image horizontally. */
  TWO = 'TWO',
  /** Flip the image horizontally. */
  VERTICAL_FLIP = 'VERTICAL_FLIP'
}

/**
 * Add pixels to the edge of an image.
 *
 * Notes
 * - By default padding applies a background color of white. See bg-color to change that.
 * - If the source image contains a transparent background and the output image also contains transparency, the padding will be made up of transparent pixels.
 * - When using pad and canvas at the same time, pad will be ignored.
 * - Values can be specified using CSS style shorthand values. (see CSS Margin or CSS Padding for more examples).
 * - CSS shorthand allows for all edges to be specified in one property.
 * - Any fractional pixel measurements will be rounded to the nearest whole pixel.
 */
export type PadInput = {
  /** Bottom - Pixels or Percentage - Default=0 */
  bottom?: InputMaybe<Scalars['Int']['input']>;
  /** Left - Pixels or Percentage - Default=0 */
  left?: InputMaybe<Scalars['Int']['input']>;
  /** Right - Pixels or Percentage - Default=0 */
  right?: InputMaybe<Scalars['Int']['input']>;
  /** Top - Pixels or Percentage - Default=0 */
  top?: InputMaybe<Scalars['Int']['input']>;
};

export type PasswordRecoveryLinkInput = {
  /** The email address of the user to generate the recovery link for */
  email: Scalars['String']['input'];
  language?: InputMaybe<Scalars['String']['input']>;
  /** The page the user will be redirected to after the user changed his/her password. */
  redirectUrl?: InputMaybe<Scalars['String']['input']>;
};

export type PasswordResetLinkEmailInput = {
  /** Attachments to add to the email */
  attachments?: InputMaybe<Array<Base64FileInput>>;
  /** The content of the email, use when the content can not be derived from other inputs */
  content?: InputMaybe<Scalars['String']['input']>;
  /** The email address of the user to generate the recovery link for */
  email: Scalars['String']['input'];
  /** The sender of the email, use when the sender can not be derived from other inputs */
  from?: InputMaybe<EmailSendContactInput>;
  language?: InputMaybe<Scalars['String']['input']>;
  /** The id of the Propeller letter to use when type is 'campaign' */
  letterId?: InputMaybe<Scalars['String']['input']>;
  /** Text to display that will lead to link when clicked */
  linkText?: InputMaybe<Scalars['String']['input']>;
  /** The id of the order to use when triggering an event of type orderconfirm */
  orderId?: InputMaybe<Scalars['Int']['input']>;
  /** The page the user will be redirected to after the user changed their password. */
  redirectUrl?: InputMaybe<Scalars['String']['input']>;
  /** The id of the site to use when sending the email, required when the site can not be derived from other inputs */
  siteId?: InputMaybe<Scalars['Int']['input']>;
  /** The subject of the email, use when the subject can not be derived from other inputs */
  subject?: InputMaybe<Scalars['String']['input']>;
  /** The type of email event to trigger */
  type: EmailEventType;
  /** The id of the user to send to email to. Can be used to replace to, when the user can't be derived from other inputs */
  userId?: InputMaybe<Scalars['Int']['input']>;
  /** Variables that can be used in the email template */
  variables?: InputMaybe<Scalars['JSONObject']['input']>;
};

export type PayMethodCreateInput = {
  /** External code (Screaming Snake Case) */
  externalCode: Scalars['String']['input'];
  /** Logo url */
  logo?: InputMaybe<Scalars['String']['input']>;
  /** Name per language */
  names?: Array<LocalizedStringInput>;
};

export type PayMethodSearchInput = {
  /** Search by date created */
  createdAt?: InputMaybe<DateSearchInput>;
  /** List of external codes */
  externalCodes?: InputMaybe<Array<Scalars['String']['input']>>;
  /** List of primary identifiers */
  ids?: InputMaybe<Array<Scalars['Int']['input']>>;
  /** Search by date changed */
  lastModifiedAt?: InputMaybe<DateSearchInput>;
  /** Pagination offset number. [Default to `12`] */
  offset?: InputMaybe<Scalars['Int']['input']>;
  /** Pagination page number. [Default to `1`] */
  page?: InputMaybe<Scalars['Int']['input']>;
  /** Inputs to sort by */
  sortInputs?: InputMaybe<Array<PayMethodSortInput>>;
};

export enum PayMethodSortField {
  CREATED_AT = 'CREATED_AT',
  EXTERNAL_CODE = 'EXTERNAL_CODE',
  ID = 'ID',
  LAST_MODIFIED_AT = 'LAST_MODIFIED_AT'
}

export type PayMethodSortInput = {
  /** Field to sort by */
  field: PayMethodSortField;
  /** Order option to sort by. [Default to `ASC`] */
  order?: SortOrder;
};

export type PayMethodUpdateInput = {
  /** External code (Screaming Snake Case) */
  externalCode?: InputMaybe<Scalars['String']['input']>;
  /** Logo url */
  logo?: InputMaybe<Scalars['String']['input']>;
  /** Name per language */
  names?: InputMaybe<Array<LocalizedStringInput>>;
};

/** Available payment statuses */
export enum PaymentStatuses {
  AUTHORIZED = 'AUTHORIZED',
  CANCELLED = 'CANCELLED',
  CHARGEBACK = 'CHARGEBACK',
  EXPIRED = 'EXPIRED',
  FAILED = 'FAILED',
  OPEN = 'OPEN',
  PAID = 'PAID',
  PENDING = 'PENDING',
  REFUNDED = 'REFUNDED'
}

export type PaymentsSearchInput = {
  /** Pagination offset number. [Default to `12`] */
  offset?: InputMaybe<Scalars['Int']['input']>;
  /** Pagination page number. [Default to `1`] */
  page?: InputMaybe<Scalars['Int']['input']>;
};

/** Permission types */
export enum Permission {
  CHANGEPERMISSIONS = 'CHANGEPERMISSIONS',
  CREATEOBJECT_WRITEDATA = 'CREATEOBJECT_WRITEDATA',
  DELETE = 'DELETE',
  DETESUBFOLDERSANDFILES = 'DETESUBFOLDERSANDFILES',
  LISTFOLDER_READDATA = 'LISTFOLDER_READDATA',
  READATTRIBUTES = 'READATTRIBUTES',
  READPERMISSIONS = 'READPERMISSIONS',
  TAKEOWNERSHIP = 'TAKEOWNERSHIP',
  WRITEATTRIBUTES = 'WRITEATTRIBUTES'
}

export type PriceFilterInput = {
  /** Price from filter */
  from: Scalars['Float']['input'];
  /** Price to filter */
  to: Scalars['Float']['input'];
};

/** Indicates where the price is calculated. PLATFORM - Price was calculated based on product price/postage rules set in Propeller. EXTERNAL - price is set externally */
export enum PriceMode {
  EXTERNAL = 'EXTERNAL',
  PLATFORM = 'PLATFORM'
}

/** Available product classes */
export enum ProductClass {
  cluster = 'cluster',
  product = 'product'
}

/** Product price types */
export enum ProductPriceType {
  bulk1 = 'bulk1',
  bulk2 = 'bulk2',
  bulk3 = 'bulk3',
  bulk4 = 'bulk4',
  default = 'default',
  discount = 'discount',
  productDiscount = 'productDiscount'
}

/** All available product statuses */
export enum ProductStatus {
  A = 'A',
  N = 'N',
  P = 'P',
  R = 'R',
  S = 'S',
  T = 'T'
}

export type RangeFilterInput = {
  /** Range filter exclude */
  exclude?: Scalars['Boolean']['input'];
  /** Price from filter */
  from: Scalars['Float']['input'];
  /** Range filter search identifier */
  searchId: Scalars['String']['input'];
  /** Price to filter */
  to: Scalars['Float']['input'];
  /** Range filter type */
  type?: InputMaybe<AttributeType>;
};

export type RegisterContactInput = {
  /** Contacts's custom attribute values. This field is used to set value on attributes for the selected contact. Attributes that are used here (selected by name) must already be defined in Propeller. */
  attributes?: InputMaybe<Array<UserManagementAttributeValueInput>>;
  dateOfBirth?: InputMaybe<Scalars['String']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  gender?: InputMaybe<Gender>;
  homepage?: InputMaybe<Scalars['String']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  mailingList?: InputMaybe<YesNo>;
  middleName?: InputMaybe<Scalars['String']['input']>;
  mobile?: InputMaybe<Scalars['String']['input']>;
  /** The id of the company the contact should be created in. */
  parentId?: InputMaybe<Scalars['Int']['input']>;
  /** Optionally create a prefilled password. When omitted the contact can set a new password using password reset(link) */
  password?: InputMaybe<Scalars['String']['input']>;
  phone?: InputMaybe<Scalars['String']['input']>;
  /** The primary language of the contact. */
  primaryLanguage?: InputMaybe<Scalars['String']['input']>;
};

export type RegisterCustomerInput = {
  /** Customer's custom attribute values. This field is used to set value on attributes for the selected customer. Attributes that are used here (selected by name) must already be defined in Propeller. */
  attributes?: InputMaybe<Array<UserManagementAttributeValueInput>>;
  dateOfBirth?: InputMaybe<Scalars['String']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  gender?: InputMaybe<Gender>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  mailingList?: InputMaybe<YesNo>;
  middleName?: InputMaybe<Scalars['String']['input']>;
  mobile?: InputMaybe<Scalars['String']['input']>;
  /** The id of the usergroup the customer should be created in. */
  parentId?: InputMaybe<Scalars['Int']['input']>;
  /** Optionally create a prefilled password. When omitted the customer can set a new password using password reset(link) */
  password?: InputMaybe<Scalars['String']['input']>;
  phone?: InputMaybe<Scalars['String']['input']>;
  /** The primary language of the customer. */
  primaryLanguage?: InputMaybe<Scalars['String']['input']>;
};

export type RegisterUserInput = {
  autoLogin?: InputMaybe<Scalars['Boolean']['input']>;
  cocNumber?: InputMaybe<Scalars['String']['input']>;
  company?: InputMaybe<Scalars['String']['input']>;
  createAccount?: InputMaybe<Scalars['Boolean']['input']>;
  dateOfBirth?: InputMaybe<Scalars['String']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  gender?: InputMaybe<Gender>;
  homepage?: InputMaybe<Scalars['String']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  mailingList?: InputMaybe<YesNo>;
  middleName?: InputMaybe<Scalars['String']['input']>;
  mobile?: InputMaybe<Scalars['String']['input']>;
  /** The id of the usergroup the user should be created in. */
  parentId?: InputMaybe<Scalars['Int']['input']>;
  /** optionally create a prefilled password. When omitted the user can set a new password using password reset(link) */
  password?: InputMaybe<Scalars['String']['input']>;
  phone?: InputMaybe<Scalars['String']['input']>;
  /** The primary language of the user. */
  primaryLanguage?: InputMaybe<Scalars['String']['input']>;
  taxNumber?: InputMaybe<Scalars['String']['input']>;
};

export type RemoveOrderStatusesFromOrderStatusSetInput = {
  /** The ids of the order statuses that will be removed from the set */
  orderStatusIds: Array<Scalars['Int']['input']>;
};

/**
 * The resize-filter parameter enables control over the resizing filter used to generate a new image with a higher or lower number of pixels.
 *
 *   NOTES:
 *     1. When making an image smaller, use bicubic, which has a natural sharpening effect.
 *     2. When making an image larger, use bilinear, which has a natural smoothing effect.
 *     3. When resizing pixel art, use nearest, which has a natural pixelation effect.
 *     4. When quality is the main concern, use lanczos, which typically renders the best results.
 */
export enum ResizeFilter {
  /** Uses an average of a 4x4 environment of pixels, weighing the innermost pixels higher. */
  BICUBIC = 'BICUBIC',
  /** Uses an average of a 2x2 environment of a pixels. */
  BILINEAR = 'BILINEAR',
  /** Uses the Lanczos filter to increase the ability to detect edges and linear features within an image and uses sinc resampling to provide the best possible reconstruction. */
  LANCZOS2 = 'LANCZOS2',
  /** Lanczos3 uses a better approximation of the sinc resampling function. (Default) */
  LANCZOS3 = 'LANCZOS3',
  /** Uses the value of nearby translated pixel values. */
  NEAREST = 'NEAREST'
}

export type SearchByInput = {
  /** Propeller payment identifier */
  id?: InputMaybe<Scalars['ID']['input']>;
  /** Propeller orderId unique payment identifier */
  orderId?: InputMaybe<Scalars['Float']['input']>;
  /** PSP payment identifier */
  paymentId?: InputMaybe<Scalars['String']['input']>;
};

export type SearchFieldsInput = {
  /** Search boost value */
  boost: Scalars['Int']['input'];
  /** List of available sortable fields */
  fieldNames: Array<Searchablefields>;
};

export type SearchSparePartsMachineInput = {
  offset?: Scalars['Int']['input'];
  page?: Scalars['Int']['input'];
};

/** All field that can be searched and boosted with the regular term input. */
export enum Searchablefields {
  description = 'description',
  eanCode = 'eanCode',
  keywords = 'keywords',
  keywordsCustom = 'keywordsCustom',
  manufacturer = 'manufacturer',
  manufacturerCode = 'manufacturerCode',
  name = 'name',
  shortDescription = 'shortDescription',
  sku = 'sku',
  supplier = 'supplier',
  supplierCode = 'supplierCode'
}

/**
 * Sharpness of the output image.
 *
 * The sharpen parameter increases the definition of the edges of objects in an image.
 *
 * Notes
 * - The sharpen parameter is an implementation of an unsharp mask.
 */
export type SharpenInput = {
  /** Sharpen amount value */
  amount?: InputMaybe<Scalars['Float']['input']>;
  /** Sharpen radius value */
  radius?: InputMaybe<Scalars['Int']['input']>;
  /** Sharpen threshold value */
  threshold?: InputMaybe<Scalars['Int']['input']>;
};

/** Image Search Sort */
export enum Sort {
  /** Sort ascending */
  ASC = 'ASC',
  /** Sort descending */
  DESC = 'DESC'
}

export type SortInput = {
  /** Available sortable fields */
  field: SortableFields;
  /** Sort ordering ['desc' or 'asc'] */
  order?: InputMaybe<SortOrder>;
};

/** Order options for sorting */
export enum SortOrder {
  /** Ascending order */
  ASC = 'ASC',
  /** Descending order */
  DESC = 'DESC',
  /**
   * Ascending order
   * @deprecated Use ASC instead
   */
  asc = 'asc',
  /**
   * Descending order
   * @deprecated Use DESC instead
   */
  desc = 'desc'
}

/** Fields available for sorting */
export enum SortableFields {
  categoryOrder = 'categoryOrder',
  dateChanged = 'dateChanged',
  dateCreated = 'dateCreated',
  name = 'name',
  price = 'price',
  relevance = 'relevance',
  shortName = 'shortName',
  sku = 'sku',
  supplierCode = 'supplierCode'
}

export type Source = {
  /** Source ID */
  id: Scalars['String']['input'];
  /** Name of the source */
  name: Scalars['String']['input'];
};

export type SourceInput = {
  /** Source ID */
  id: Scalars['String']['input'];
  /** Name of the source */
  name: Scalars['String']['input'];
};

export type StandardFileUploadInput = {
  /** File reference from filesystem [GraphQL Upload form-data specification](https://github.com/jaydenseric/graphql-multipart-request-spec) */
  file: Scalars['Upload']['input'];
  /** Name to use for storing the file [if not provided the actual upload file name will be used] */
  fileName?: InputMaybe<Scalars['String']['input']>;
};

/** All supported tax codes */
export enum TaxCode {
  H = 'H',
  L = 'L',
  N = 'N'
}

export type TaxSearchInput = {
  offset?: Scalars['Int']['input'];
  page?: Scalars['Int']['input'];
  /** Only search for taxes for shop */
  shopId?: InputMaybe<Scalars['Int']['input']>;
  /** Only search for taxes for zone */
  zone?: InputMaybe<Scalars['String']['input']>;
};

/** All supported taxcodes */
export enum Taxcode {
  /** High tax rate */
  H = 'H',
  /** Low tax rate */
  L = 'L',
  /** No tax rate */
  N = 'N'
}

export type TenderAddItemInput = {
  /**
   * Adds child items to this tender item. This field is used when adding configurable clusters to the Tender.
   *   Provide an array of children options/ products of the parent item.
   */
  childItems?: InputMaybe<Array<TenderChildItemInput>>;
  /** Delivery date */
  deliveryDate?: InputMaybe<Scalars['DateTime']['input']>;
  /** Item name. Defaults to product name */
  name?: InputMaybe<Scalars['String']['input']>;
  /** Notes, remarks, etc. */
  notes?: InputMaybe<Scalars['String']['input']>;
  /** Item price. Defaults to calculated sales price */
  price?: InputMaybe<Scalars['Float']['input']>;
  /** Product ID */
  productId: Scalars['Int']['input'];
  /** Item quantity */
  quantity: Scalars['Int']['input'];
  /** Item supplier. Defaults to product supplier */
  supplier?: InputMaybe<Scalars['String']['input']>;
};

export type TenderAddItemsInput = {
  /** If set to true and an existing item for the same product is found, a new tender item will be created. Existing items are updated by default */
  forceAdd?: InputMaybe<Scalars['Boolean']['input']>;
  /** Tender items */
  products: Array<TenderAddItemInput>;
  /** Tender unique ID */
  tenderId: Scalars['String']['input'];
  /** If set to false, budget validation will be skipped. Defaults to true */
  validateBudget?: InputMaybe<Scalars['Boolean']['input']>;
  /** If set to false, the items will not be validated against customer order lists. Defaults to true */
  validateOrderList?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Address types for Tenders */
export enum TenderAddressType {
  delivery = 'delivery',
  invoice = 'invoice'
}

export type TenderChildItemInput = {
  /** Delivery date */
  deliveryDate?: InputMaybe<Scalars['DateTime']['input']>;
  /** Item name. Defaults to product name */
  name?: InputMaybe<Scalars['String']['input']>;
  /** Notes, remarks, etc. */
  notes?: InputMaybe<Scalars['String']['input']>;
  /** Item price. Defaults to calculated sales price */
  price?: InputMaybe<Scalars['Float']['input']>;
  /** Product ID */
  productId: Scalars['Int']['input'];
};

export type TenderDeleteItemInput = {
  /** Tender item ID */
  itemId: Scalars['Int']['input'];
  /** Tender unique ID */
  tenderId: Scalars['String']['input'];
};

export type TenderDiscountInput = {
  /** Tender unique ID */
  tenderId: Scalars['String']['input'];
  /** Order discount type */
  type: DiscountType;
  /** Discount amount/ percentage, depending on discount type */
  value: Scalars['Float']['input'];
};

/** Order item types */
export enum TenderOrderItemType {
  incentive = 'incentive',
  product = 'product',
  surcharge = 'surcharge'
}

/** Order types */
export enum TenderOrderType {
  dropshipment = 'dropshipment',
  purchase = 'purchase',
  quotation = 'quotation',
  stock = 'stock'
}

export type TenderPaymentInput = {
  /** Accounting ID for this tender/order */
  accountingId?: InputMaybe<Scalars['String']['input']>;
  /** The costs associated with the selected paymethod on this tender/order. VAT excluding. If omitted, payment costs are automatically calculated */
  amountGross?: InputMaybe<Scalars['Float']['input']>;
  /** Payment method for this tender/order */
  method?: InputMaybe<Scalars['String']['input']>;
  /** Payment status for this tender/order */
  status?: InputMaybe<Scalars['String']['input']>;
  /** Tender unique ID */
  tenderId: Scalars['String']['input'];
  /** Payment transaction ID for this tender/order */
  transactionId?: InputMaybe<Scalars['String']['input']>;
};

export type TenderPostageInput = {
  /** The carrier for this tender/order */
  carrier?: InputMaybe<Scalars['String']['input']>;
  /** This order can be shipped in parts when items different delivery dates */
  partialDeliveryAllowed?: InputMaybe<Scalars['Boolean']['input']>;
  /** Pick up location ID */
  pickUpLocationId?: InputMaybe<Scalars['Int']['input']>;
  /** Shipping costs, VAT excluding. If omitted, shipping costs are automatically calculated */
  postageGross?: InputMaybe<Scalars['Float']['input']>;
  /** The preferred delivery date for this order as requested by the user */
  requestDate?: InputMaybe<Scalars['DateTime']['input']>;
  /** The shipping method for this tender/order */
  shippingMethod?: InputMaybe<Scalars['String']['input']>;
  /** Tender unique ID */
  tenderId: Scalars['String']['input'];
  /** Track & Trace code */
  trackTrace?: InputMaybe<Scalars['String']['input']>;
};

export type TenderStart = {
  /** The channel id for the tender created */
  channelId?: InputMaybe<Scalars['Int']['input']>;
  /** The currency for the tender created. Defaults to EUR */
  currency?: InputMaybe<Scalars['String']['input']>;
  /** The id of the user editing the order. Defaults to userId */
  ownerId?: InputMaybe<Scalars['Int']['input']>;
  /** The site id for the tender created */
  siteId?: InputMaybe<Scalars['Int']['input']>;
  /** The type for the tender created. Defaults to dropshipment */
  type?: InputMaybe<TenderOrderType>;
  /** The user ID for the tender created */
  userId: Scalars['Int']['input'];
};

export type TenderUpdateAddressInput = {
  /** City */
  city: Scalars['String']['input'];
  /** Address code */
  code?: InputMaybe<Scalars['String']['input']>;
  /** Address company */
  company?: InputMaybe<Scalars['String']['input']>;
  /** Country code */
  country: Scalars['String']['input'];
  /** Address contact email */
  email?: InputMaybe<Scalars['String']['input']>;
  /** Address contact fax number */
  fax?: InputMaybe<Scalars['String']['input']>;
  /** Address contact first name */
  firstName?: InputMaybe<Scalars['String']['input']>;
  /** Address contact gender */
  gender?: InputMaybe<Gender>;
  /** ICP is Yes when taxes do not apply for shipping to the given address/ country. Defaults to No */
  icp?: InputMaybe<YesNo>;
  /** Address contact last name */
  lastName?: InputMaybe<Scalars['String']['input']>;
  /** Address contact middle name */
  middleName?: InputMaybe<Scalars['String']['input']>;
  /** Address contact mobile phone number */
  mobile?: InputMaybe<Scalars['String']['input']>;
  /** Notes, remarks, etc. */
  notes?: InputMaybe<Scalars['String']['input']>;
  /** Number */
  number?: InputMaybe<Scalars['String']['input']>;
  /** Number extension */
  numberExtension?: InputMaybe<Scalars['String']['input']>;
  /** Address contact phone number */
  phone?: InputMaybe<Scalars['String']['input']>;
  /** Postal code */
  postalCode: Scalars['String']['input'];
  /** Region */
  region?: InputMaybe<Scalars['String']['input']>;
  /** Street */
  street: Scalars['String']['input'];
  /** Tender unique ID */
  tenderId: Scalars['String']['input'];
  /** Address type */
  type: TenderAddressType;
  /** Address contact URL */
  url?: InputMaybe<Scalars['String']['input']>;
};

export type TenderUpdateInput = {
  /** User's reference for this tender */
  reference?: InputMaybe<Scalars['String']['input']>;
  /** User's remarks for this tender */
  remarks?: InputMaybe<Scalars['String']['input']>;
  /** Status of this tender */
  status?: InputMaybe<Scalars['String']['input']>;
  /** Tender unique ID */
  tenderId: Scalars['String']['input'];
};

export type TenderUpdateItemInput = {
  /** Delivery date */
  deliveryDate?: InputMaybe<Scalars['DateTime']['input']>;
  /** Tender item ID */
  itemId: Scalars['Int']['input'];
  /** Item name. Defaults to product name */
  name?: InputMaybe<Scalars['String']['input']>;
  /** Notes, remarks, etc. */
  notes?: InputMaybe<Scalars['String']['input']>;
  /** Item price. Defaults to calculated sales price */
  price?: InputMaybe<Scalars['Float']['input']>;
  /** Item quantity */
  quantity?: InputMaybe<Scalars['Int']['input']>;
  /** Item supplier. Defaults to product supplier */
  supplier?: InputMaybe<Scalars['String']['input']>;
  /** Tender unique ID */
  tenderId: Scalars['String']['input'];
  /** If set to false, budget validation will be skipped. Defaults to true */
  validateBudget?: InputMaybe<Scalars['Boolean']['input']>;
  /** If set to false, the items will not be validated against customer order lists. Defaults to true */
  validateOrderList?: InputMaybe<Scalars['Boolean']['input']>;
};

export type TextFilterInput = {
  /** Text filter exclude */
  exclude?: Scalars['Boolean']['input'];
  /** Text filter search identifier */
  searchId: Scalars['String']['input'];
  /** Text filter type */
  type?: InputMaybe<AttributeType>;
  /** Text filter value */
  values: Array<Scalars['String']['input']>;
};

/** Available transaction statuses */
export enum TransactionStatuses {
  FAILED = 'FAILED',
  OPEN = 'OPEN',
  PENDING = 'PENDING',
  SUCCESS = 'SUCCESS'
}

/** Available transaction types */
export enum TransactionTypes {
  AUTHORIZATION = 'AUTHORIZATION',
  CANCEL_AUTHORIZATION = 'CANCEL_AUTHORIZATION',
  CHARGEBACK = 'CHARGEBACK',
  PAY = 'PAY',
  REFUND = 'REFUND'
}

export type TransformationInput = {
  /**
   * Enable optimization features automatically.
   *
   * Enables optimizations based on content negotiation.
   *
   * The only negotiated optimizations currently available are for AVIF and WebP, both image compression formats with limited browser support.
   *
   * This functionality is also possible using format=auto which adds additional support for JPEGXL output.
   *
   * Notes
   * - Although the AVIF and WebP formats produce images at a higher compression ratio with a lower loss of quality compared to JPEG, they are not supported in all browsers.
   * - When using Firefox version 66 to 71, automatic content negotiation will not occur when navigating to the image. To view WebP or AVIF images generated via auto=avif or auto=webp you must insert your image onto a web page.
   * - format=auto will take precedence if combined with auto.
   */
  auto?: InputMaybe<Auto>;
  /**
   * Set the background color of an image.
   *
   * The bg-color parameter sets the background color of an image to use when applying padding or when replacing transparent pixels.
   *
   * Its value may be one of:
   *
   * - Hex RGB value: Both 3- and 6-digit values are acceptable (e.g. a22 or cf23a5).
   * - Decimal RGB: RGB values between 0 and 255. Comma-delimited (e.g. 255,0,0).
   * - Decimal RGB with alpha|RGBA colors include an additional value for alpha (transparency), which ranges from 0 for fully transparent to 1 for fully opaque.
   *
   * Examples
   * - "c5c5c5"      => Set background color to grey (Hex RGB)
   * - "000"         => Set background color to black (Hex RGB)
   * - "255,0,0"     => Set background color to red (Decimal RGB)
   * - "255,0,0,0.5" => Set background color to red with 50% transparency (Decimal RGBA)
   */
  bgColor?: InputMaybe<Scalars['String']['input']>;
  /**
   * Set the blurriness of the output image.
   *
   * Applies a Gaussian blur filter to the image.
   *
   * Its value is a number of pixels between 0.5 and 1000 or a percentage (of the dimensions of the image) suffixed with p (e.g., 1p for 1%).
   *
   * Examples
   * - "50"   => Apply a Gaussian blur with a radius of 50px
   * - "0.5p" => Apply a Gaussian blur with a radius of 0.5% of the image size
   */
  blur?: InputMaybe<Scalars['Float']['input']>;
  /**
   * Set the brightness of the output image.
   *
   * The brightness parameter increases or decreases the amount of perceived light an image radiates or reflects.
   *
   * Its value may be any number between -100 and 100.
   *
   * Examples
   * - 50  => Increase the brightness by 50
   * - -42 => Decrease the brightness by 42
   */
  brightness?: InputMaybe<Scalars['Int']['input']>;
  /** Increase the size of the canvas around an image. */
  canvas?: InputMaybe<CanvasInput>;
  /**
   * Set the contrast of the output image.
   *
   * The contrast parameter increases or decreases the difference between the darkest and lightest tones in an image.
   *
   * Notes
   * - The default value is 0. This leaves the image unchanged.
   * - A value of -100 will result in a fully grey image.
   *
   * Examples
   * - 50  => Increase the contrast by 50
   */
  contrast?: InputMaybe<Scalars['Int']['input']>;
  /** Remove pixels from an image. */
  crop?: InputMaybe<CropInput>;
  /**
   * Disable functionality that is enabled by default.
   *
   * upscale is already disabled by default. It can be enabled with the enable parameter.
   *
   * Notes
   * - Image upscaling is discouraged because it increases both the file size and delivery time of the image to the end user with no visual improvement. We recommend handling upscaling on the client-side (e.g., using a web browser or native application).
   * - The upscale value must be used with the width, height, or dpr parameters to see any effect in the output image.
   *
   * Examples
   * - "width=2560&disable=upscale" => Attempt to resize the image to 2560px wide but do not exceed the natural width of the source image
   */
  disable?: InputMaybe<FeaturesEnableDisable>;
  /**
   * Serve correctly sized images for devices that expose a device pixel ratio.
   *
   * Device pixel ratio.
   *
   * The dpr parameter provides a means to multiply image dimensions in order to translate logical pixels (also 'CSS pixels') into physical pixels. The device pixel ratio is therefore the ratio between physical pixels and logical pixels.
   *
   * It is usually possible for client-side software to query the host system to determine the DPR of the display the user is viewing. In web browsers, this is available as the Window.devicePixelRatio variable in JavaScript. The iPhone 5 reports a device pixel ratio of 2, because the physical linear resolution is double the logical resolution.
   *
   * - Physical Resolution: 960 x 640
   * - Logical Resolution: 480 x 320
   *
   * Other devices report varying device pixel ratios, including non-integer ones. For example, the Nokia Lumia 1020 reports 1.6667, whereas the Samsung Galaxy S4 reports 3.
   *
   * Its value may be any number between 1 and 10.
   *
   * Examples
   * - width=200&dpr=2 => Resize the width to 400px (200 CSS pixels for screens with a device pixel ratio of 2)
   */
  dpr?: InputMaybe<Scalars['Int']['input']>;
  /**
   * Enable functionality that is disabled by default.
   *
   * Supported values:
   * - upscale => Allow images to be resized such that the output image's dimensions are larger than the source image.
   *
   * Notes
   * - Image upscaling is discouraged because it increases both the file size and delivery time of the image to the end user with no visual improvement. We recommend handling upscaling on the client-side (e.g., using a web browser or native application).
   * - The upscale value must be used with the width, height, or dpr parameters to see any effect in the output image.
   *
   * Examples:
   * - ?width=2560&enable=upscale => Resize the image to 2560px wide, even if the source image is smaller
   */
  enable?: InputMaybe<FeaturesEnableDisable>;
  /**
   * Set how the image will fit within the size bounds provided.
   *
   * The fit parameter controls how the image will be constrained within the provided size (width and height) values, in order to maintain the correct proportions.
   *
   * Allowed values:
   * - bounds => Resize the image to fit entirely within the specified region, making one dimension smaller if needed.
   * - cover	 => Resize the image to entirely cover the specified region, making one dimension larger if needed.
   * - crop	 => Resize and crop the image centrally to exactly fit the specified region.
   *
   * Notes
   * The fit parameter has no effect unless both width and height are specified.
   *
   * Examples
   * - width=150&height=150&fit=bounds	=> Resize the image to fit within the bounds of 150px in width by 150px in height
   * - width=150&height=150&fit=cover	=> Resize the image to entirely cover 150px in width by 150px in height
   * - width=150&height=150&fit=crop	  => Crop the image to 150px in width by 150px in height
   */
  fit?: InputMaybe<Fit>;
  /**
   * Specify the output format to convert the image to.
   *
   * The format parameter enables the source image to be converted (a.k.a., "transcoded") from one encoded format to another. This is useful when the source image has been saved in a sub-optimal file format that hinders performance.
   *
   * Notes
   * - The source image can be any of the following image formats: AVIF, JPEG, JPEGXL, PNG, GIF, WEBP.
   * - JPEG, progressive JPEG, WebP (Lossy), and PNG8 have a sliding quality scale that supports the quality parameter.
   * - GIF and PNG do not have a sliding quality scale. They do not support the quality parameter.
   * - A progressive JPEG pjpg is an image created using the JPEG suite of compression algorithms that will "fade in" successive waves of lines until the entire image has completely arrived. For this reason, progressive JPEG images typically appear to load quicker than the Baseline JPEG jpg format.
   * - WebP support is available in Google Chrome, Mozilla Firefox 65 and later, Safari 14 and later, Opera, and Android Browser.
   * - Lossless compression is currently supported for WebP and JPEGXL only.
   * - GIF to WebP conversion does not support animated GIFs.
   * - MP4 conversion is only available for animated GIF source images.
   * - AVIF and JPEGXL are premium features
   *
   * About the auto value
   *
   * The auto behavior aims to provide the optimal output format based on client capabilities as well as information related to the source image and transform characteristics. In that regard, we may adopt different heuristics to determine the output format for different source images or transforms.
   *
   * When using format=auto, the image format delivered is always compatible with the current browser regardless of the source image format. This means a JPEGXL source image can safely be used in a browser where JPEGXL is not supported, and the resulting image is always viewable. With other format parameters and with auto=webp and auto=avif, there are some cases where the original format may be used.
   *
   * - auto intelligently chooses the best format to deliver to the user prioritizing:
   *   - JPEGXL
   *   - AVIF
   *   - WebP
   *   - Source image format / JPEG
   *
   * - Images default to lossy unless the quality parameter is set to 100.
   * - If no quality parameter is set, the default JPEG quality specified in IO default settings is used.
   * - Allows the use of JPEGXL, AVIF, or WebP as the source image format in cases where that image format is not support in the viewing environment.
   * - Quality mapping uses an SSIM similarity score between formats.
   * - Takes precedence if combined with the auto parameter.
   */
  format?: InputMaybe<Format>;
  /** Extract the first frame from an animated image sequence. */
  frame?: InputMaybe<Scalars['Int']['input']>;
  /**
   * Resize the height of the image.
   *
   * The desired height of the output image.
   *
   * The height parameter enables dynamic height resizing based on pixels and percent values.
   *
   * The {value} can be either a positive integer or a positive number less than 1. If the value is an integer greater than or equal to 1, the value is interpreted as a pixel height. If the height parameter is less than 1, the value is interpreted as a percentage height.
   *
   * Unless a width parameter is present, the height value will cause the width of the image to be scaled in proportion to the requested height. If both width and height are omitted, the input image dimensions are used.
   *
   * value may be one of:
   * - Absolute height: An integer number of pixels.
   * - Relative height: A fraction between 0 and 0.99 (e.g., 0.5) or a percentage between 0 and 100 followed by the letter p (e.g., 50p). In either case, the value indicates the desired height relative to the image's natural height.
   *
   * Notes
   * - The maximum output dimensions are 8,192 x 8,192 pixels.
   * - Because we use a positive number between 0 and 0.99 to return a percentage-based scaled image, in order to return an image that is 100% or more, a p suffix can be appended to the value. For example, height=250p would return an image that is 250% the height of the original. We use p as a simple way to represent percentages.
   * - If the requested height is not a whole number of pixels (e.g., as a result of applying a percentage value for height), it is rounded to the nearest integer.
   *
   * Examples
   *
   * - height=200	          => Resize the height to 200px
   * - height=0.10	          => Resize the height to 10% of the input image
   * - height=150p	          => Resize the height to 150% of the input image
   * - height=200&width=0.50	=> Force resize to a height of 200px and a width that is 50% of the original
   * - height=200&dpr=2	    => Resize the height to 400px (200 CSS pixels for screens with a device pixel ratio of 2)
   * - height=150&width=150&fit=bounds	=> Fit image within a 150 x 150px rectangle
   */
  height?: InputMaybe<Scalars['Float']['input']>;
  /**
   * Specify the level constraints when converting to video.
   *
   * When converting animated GIFs to the MP4 format and when used in conjunction with the profile parameter, the level parameter specifies a set of constraints indicating a degree of required decoder performance for a profile.
   *
   * Notes
   * If either level or profile parameters are missing, profile=baseline&level=3.0 will be used as the default.
   *
   * Examples
   * - format=mp4&profile=main&level=5.2	=> Convert the source animated GIF to MP4 format and select the main profile with a level of 5.2
   */
  level?: InputMaybe<Level>;
  /**
   * Include or exclude metadata from the output image.
   *
   * By default, the image optimizer will remove all metadata embedded in an image. The metadata parameter allows this behavior to be configured.
   *
   * Notes
   * This parameter is only effective when the source and destination format are both jpeg.
   */
  metadata?: InputMaybe<ImageMetadata>;
  /**
   * Automatically apply optimal quality compression.
   *
   * The optimize parameter automatically applies optimal quality compression to produce an output image with as much visual fidelity as possible, while minimizing the file size.
   *
   * Notes
   * - Optimize is currently supported by the following output formats: JPEG, WebP, AVIF.
   * - If the quality parameter is also provided, quality overrides optimize because it is more precise.
   * - Because optimize attempts to produce an output image with as much visual quality as possible while minimizing the file size, the operations applied are subject to change.
   *
   * Examples
   * - optimize=medium	        => Output an image with medium optimization
   * - optimize=high&auto=webp	=> Output a highly optimized JPEG. For browsers that support WebP output a highly optimized WebP image.
   */
  optimize?: InputMaybe<Optimize>;
  /**
   * Change how the image will be orientated.
   *
   * The orient parameter controls the cardinal orientation of the image.
   *
   * Notes
   * - By default, if the source image contains orientation information stored within its metadata, that orientation will be applied to the image data and the orientation override removed from metadata.
   * - The numerical values are the same as EXIF rotation numbers.
   *
   * Examples
   * - orient=r => Orient the image right
   * - orient=2 => Flip vertically
   * - orient=3 => Flip vertically and horizontally
   */
  orient?: InputMaybe<Orient>;
  /**
   * Add pixels to the edge of an image.
   *
   * Notes
   * - By default padding applies a background color of white. See bg-color to change that.
   * - If the source image contains a transparent background and the output image also contains transparency, the padding will be made up of transparent pixels.
   * - When using pad and canvas at the same time, pad will be ignored.
   * - Values can be specified using CSS style shorthand values. (see CSS Margin or CSS Padding for more examples).
   * - CSS shorthand allows for all edges to be specified in one property.
   * - Any fractional pixel measurements will be rounded to the nearest whole pixel.
   *
   * Examples
   * - pad=25,50,75,100 => Pad top edge 25px, right edge 50px, bottom edge 75px and left edge 100px
   * - pad=25,50,75	   => Pad top edge 25px, right and left edge 50px, bottom edge 75px
   * - pad=25,50	       => Pad top and bottom edge 25px, right and left edge 50px
   * - pad=0.25	       => Pad all edges by 25%
   */
  pad?: InputMaybe<PadInput>;
  /**
   * Remove pixels from an image before any other transformations occur.
   *
   * Identical to crop except that precrop is performed before any other transformations.
   */
  precrop?: InputMaybe<CropInput>;
  /**
   * Specify the profile class of application when converting to video.
   *
   * When converting animated GIFs to MP4 format and when used in conjunction with the level parameter, the profile parameter controls which features the video encoder can use based on a target class of application for decoding the specific video bitstream.
   *
   * Notes
   * If either profile or level parameters are missing, profile=baseline&level=3.0 will be used as the default.
   *
   * Examples
   * - format=mp4&profile=high&level=5.0	=> Convert the source animated GIF to MP4 format and select the high profile with a level of 5.0
   */
  profile?: InputMaybe<Scalars['String']['input']>;
  /**
   * Optimize the image to the given compression level for lossy file formatted images.
   *
   * Output image quality for lossy file formats.
   *
   * The quality parameter enables control over the compression level for lossy file-formatted images.
   *
   * Its value may be any integer between 1 and 100, where 1 is a lower quality image and a smaller file and 100 is the highest quality image and larger file. If desired, a second quality level can be specified for use when auto=webp is enabled and a WebP output format has been selected.
   *
   * Notes
   * - The quality parameter can be applied to the following output formats: avif, jpg, pjpg, png8, webp and webply.
   * - If no quality parameter is present for jpg, pjpg, or webp, the output image will be returned at the default value set in the Image Optimizer web interface.
   * - If auto=webp is enabled by the URL or the service settings, the second value will be used as the quality value if the requestor sends the accept: image/webp request header.
   * - When only specifying quality, if the output image file size is larger than the input image, the original image will be delivered.
   * - This does not include a way of specifying auto AVIF quality.
   *
   * Examples
   * - quality=85	                        => Output an image with quality set to 85.
   * - format=pjpg&auto=webp&quality=85,75	=> Convert the image format to a progressive jpg with image quality set to 85. For browsers that support WebP set the image quality to 75.
   */
  quality?: InputMaybe<Scalars['Float']['input']>;
  /**
   * Specify the resize filter used when resizing images.
   *
   * The resizeFilter parameter enables control over the resizing filter used to generate a new image with a higher or lower number of pixels.
   *
   * Notes
   * - When making an image smaller, use bicubic, which has a natural sharpening effect.
   * - When making an image larger, use bilinear, which has a natural smoothing effect.
   * - When resizing pixel art, use nearest, which has a natural pixelation effect.
   * - When quality is the main concern, use lanczos, which typically renders the best results.
   *
   * Examples
   * - resize-filter=bilinear	Deliver an image that has been resized using the bilinear filter.
   */
  resizeFilter?: InputMaybe<ResizeFilter>;
  /**
   * Set the saturation of the output image.
   *
   * Saturation of the output image.
   *
   * The saturation parameter increases or decreases the intensity of the colors in an image.
   *
   * Its value may be any number between -100 and 100.
   *
   * Notes
   * - The default value is 0. This leaves the image unchanged.
   * - Valid values range from -100 to 100
   * - A value of -100 will generate a grayscale image.
   *
   * Examples
   * - saturation=25	  => Increase saturation by 25
   * - saturation=-100	=> Decrease saturation by 100 (rendering the image grayscale)
   */
  saturation?: InputMaybe<Scalars['Int']['input']>;
  /**
   * Set the sharpness of the output image.
   *
   * Sharpness of the output image.
   *
   * The sharpen parameter increases the definition of the edges of objects in an image.
   *
   * Notes
   * The sharpen parameter is an implementation of an unsharp mask.
   *
   * Examples
   * - sharpen=a5,r2,t1 => Increase the sharpness of the image by amount:5, radius:2, and threshold:1
   */
  sharpen?: InputMaybe<SharpenInput>;
  /**
   * Remove pixels from the edge of an image.
   *
   * The trim parameter removes pixels from the edge of an image by pixel or percentage value. This can be useful for removing whitespace and borders that appear on a source image.
   *
   * Notes
   * - Values can be specified using CSS style shorthand values. (See CSS Margin or CSS Padding for more examples).
   * - CSS shorthand allows for all edges to be specified in one property.
   * - Any fractional pixel measurements will be rounded to the nearest whole pixel.
   *
   * Examples
   * - trim=25,50,75,100	=> Trim top edge 25px, right edge 50px, bottom edge 75px and left edge 100px
   * - trim=25,50,75	    => Trim top edge 25px, right and left edge 50px, bottom edge 75px
   * - trim=25,50	      => Trim top and bottom edge 25px, right and left edge 50px
   * - trim=0.25	        => Trim all edges by 25%
   */
  trim?: InputMaybe<TrimInput>;
  /**
   * Resize the width of the image.
   *
   *     The desired width of the output image.
   *
   *     The width parameter enables dynamic width resizing based on pixels and percent values.
   *
   *     The {value} can be either a positive integer or a number less than 1. If the value is an integer greater than or equal to 1, the value is interpreted as a pixel width. If the width parameter is less than 1, the value is interpreted as a percentage width.
   *
   *     Unless a height parameter is present, the width value will cause the height of the image to be scaled in proportion to the requested width. If both width and height are omitted, the input image dimensions are used.
   *
   *     value may be one of:
   *     - Absolute width: An integer between 1 and 8192.
   *     - Relative width: A fraction between 0 and 0.99 (e.g. ,0.5) or a percentage between 0 and 100 followed by the letter p (e.g., 50p). In either case the value indicates the desired width relative to the image's natural width.
   *
   *     Notes
   *     - The maximum output dimensions are 8,192 x 8,192 pixels.
   *     - If the image is animated, the maximum output dimensions apply to the sum of the area of all the frames of the image. For example, a 1024x1024px GIF could have 64 frames before it would exceed the size limit.
   *     - Because we use a positive number less than 1 to return a percentage-based scaled image, in order to return an image that is 100% or more, a p suffix can be appended to the value. For example, width=250p would return an image that is 250% the width of the original. We use p as a simple way to represent percent.
   *     - If the requested width is a fraction of a pixel (e.g., as a result of applying a percentage value for width), it is rounded to the nearest integer.
   *
   *     Examples
   *     - width=200	                      => Resize the width to 200px
   *     - width=0.10	                    => Resize the width to 10% of the input image
   *     - width=150p	                    => Resize the width to 150% of the input image
   *     - width=200&height=0.50	          => Force resize to a width of 200px and a height that is 50% of the original.
   *     - width=150&height=150&fit=bounds	=> Fit image within a 150 x 150px rectangle
   *     - width=200&dpr=2	                => Resize the width to 400px (200 CSS pixels for screens with a device pixel ratio of 2)
   */
  width?: InputMaybe<Scalars['Int']['input']>;
};

export type TransformationsInput = {
  /** Image Transformation input... */
  transformations: Array<ImageTransformationInput>;
};

/**
 * Remove pixels from the edge of an image.
 *
 * The trim parameter removes pixels from the edge of an image by pixel or percentage value. This can be useful for removing whitespace and borders that appear on a source image.
 *
 * Notes
 * - Values can be specified using CSS style shorthand values. (See CSS Margin or CSS Padding for more examples).
 * - CSS shorthand allows for all edges to be specified in one property.
 * - Any fractional pixel measurements will be rounded to the nearest whole pixel.
 */
export type TrimInput = {
  /** Bottom - Pixels or Percentage(decimal 0.0 - 1) - Default=0 */
  bottom?: InputMaybe<Scalars['Int']['input']>;
  /** Left - Pixels or Percentage(decimal 0.0 - 1) - Default=0 */
  left?: InputMaybe<Scalars['Int']['input']>;
  /** Right - Pixels or Percentage(decimal 0.0 - 1) - Default=0 */
  right?: InputMaybe<Scalars['Int']['input']>;
  /** Top - Pixels or Percentage(decimal 0.0 - 1) - Default=0 */
  top?: InputMaybe<Scalars['Int']['input']>;
};

export type UpdateBundleInput = {
  /** The discount condition for this bundle. Discount can be applied for Extra Products (EP) or All Products (ALL). */
  condition?: InputMaybe<BundleCondition>;
  /** The description for this bundle. */
  description?: InputMaybe<Scalars['String']['input']>;
  /** The discount percentage this bundle. */
  discount?: InputMaybe<Scalars['Float']['input']>;
  /** The name for this bundle. */
  name?: InputMaybe<Scalars['String']['input']>;
  /** The bundle prices. */
  price?: InputMaybe<Scalars['Float']['input']>;
  /** The bundle tax zone. */
  taxZone?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateCategoryInput = {
  defaultLanguage?: InputMaybe<Scalars['String']['input']>;
  /**
   * The long description for this category,
   *     this field can contain html formatting,
   *     this field can be translated is case of multilingual catalog.
   */
  description?: InputMaybe<Array<LocalizedStringInput>>;
  /**
   * Localized category name as it is available in
   *     the supported language defaults to the category's default language.
   */
  name?: InputMaybe<Array<LocalizedStringInput>>;
  /** The category's parent Category when available */
  parent?: InputMaybe<Scalars['Int']['input']>;
  /**
   * The long description for this category,
   *     this field can contain html formatting,
   *     this field can be translated is case of multilingual catalog.
   */
  shortDescription?: InputMaybe<Array<LocalizedStringInput>>;
};

export type UpdateCompanyInput = {
  cocNumber?: InputMaybe<Scalars['String']['input']>;
  /** The id of the the company. */
  companyId: Scalars['Int']['input'];
  email?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  notes?: InputMaybe<Scalars['String']['input']>;
  /** The id of the usergroup the company should be created in. */
  parentId?: InputMaybe<Scalars['Int']['input']>;
  phone?: InputMaybe<Scalars['String']['input']>;
  taxNumber?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateContactInput = {
  /** Contacts's custom attribute values. This field is used to set value on attributes for the selected contact. Attributes that are used here (selected by name) must already be defined in Propeller. */
  attributes?: InputMaybe<Array<UserManagementAttributeValueInput>>;
  contactId: Scalars['Int']['input'];
  dateOfBirth?: InputMaybe<Scalars['String']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  gender?: InputMaybe<Gender>;
  homepage?: InputMaybe<Scalars['String']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  mailingList?: InputMaybe<YesNo>;
  middleName?: InputMaybe<Scalars['String']['input']>;
  mobile?: InputMaybe<Scalars['String']['input']>;
  /** The id of the company the contact should be created in. */
  parentId?: InputMaybe<Scalars['Int']['input']>;
  phone?: InputMaybe<Scalars['String']['input']>;
  /** The primary language of the contact. */
  primaryLanguage?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateCustomerInput = {
  /** Customer's custom attribute values. This field is used to set value on attributes for the selected customer. Attributes that are used here (selected by name) must already be defined in Propeller. */
  attributes?: InputMaybe<Array<UserManagementAttributeValueInput>>;
  customerId: Scalars['Int']['input'];
  dateOfBirth?: InputMaybe<Scalars['String']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  gender?: InputMaybe<Gender>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  mailingList?: InputMaybe<YesNo>;
  middleName?: InputMaybe<Scalars['String']['input']>;
  mobile?: InputMaybe<Scalars['String']['input']>;
  /** The id of the usergroup the customer should be created in. */
  parentId?: InputMaybe<Scalars['Int']['input']>;
  phone?: InputMaybe<Scalars['String']['input']>;
  /** The primary language of the customer. */
  primaryLanguage?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateInventoryInput = {
  /** Inventory cost price */
  costPrice?: InputMaybe<Scalars['Float']['input']>;
  /** Inventory estimated next delivery date in ISO8601 format */
  nextDeliveryDate?: InputMaybe<Scalars['String']['input']>;
  /** Inventory note */
  notes?: InputMaybe<Scalars['String']['input']>;
  /** Inventory quantity */
  quantity?: InputMaybe<Scalars['Int']['input']>;
};

export type UpdateMediaAttachmentInput = {
  /** Media item alt description that briefly explains its contents. */
  alt?: InputMaybe<Array<MediaLocalizedStringInput>>;
  /** Media item short description. */
  description?: InputMaybe<Array<MediaLocalizedStringInput>>;
  /** Media item display priority [Lower value has higher priority] -  default: 1000 */
  priority?: InputMaybe<Scalars['Int']['input']>;
  /** Media item tags. */
  tags?: InputMaybe<Array<MediaLocalizedStringArrayInput>>;
};

export type UpdateMediaDocumentInput = {
  /** Media item alt description that briefly explains its contents. */
  alt?: InputMaybe<Array<MediaLocalizedStringInput>>;
  /** Unique category identifier that the media item relates to */
  categoryId?: InputMaybe<Scalars['ID']['input']>;
  /** Unique cluster identifier that the media item relates to */
  clusterId?: InputMaybe<Scalars['ID']['input']>;
  /** Media item short description. */
  description?: InputMaybe<Array<MediaLocalizedStringInput>>;
  /** MediaDocument unique identified */
  mediaId: Scalars['String']['input'];
  /** Media item display priority [Lower value has higher priority] -  default: 1000 */
  priority?: InputMaybe<Scalars['Int']['input']>;
  /** Unique product identifier that the media item relates to */
  productId?: InputMaybe<Scalars['ID']['input']>;
  /** Unique machine identifier that the media item relates to */
  sparePartsMachineId?: InputMaybe<Scalars['ID']['input']>;
  /** Media item tags. */
  tags?: InputMaybe<Array<MediaLocalizedStringArrayInput>>;
  /** Upload File input reference */
  uploadDocument?: InputMaybe<UploadFileInput>;
};

export type UpdateMediaImageInput = {
  /** Media item alt description that briefly explains its contents. */
  alt?: InputMaybe<Array<MediaLocalizedStringInput>>;
  /** Unique category identifier that the media item relates to */
  categoryId?: InputMaybe<Scalars['ID']['input']>;
  /** Unique cluster identifier that the media item relates to */
  clusterId?: InputMaybe<Scalars['ID']['input']>;
  /** Media item short description. */
  description?: InputMaybe<Array<MediaLocalizedStringInput>>;
  /** MediaImage unique identified */
  mediaId: Scalars['String']['input'];
  /** Media item display priority [Lower value has higher priority] -  default: 1000 */
  priority?: InputMaybe<Scalars['Int']['input']>;
  /** Unique product identifier that the media item relates to */
  productId?: InputMaybe<Scalars['ID']['input']>;
  /** Unique machine identifier that the media item relates to */
  sparePartsMachineId?: InputMaybe<Scalars['ID']['input']>;
  /** Media item tags. */
  tags?: InputMaybe<Array<MediaLocalizedStringArrayInput>>;
  /** Upload File input reference */
  uploadImage?: InputMaybe<UploadFileInput>;
};

export type UpdateMediaVideoInput = {
  /** Media item alt description that briefly explains its contents. */
  alt?: InputMaybe<Array<MediaLocalizedStringInput>>;
  /** Unique category identifier that the media item relates to */
  categoryId?: InputMaybe<Scalars['ID']['input']>;
  /** Unique cluster identifier that the media item relates to */
  clusterId?: InputMaybe<Scalars['ID']['input']>;
  /** Media item short description. */
  description?: InputMaybe<Array<MediaLocalizedStringInput>>;
  /** MediaVideo unique identified */
  mediaId: Scalars['String']['input'];
  /** Media item display priority [Lower value has higher priority] -  default: 1000 */
  priority?: InputMaybe<Scalars['Int']['input']>;
  /** Unique product identifier that the media item relates to */
  productId?: InputMaybe<Scalars['ID']['input']>;
  /** Unique machine identifier that the media item relates to */
  sparePartsMachineId?: InputMaybe<Scalars['ID']['input']>;
  /** Media item tags. */
  tags?: InputMaybe<Array<MediaLocalizedStringArrayInput>>;
  /** Upload Video input reference */
  video?: InputMaybe<UploadVideoInput>;
};

export type UpdateOrderStatusInput = {
  /** The order status set to add this order status to */
  addOrderStatusToSet?: InputMaybe<OrderStatusSetSearchByInput>;
  /** The order status code value */
  code?: InputMaybe<Scalars['String']['input']>;
  /** The order status description */
  description?: InputMaybe<Scalars['String']['input']>;
  /** Marks if the order can be archived */
  isArchivable?: InputMaybe<Scalars['Boolean']['input']>;
  /** Marks if the order is confirmable */
  isConfirmable?: InputMaybe<Scalars['Boolean']['input']>;
  /** Marks if the order status is default */
  isDefault?: InputMaybe<Scalars['Boolean']['input']>;
  /** Marks if the order is deletable */
  isDeletable?: InputMaybe<Scalars['Boolean']['input']>;
  /** Marks if the order is editable */
  isEditable?: InputMaybe<Scalars['Boolean']['input']>;
  /** Marks if the order is exportable */
  isExportable?: InputMaybe<Scalars['Boolean']['input']>;
  /** Marks if the order is public */
  isPublic?: InputMaybe<Scalars['Boolean']['input']>;
  /** The order status name */
  name?: InputMaybe<Scalars['String']['input']>;
  /** The IDs of the next possible order statuses */
  nextStatusesIds?: InputMaybe<Array<Scalars['Int']['input']>>;
  /** The order status for order type. */
  orderType?: InputMaybe<OrderStatusOrderType>;
  /** The order status priority */
  priority?: InputMaybe<Scalars['Int']['input']>;
};

export type UpdateOrderStatusSetInput = {
  /** The order status set description */
  description?: InputMaybe<Scalars['String']['input']>;
  /** The order status set name */
  name?: InputMaybe<Scalars['String']['input']>;
  /** The ids of the order statuses that will be added to the set */
  orderStatusIds?: InputMaybe<Array<Scalars['Int']['input']>>;
};

export type UpdatePaymentInput = {
  /** Add a transaction related to the specified payment */
  addTransaction?: InputMaybe<CreateTransactionInput>;
  /** Payment amount [denomination in cents] */
  amount?: InputMaybe<Scalars['Int']['input']>;
  /** Guest User ID */
  anonymousId?: InputMaybe<Scalars['Int']['input']>;
  /** Payment currency - ISO 4217 currency code => https://www.xe.com/iso4217.php */
  currency?: InputMaybe<Scalars['String']['input']>;
  /** Payment method used by the PSP */
  method?: InputMaybe<Scalars['String']['input']>;
  /** Unique paymentId reference for the Payment - provided by the PSP */
  paymentId?: InputMaybe<Scalars['String']['input']>;
  /** Payment status */
  status?: InputMaybe<PaymentStatuses>;
  /** Logged in User ID */
  userId?: InputMaybe<Scalars['Int']['input']>;
};

export type UpdateProductInput = {
  /** The id of the category the product should be created in. */
  categoryId?: InputMaybe<Scalars['Int']['input']>;
  /** Product cost price */
  costPrice?: InputMaybe<Scalars['Float']['input']>;
  /** The descriptions of the product and it's translations in different languages. */
  description?: InputMaybe<Array<LocalizedStringInput>>;
  /** The EAN code of the product. */
  eanCode?: InputMaybe<Scalars['String']['input']>;
  /** The manufacturer of the product. */
  manufacturer?: InputMaybe<Scalars['String']['input']>;
  /** The manugacturer (OEM) code of the product. */
  manufacturerCode?: InputMaybe<Scalars['String']['input']>;
  /** Product minimum quantity. Defaults to 1. */
  minimumQuantity?: InputMaybe<Scalars['Int']['input']>;
  /** The names of the product and it's translations in different languages. */
  name?: InputMaybe<Array<LocalizedStringInput>>;
  /** Notes of the product and it's translations in different languages. */
  notes?: InputMaybe<Array<LocalizedStringInput>>;
  /** Original price of the product */
  originalPrice?: InputMaybe<Scalars['Float']['input']>;
  /** The default package type for this product. */
  package?: InputMaybe<Scalars['String']['input']>;
  /** The package type descriptions of the product and it's translations in different languages. */
  packageDescription?: InputMaybe<Array<LocalizedStringInput>>;
  /** The id of the product. */
  productId: Scalars['Int']['input'];
  /** The short descriptions of the product and it's translations in different languages. */
  shortDescription?: InputMaybe<Array<LocalizedStringInput>>;
  /**
   * The short name product.
   *       Commonly used for connections with third parties that
   *       have a size limitation on the product name field.
   */
  shortName?: InputMaybe<Scalars['String']['input']>;
  /** The sku of the product. */
  sku?: InputMaybe<Scalars['String']['input']>;
  /** The status of the product. */
  status?: InputMaybe<ProductStatus>;
  /** Product suggested price */
  suggestedPrice?: InputMaybe<Scalars['Float']['input']>;
  /** The supplier of the product. */
  supplier?: InputMaybe<Scalars['String']['input']>;
  /** The supplier code of the product. */
  supplierCode?: InputMaybe<Scalars['String']['input']>;
  /** The tax code of the product. Defaults to H. */
  taxCode?: InputMaybe<TaxCode>;
  /** Product unit. Defaults to 1. */
  unit?: InputMaybe<Scalars['Int']['input']>;
};

export type UpdateTaxInput = {
  /** Tax code */
  code?: InputMaybe<Scalars['String']['input']>;
  /** Tax export code */
  exportCode?: InputMaybe<Scalars['String']['input']>;
  /** Tax percentage expressed as ratio */
  ratio?: InputMaybe<Scalars['Float']['input']>;
  /** Shop identifier for the tax to apply to... If not provided the default will be inferred from the channel */
  shopId?: InputMaybe<Scalars['Int']['input']>;
  /** Tax zone */
  zone?: Scalars['String']['input'];
};

export type UpdateUserInput = {
  cocNumber?: InputMaybe<Scalars['String']['input']>;
  company?: InputMaybe<Scalars['String']['input']>;
  dateOfBirth?: InputMaybe<Scalars['String']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  gender?: InputMaybe<Gender>;
  homepage?: InputMaybe<Scalars['String']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  mailingList?: InputMaybe<YesNo>;
  middleName?: InputMaybe<Scalars['String']['input']>;
  mobile?: InputMaybe<Scalars['String']['input']>;
  /** The id of the usergroup the user should be created in. */
  parentId?: InputMaybe<Scalars['Int']['input']>;
  phone?: InputMaybe<Scalars['String']['input']>;
  /** The primary language of the user. */
  primaryLanguage?: InputMaybe<Scalars['String']['input']>;
  taxNumber?: InputMaybe<Scalars['String']['input']>;
  userId: Scalars['Int']['input'];
};

export type UpdateUsergroupInput = {
  name?: InputMaybe<Scalars['String']['input']>;
  /** The id of the usergroup the company should be created in. */
  parentId?: InputMaybe<Scalars['Int']['input']>;
  /** The id of the the usergroup. */
  usergroupId: Scalars['Int']['input'];
};

export type UpdateWarehouseAddressInput = {
  /** Address city */
  city?: InputMaybe<Scalars['String']['input']>;
  /** Address code */
  code?: InputMaybe<Scalars['String']['input']>;
  /** User company the address applies to */
  company?: InputMaybe<Scalars['String']['input']>;
  /** Address country */
  country?: InputMaybe<Scalars['String']['input']>;
  /** Address email [valid email format required] */
  email?: InputMaybe<Scalars['String']['input']>;
  /** User first name the address applies to */
  firstName?: InputMaybe<Scalars['String']['input']>;
  /** User gender the address applies to */
  gender?: InputMaybe<Gender>;
  /** Address primary identifier */
  id: Scalars['Int']['input'];
  /** User last name the address applies to */
  lastName?: InputMaybe<Scalars['String']['input']>;
  /** User middle name the address applies to */
  middleName?: InputMaybe<Scalars['String']['input']>;
  /** Address mobile number [min: 6, max: 30] */
  mobile?: InputMaybe<Scalars['String']['input']>;
  /** Address delivery notes */
  notes?: InputMaybe<Scalars['String']['input']>;
  /** User street number */
  number?: InputMaybe<Scalars['String']['input']>;
  /** User street number extension */
  numberExtension?: InputMaybe<Scalars['String']['input']>;
  /** Address fixed phone number [min: 6, max: 30] */
  phone?: InputMaybe<Scalars['String']['input']>;
  /** Address postal code */
  postalCode?: InputMaybe<Scalars['String']['input']>;
  /** Address region */
  region?: InputMaybe<Scalars['String']['input']>;
  /** User street address */
  street?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateWarehouseInput = {
  /**
   * Warehouse business hours per week day
   *
   *     Example:
   *         [{
   *           dayOfWeek: 0
   *           openingTime: "08:00"
   *           closingTime: "20:00"
   *           lunchBeakStartTime: "12:00"
   *           lunchBeakEndTime: "12:30"
   *         },
   *         {
   *           dayOfWeek: 1
   *           openingTime: "08:00"
   *           closingTime: "20:00"
   *           lunchBeakStartTime: "12:00"
   *           lunchBeakEndTime: "12:30"
   *         },
   *         {
   *           dayOfWeek: 2
   *           openingTime: "08:00"
   *           closingTime: "20:00"
   *           lunchBeakStartTime: "12:00"
   *           lunchBeakEndTime: "12:30"
   *         },
   *         {
   *           dayOfWeek: 3
   *           openingTime: "08:00"
   *           closingTime: "20:00"
   *           lunchBeakStartTime: "12:00"
   *           lunchBeakEndTime: "12:30"
   *         },
   *         {
   *           dayOfWeek: 4
   *           openingTime: "08:00"
   *           closingTime: "20:00"
   *           lunchBeakStartTime: "12:00"
   *           lunchBeakEndTime: "12:30"
   *         },
   *         {
   *           dayOfWeek: 5
   *           openingTime: "08:00"
   *           closingTime: "20:00"
   *           lunchBeakStartTime: "12:00"
   *           lunchBeakEndTime: "12:30"
   *         },
   *         {
   *           dayOfWeek: 6
   *           openingTime: "08:00"
   *           closingTime: "20:00"
   *           lunchBeakStartTime: "12:00"
   *           lunchBeakEndTime: "12:30"
   *         }]
   */
  businessHours?: InputMaybe<Array<BusinessHoursInput>>;
  /** Warehouse description */
  description?: InputMaybe<Scalars['String']['input']>;
  /** Warehouse isActive status */
  isActive?: InputMaybe<Scalars['Boolean']['input']>;
  /** Warehouse isPickupLocation status */
  isPickupLocation?: InputMaybe<Scalars['Boolean']['input']>;
  /** Warehouse isStore status */
  isStore?: InputMaybe<Scalars['Boolean']['input']>;
  /** Warehouse name */
  name?: InputMaybe<Scalars['String']['input']>;
  /** Warehouse notes */
  notes?: InputMaybe<Scalars['String']['input']>;
  /** Optional warehouse shopId! Default value will be internally inferred from the appropriate channel */
  shopId?: InputMaybe<Scalars['Int']['input']>;
};

export type UploadFileInput = {
  /** Base64 file upload */
  base64File?: InputMaybe<MediaBase64FileInput>;
  /** Cache control options */
  cacheControl?: InputMaybe<CacheControlInput>;
  /** File reference from filesystem [GraphQL Upload form-data specification](https://github.com/jaydenseric/graphql-multipart-request-spec) */
  file?: InputMaybe<StandardFileUploadInput>;
  /** Language specific media item [Default value 'EN'] */
  language?: InputMaybe<Scalars['String']['input']>;
  /** Upload type */
  uploadType: UploadType;
  /** The public url to the file. */
  urlFile?: InputMaybe<UrlFileUploadInput>;
};

/** Upload type */
export enum UploadType {
  /** Upload by providing base64 string representation of a file */
  BASE64 = 'BASE64',
  /** Upload by referencing a file */
  FILE = 'FILE',
  /** Upload by providing public url to the file */
  URL = 'URL'
}

export type UploadVideoInput = {
  /** Language specific video [Default value 'EN'] */
  language?: InputMaybe<Scalars['String']['input']>;
  /** Video URI */
  uri: Scalars['String']['input'];
};

export type UpsertSparePartsMachineInput = {
  /** Machine description */
  description?: InputMaybe<Array<LocalizedStringInput>>;
  externalReference: ExternalReferenceInput;
  /** Level 1 SubMachines input */
  machines?: InputMaybe<Array<CreateSparePartsMachineInput>>;
  /** Spare PartsMachine name */
  name: Array<LocalizedStringInput>;
  /** Spare parts for this machine */
  parts?: InputMaybe<Array<CreateSparePartInput>>;
};

export type UrlFileUploadInput = {
  /** Name to use for storing the file */
  fileName: Scalars['String']['input'];
  /** The public url to the file. */
  url: Scalars['String']['input'];
};

export type UserAddressCreateInput = {
  /** Address city */
  city: Scalars['String']['input'];
  /** Address code */
  code?: InputMaybe<Scalars['String']['input']>;
  /** User company the address applies to */
  company?: InputMaybe<Scalars['String']['input']>;
  /** Address country */
  country: Scalars['String']['input'];
  /** Address email [valid email format required] */
  email?: InputMaybe<Scalars['String']['input']>;
  /** User first name the address applies to */
  firstName?: InputMaybe<Scalars['String']['input']>;
  /** User gender the address applies to */
  gender?: InputMaybe<Gender>;
  /** ICP address field defines whether tax should be applied to an order if the address is selected as a delivery address. Used when shipping b2b order abroad */
  icp?: InputMaybe<YesNo>;
  /** Indicates whether the address is default for its type */
  isDefault?: InputMaybe<YesNo>;
  /** User last name the address applies to */
  lastName?: InputMaybe<Scalars['String']['input']>;
  /** User middle name the address applies to */
  middleName?: InputMaybe<Scalars['String']['input']>;
  /** Address mobile number [min: 6, max: 30] */
  mobile?: InputMaybe<Scalars['String']['input']>;
  /** Address delivery notes */
  notes?: InputMaybe<Scalars['String']['input']>;
  /** User street number */
  number?: InputMaybe<Scalars['String']['input']>;
  /** User street number extension */
  numberExtension?: InputMaybe<Scalars['String']['input']>;
  /** Address fixed phone number [min: 6, max: 30] */
  phone?: InputMaybe<Scalars['String']['input']>;
  /** Address postal code */
  postalCode: Scalars['String']['input'];
  /** Address region */
  region?: InputMaybe<Scalars['String']['input']>;
  /** User street address */
  street: Scalars['String']['input'];
  /** Address type [one of 'home', 'delivery' or 'invoice'] */
  type: AddressType;
  /** Address user primary identifier */
  userId: Scalars['Int']['input'];
};

export type UserAddressDeleteInput = {
  /** Address primary identifier */
  id: Scalars['Int']['input'];
  /** User primary identifier */
  userId: Scalars['Int']['input'];
};

export type UserAddressUpdateInput = {
  /** Address city */
  city?: InputMaybe<Scalars['String']['input']>;
  /** Address code */
  code?: InputMaybe<Scalars['String']['input']>;
  /** User company the address applies to */
  company?: InputMaybe<Scalars['String']['input']>;
  /** Address country */
  country?: InputMaybe<Scalars['String']['input']>;
  /** Address email [valid email format required] */
  email?: InputMaybe<Scalars['String']['input']>;
  /** User first name the address applies to */
  firstName?: InputMaybe<Scalars['String']['input']>;
  /** User gender the address applies to */
  gender?: InputMaybe<Gender>;
  /** ICP address field defines whether tax should be applied to an order if the address is selected as a delivery address. Used when shipping b2b order abroad */
  icp?: InputMaybe<YesNo>;
  /** Address primary identifier */
  id: Scalars['Int']['input'];
  /** Indicates whether the address is default for its type */
  isDefault?: InputMaybe<YesNo>;
  /** User last name the address applies to */
  lastName?: InputMaybe<Scalars['String']['input']>;
  /** User middle name the address applies to */
  middleName?: InputMaybe<Scalars['String']['input']>;
  /** Address mobile number [min: 6, max: 30] */
  mobile?: InputMaybe<Scalars['String']['input']>;
  /** Address delivery notes */
  notes?: InputMaybe<Scalars['String']['input']>;
  /** User street number */
  number?: InputMaybe<Scalars['String']['input']>;
  /** User street number extension */
  numberExtension?: InputMaybe<Scalars['String']['input']>;
  /** Address fixed phone number [min: 6, max: 30] */
  phone?: InputMaybe<Scalars['String']['input']>;
  /** Address postal code */
  postalCode?: InputMaybe<Scalars['String']['input']>;
  /** Address region */
  region?: InputMaybe<Scalars['String']['input']>;
  /** User street address */
  street?: InputMaybe<Scalars['String']['input']>;
  /** Address user primary identifier */
  userId: Scalars['Int']['input'];
};

export type UserManagementAttributeSearchInput = {
  /** Decimal value for the attribute, required when attribute type equals decimal. */
  decimalValue?: InputMaybe<Scalars['Float']['input']>;
  /** Enumeration value for the attribute, required when type equals enum or enumlist. Values in this field can be multivalue. */
  enumValue?: InputMaybe<Array<Scalars['String']['input']>>;
  /** Integer value for the attribute, required when attribute type equals numeric or object. */
  intValue?: InputMaybe<Scalars['Int']['input']>;
  /** Attribute name for which the value is applied for. */
  name: Scalars['String']['input'];
  /** Textual value(s) for the attribute, required when attribute type equals text, list or color. This value is suitable for Localization. */
  textValue?: InputMaybe<LocalizedStringArrayInput>;
  /** Attribute type. */
  type: AttributeType;
};

export type UserManagementAttributeValueInput = {
  /** Decimal value for the attribute, required when attribute type equals decimal. */
  decimalValue?: InputMaybe<Scalars['Float']['input']>;
  /** Enumeration value for the attribute, required when type equals enum or enumlist. Values in this field can be multivalue. */
  enumValue?: InputMaybe<Array<Scalars['String']['input']>>;
  /** Integer value for the attribute, required when attribute type equals numeric or object. */
  intValue?: InputMaybe<Scalars['Int']['input']>;
  /** Attribute name for which the value is applied for. */
  name: Scalars['String']['input'];
  /** Textual value(s) for the attribute, required when attribute type equals text, list or color. This value is suitable for Localization. */
  textValue?: InputMaybe<LocalizedStringArrayInput>;
  /** Attribute type. */
  type: AttributeType;
};

export type UsergroupSearchArguments = {
  /** Usergroup's name */
  name?: InputMaybe<Scalars['String']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  /** List of usergroup sort filters */
  sort?: InputMaybe<Array<UsergroupSortInput>>;
};

export type UsergroupSortInput = {
  /** Available sortable fields */
  field: UsergroupSortableFields;
  /** Sort ordering ['desc' or 'asc'] */
  order?: SortOrder;
};

/** Usergroup sortable fields */
export enum UsergroupSortableFields {
  name = 'name'
}

export type ValuesetCreateInput = {
  /** Valueset descriptions per language */
  descriptions?: InputMaybe<Array<LocalizedStringInput>>;
  /** Valueset unique name */
  name: Scalars['String']['input'];
};

export type ValuesetItemCreateInput = {
  /** Valueset items descriptions per language */
  descriptions?: InputMaybe<Array<LocalizedStringInput>>;
  /** Valueset item extra value */
  extra?: InputMaybe<Scalars['String']['input']>;
  /** Valueset item unique value */
  value: Scalars['String']['input'];
  /** Valueset id */
  valuesetId: Scalars['Int']['input'];
};

export type ValuesetItemSearchInput = {
  /** Search by extra values */
  extras?: InputMaybe<Array<Scalars['String']['input']>>;
  /** Search by valueset item ids */
  ids?: InputMaybe<Array<Scalars['Int']['input']>>;
  /** Pagination offset number. [Default to `12`] */
  offset?: InputMaybe<Scalars['Int']['input']>;
  /** Pagination page number. [Default to `1`] */
  page?: InputMaybe<Scalars['Int']['input']>;
  /** Inputs to sort by */
  sortInputs?: InputMaybe<Array<ValuesetItemSortInput>>;
  /** Search by values */
  values?: InputMaybe<Array<Scalars['String']['input']>>;
  /** Search by valueset ids */
  valuesetIds?: InputMaybe<Array<Scalars['Int']['input']>>;
};

export enum ValuesetItemSortField {
  EXTRA = 'EXTRA',
  ID = 'ID',
  VALUE = 'VALUE'
}

export type ValuesetItemSortInput = {
  /** Field to sort by */
  field: ValuesetItemSortField;
  /** Order option to sort by. [Default to `ASC`] */
  order?: InputMaybe<ValuesetSortOrder>;
};

export type ValuesetItemUpdateInput = {
  /** Valueset items descriptions per language */
  descriptions?: InputMaybe<Array<LocalizedStringInput>>;
  /** Valueset item extra value */
  extra?: InputMaybe<Scalars['String']['input']>;
  /** Valueset item unique value */
  value?: InputMaybe<Scalars['String']['input']>;
};

export type ValuesetSearchInput = {
  /** Search by date created */
  createdAt?: InputMaybe<DateSearchInput>;
  /** Search by ids */
  ids?: InputMaybe<Array<Scalars['Int']['input']>>;
  /** Search by date changed */
  lastModifiedAt?: InputMaybe<DateSearchInput>;
  /** Search by names */
  names?: InputMaybe<Array<Scalars['String']['input']>>;
  /** Pagination offset number. [Default to `12`] */
  offset?: InputMaybe<Scalars['Int']['input']>;
  /** Pagination page number. [Default to `1`] */
  page?: InputMaybe<Scalars['Int']['input']>;
  /** Inputs to sort by */
  sortInputs?: InputMaybe<Array<ValuesetSortInput>>;
  /** Valueset type. One of: [SYSTEM, CUSTOM] */
  type?: InputMaybe<ValuesetType>;
};

export enum ValuesetSortField {
  CREATED_AT = 'CREATED_AT',
  ID = 'ID',
  LAST_MODIFIED_AT = 'LAST_MODIFIED_AT',
  NAME = 'NAME'
}

export type ValuesetSortInput = {
  /** Field to sort by */
  field: ValuesetSortField;
  /** Order option to sort by. [Default to `ASC`] */
  order?: InputMaybe<ValuesetSortOrder>;
};

export enum ValuesetSortOrder {
  ASC = 'ASC',
  DESC = 'DESC'
}

export enum ValuesetType {
  CUSTOM = 'CUSTOM',
  SYSTEM = 'SYSTEM'
}

export type ValuesetUpdateInput = {
  /** Valueset descriptions per language */
  descriptions?: InputMaybe<Array<LocalizedStringInput>>;
  /** Valueset unique name */
  name?: InputMaybe<Scalars['String']['input']>;
};

export type VerifyTokenInput = {
  token: Scalars['String']['input'];
};

export type WarehousesSearchInput = {
  /** Search warehouses by ids */
  ids?: InputMaybe<Array<Scalars['Int']['input']>>;
  /** Warehouse isActive status */
  isActive?: InputMaybe<Scalars['Boolean']['input']>;
  /** Warehouse isPickupLocation status */
  isPickupLocation?: InputMaybe<Scalars['Boolean']['input']>;
  /** Warehouse isStore status */
  isStore?: InputMaybe<Scalars['Boolean']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
};

/** Boolean in Yes/No format */
export enum YesNo {
  N = 'N',
  Y = 'Y'
}

export type ClusterListItemFragment_Cluster_products_Product = (
  { __typename: 'Product' }
  & ProductListItemFragment
);

export type ClusterListItemFragment_Cluster_defaultProduct_Product = (
  { __typename: 'Product' }
  & ProductListItemFragment
);

export type ClusterListItemFragment = { __typename: 'Cluster', clusterId: number, sku: string, products?: Array<ClusterListItemFragment_Cluster_products_Product> | null, defaultProduct?: ClusterListItemFragment_Cluster_defaultProduct_Product | null };

export type Custom_CartFragment_Cart_total_CartTotal = { __typename: 'CartTotal', totalGross: number, totalNet: number };

export type Custom_CartFragment_Cart_postageData_CartPostageData = { __typename: 'CartPostageData', shippingMethod: string, requestDate: string, postage: number, postageNet: number };

export type Custom_CartFragment_Cart_taxLevels_CartTaxLevel = { __typename: 'CartTaxLevel', taxCode: Taxcode, price: number };

export type Custom_CartFragment_Cart_invoiceAddress_CartAddress = (
  { __typename: 'CartAddress' }
  & Custom_CartAddressFragment
);

export type Custom_CartFragment_Cart_deliveryAddress_CartAddress = (
  { __typename: 'CartAddress' }
  & Custom_CartAddressFragment
);

export type Custom_CartFragment_Cart_items_CartMainItem_product_Product = (
  { __typename: 'Product' }
  & ProductListItemFragment
);

export type Custom_CartFragment_Cart_items_CartMainItem_childItems_CartBaseItem_product_Product = { __typename: 'Product', id: number, sku: string, package: string };

export type Custom_CartFragment_Cart_items_CartMainItem_childItems_CartBaseItem = { __typename: 'CartBaseItem', id: number, productId?: number | null, taxCode: Taxcode, quantity: number, price: number, priceNet: number, totalPrice: number, totalPriceNet: number, product: Custom_CartFragment_Cart_items_CartMainItem_childItems_CartBaseItem_product_Product };

export type Custom_CartFragment_Cart_items_CartMainItem = { __typename: 'CartMainItem', id: number, productId?: number | null, taxCode: Taxcode, quantity: number, price: number, priceNet: number, totalPrice: number, totalPriceNet: number, totalSum: number, totalSumNet: number, sum: number, sumNet: number, notes: string, product?: Custom_CartFragment_Cart_items_CartMainItem_product_Product | null, childItems?: Array<Custom_CartFragment_Cart_items_CartMainItem_childItems_CartBaseItem> | null };

export type Custom_CartFragment = { __typename: 'Cart', cartId: string, userId: number, channelId?: number | null, notes?: string | null, extra3?: string | null, reference?: string | null, orderStatus?: string | null, actionCode?: string | null, total?: Custom_CartFragment_Cart_total_CartTotal | null, postageData?: Custom_CartFragment_Cart_postageData_CartPostageData | null, taxLevels?: Array<Custom_CartFragment_Cart_taxLevels_CartTaxLevel> | null, invoiceAddress: Custom_CartFragment_Cart_invoiceAddress_CartAddress, deliveryAddress: Custom_CartFragment_Cart_deliveryAddress_CartAddress, items?: Array<Custom_CartFragment_Cart_items_CartMainItem> | null };

export type Custom_CartAddressFragment = { __typename: 'CartAddress', code?: string | null, lastName: string, firstName: string, street: string, number?: string | null, numberExtension?: string | null, postalCode: string, city: string, country: string, phone?: string | null, email?: string | null, company?: string | null, notes?: string | null, icp?: YesNo | null };

export type OrderItemFragment_OrderItem_product_Product_cluster_Cluster = { __typename: 'Cluster', sku: string };

export type OrderItemFragment_OrderItem_product_Product_country_AttributeValueResponse_items_AttributeValue_textValue_LocalizedStringArray = { __typename: 'LocalizedStringArray', values?: Array<string> | null };

export type OrderItemFragment_OrderItem_product_Product_country_AttributeValueResponse_items_AttributeValue = { __typename: 'AttributeValue', attributeId: number, textValue?: Array<OrderItemFragment_OrderItem_product_Product_country_AttributeValueResponse_items_AttributeValue_textValue_LocalizedStringArray> | null };

export type OrderItemFragment_OrderItem_product_Product_country_AttributeValueResponse = { __typename: 'AttributeValueResponse', items: Array<OrderItemFragment_OrderItem_product_Product_country_AttributeValueResponse_items_AttributeValue> };

export type OrderItemFragment_OrderItem_product_Product_region_AttributeValueResponse_items_AttributeValue_textValue_LocalizedStringArray = { __typename: 'LocalizedStringArray', values?: Array<string> | null };

export type OrderItemFragment_OrderItem_product_Product_region_AttributeValueResponse_items_AttributeValue = { __typename: 'AttributeValue', attributeId: number, textValue?: Array<OrderItemFragment_OrderItem_product_Product_region_AttributeValueResponse_items_AttributeValue_textValue_LocalizedStringArray> | null };

export type OrderItemFragment_OrderItem_product_Product_region_AttributeValueResponse = { __typename: 'AttributeValueResponse', items: Array<OrderItemFragment_OrderItem_product_Product_region_AttributeValueResponse_items_AttributeValue> };

export type OrderItemFragment_OrderItem_product_Product_volume_AttributeValueResponse_items_AttributeValue = { __typename: 'AttributeValue', attributeId: number, decimalValue?: Array<number> | null };

export type OrderItemFragment_OrderItem_product_Product_volume_AttributeValueResponse = { __typename: 'AttributeValueResponse', items: Array<OrderItemFragment_OrderItem_product_Product_volume_AttributeValueResponse_items_AttributeValue> };

export type OrderItemFragment_OrderItem_product_Product_year_AttributeValueResponse_items_AttributeValue_textValue_LocalizedStringArray = { __typename: 'LocalizedStringArray', values?: Array<string> | null };

export type OrderItemFragment_OrderItem_product_Product_year_AttributeValueResponse_items_AttributeValue = { __typename: 'AttributeValue', attributeId: number, textValue?: Array<OrderItemFragment_OrderItem_product_Product_year_AttributeValueResponse_items_AttributeValue_textValue_LocalizedStringArray> | null };

export type OrderItemFragment_OrderItem_product_Product_year_AttributeValueResponse = { __typename: 'AttributeValueResponse', items: Array<OrderItemFragment_OrderItem_product_Product_year_AttributeValueResponse_items_AttributeValue> };

export type OrderItemFragment_OrderItem_product_Product = { __typename: 'Product', package: string, cluster?: OrderItemFragment_OrderItem_product_Product_cluster_Cluster | null, country: OrderItemFragment_OrderItem_product_Product_country_AttributeValueResponse, region: OrderItemFragment_OrderItem_product_Product_region_AttributeValueResponse, volume: OrderItemFragment_OrderItem_product_Product_volume_AttributeValueResponse, year: OrderItemFragment_OrderItem_product_Product_year_AttributeValueResponse };

export type OrderItemFragment = { __typename: 'OrderItem', id: number, class: OrderItemClass, parentOrderItemId?: number | null, productId?: number | null, sku: string, name?: string | null, quantity: number, package?: string | null, price: number, priceTotal: number, priceNet?: number | null, priceTotalNet?: number | null, tax?: number | null, product?: OrderItemFragment_OrderItem_product_Product | null };

export type ProductAttributesFragment_Product_attributeValues_AttributeValueResponse_items_AttributeValue_textValue_LocalizedStringArray = { __typename: 'LocalizedStringArray', language: string, values?: Array<string> | null };

export type ProductAttributesFragment_Product_attributeValues_AttributeValueResponse_items_AttributeValue_attributeDescription_AttributeDescription_description_LocalizedString = { __typename: 'LocalizedString', language: string, value?: string | null };

export type ProductAttributesFragment_Product_attributeValues_AttributeValueResponse_items_AttributeValue_attributeDescription_AttributeDescription = { __typename: 'AttributeDescription', name: string, id: number, searchId: string, type: AttributeType, typeParam: string, group: string, description: Array<ProductAttributesFragment_Product_attributeValues_AttributeValueResponse_items_AttributeValue_attributeDescription_AttributeDescription_description_LocalizedString> };

export type ProductAttributesFragment_Product_attributeValues_AttributeValueResponse_items_AttributeValue = { __typename: 'AttributeValue', attributeId: number, enumValue?: Array<string> | null, intValue?: Array<number> | null, decimalValue?: Array<number> | null, dateValue?: string | null, textValue?: Array<ProductAttributesFragment_Product_attributeValues_AttributeValueResponse_items_AttributeValue_textValue_LocalizedStringArray> | null, attributeDescription: ProductAttributesFragment_Product_attributeValues_AttributeValueResponse_items_AttributeValue_attributeDescription_AttributeDescription };

export type ProductAttributesFragment_Product_attributeValues_AttributeValueResponse = { __typename: 'AttributeValueResponse', itemsFound: number, items: Array<ProductAttributesFragment_Product_attributeValues_AttributeValueResponse_items_AttributeValue> };

export type ProductAttributesFragment = { __typename: 'Product', attributeValues: ProductAttributesFragment_Product_attributeValues_AttributeValueResponse };

export type ProductDetailFragment_Product_shortDescription_LocalizedString = { __typename: 'LocalizedString', language: string, value?: string | null };

export type ProductDetailFragment = (
  { __typename: 'Product', shortDescription: Array<ProductDetailFragment_Product_shortDescription_LocalizedString> }
  & ProductListItemFragment
  & ProductAttributesFragment
  & ProductDocumentsFragment
);

export type ProductDocumentsFragment_Product_media_ProductMedia_documents_PaginatedMediaDocumentResponse_items_MediaDocument_documents_LocalizedDocument = { __typename: 'LocalizedDocument', language: string, originalUrl: string, mimeType: string };

export type ProductDocumentsFragment_Product_media_ProductMedia_documents_PaginatedMediaDocumentResponse_items_MediaDocument = { __typename: 'MediaDocument', documents?: Array<ProductDocumentsFragment_Product_media_ProductMedia_documents_PaginatedMediaDocumentResponse_items_MediaDocument_documents_LocalizedDocument> | null };

export type ProductDocumentsFragment_Product_media_ProductMedia_documents_PaginatedMediaDocumentResponse = { __typename: 'PaginatedMediaDocumentResponse', items: Array<ProductDocumentsFragment_Product_media_ProductMedia_documents_PaginatedMediaDocumentResponse_items_MediaDocument> };

export type ProductDocumentsFragment_Product_media_ProductMedia = { __typename: 'ProductMedia', documents?: ProductDocumentsFragment_Product_media_ProductMedia_documents_PaginatedMediaDocumentResponse | null };

export type ProductDocumentsFragment = { __typename: 'Product', media?: ProductDocumentsFragment_Product_media_ProductMedia | null };

export type ProductImagesFragment_Product_media_ProductMedia_images_PaginatedMediaImageResponse_items_MediaImage_alt_LocalizedString = { __typename: 'LocalizedString', language: string, value?: string | null };

export type ProductImagesFragment_Product_media_ProductMedia_images_PaginatedMediaImageResponse_items_MediaImage_images_LocalizedImage = { __typename: 'LocalizedImage', language: string, originalUrl: string, mimeType: string };

export type ProductImagesFragment_Product_media_ProductMedia_images_PaginatedMediaImageResponse_items_MediaImage = { __typename: 'MediaImage', alt: Array<ProductImagesFragment_Product_media_ProductMedia_images_PaginatedMediaImageResponse_items_MediaImage_alt_LocalizedString>, images?: Array<ProductImagesFragment_Product_media_ProductMedia_images_PaginatedMediaImageResponse_items_MediaImage_images_LocalizedImage> | null };

export type ProductImagesFragment_Product_media_ProductMedia_images_PaginatedMediaImageResponse = { __typename: 'PaginatedMediaImageResponse', items: Array<ProductImagesFragment_Product_media_ProductMedia_images_PaginatedMediaImageResponse_items_MediaImage> };

export type ProductImagesFragment_Product_media_ProductMedia = { __typename: 'ProductMedia', images?: ProductImagesFragment_Product_media_ProductMedia_images_PaginatedMediaImageResponse | null };

export type ProductImagesFragment = { __typename: 'Product', media?: ProductImagesFragment_Product_media_ProductMedia | null };

export type ProductListItemFragment_Product_cluster_Cluster_name_LocalizedString = { __typename: 'LocalizedString', language: string, value?: string | null };

export type ProductListItemFragment_Product_cluster_Cluster_slug_LocalizedString = { __typename: 'LocalizedString', language: string, value?: string | null };

export type ProductListItemFragment_Product_cluster_Cluster_analyticsName_LocalizedString = { __typename: 'LocalizedString', value?: string | null };

export type ProductListItemFragment_Product_cluster_Cluster = { __typename: 'Cluster', clusterId: number, sku: string, name: Array<ProductListItemFragment_Product_cluster_Cluster_name_LocalizedString>, slug: Array<ProductListItemFragment_Product_cluster_Cluster_slug_LocalizedString>, analyticsName: Array<ProductListItemFragment_Product_cluster_Cluster_analyticsName_LocalizedString> };

export type ProductListItemFragment_Product_slug_LocalizedString = { __typename: 'LocalizedString', language: string, value?: string | null };

export type ProductListItemFragment_Product_name_LocalizedString = { __typename: 'LocalizedString', language: string, value?: string | null };

export type ProductListItemFragment_Product_description_LocalizedString = { __typename: 'LocalizedString', language: string, value?: string | null };

export type ProductListItemFragment_Product_price_ProductPrice = { __typename: 'ProductPrice', net?: number | null, gross: number };

export type ProductListItemFragment_Product_color_AttributeValueResponse_items_AttributeValue_textValue_LocalizedStringArray = { __typename: 'LocalizedStringArray', values?: Array<string> | null };

export type ProductListItemFragment_Product_color_AttributeValueResponse_items_AttributeValue = { __typename: 'AttributeValue', attributeId: number, textValue?: Array<ProductListItemFragment_Product_color_AttributeValueResponse_items_AttributeValue_textValue_LocalizedStringArray> | null };

export type ProductListItemFragment_Product_color_AttributeValueResponse = { __typename: 'AttributeValueResponse', items: Array<ProductListItemFragment_Product_color_AttributeValueResponse_items_AttributeValue> };

export type ProductListItemFragment_Product_country_AttributeValueResponse_items_AttributeValue_textValue_LocalizedStringArray = { __typename: 'LocalizedStringArray', values?: Array<string> | null };

export type ProductListItemFragment_Product_country_AttributeValueResponse_items_AttributeValue = { __typename: 'AttributeValue', attributeId: number, textValue?: Array<ProductListItemFragment_Product_country_AttributeValueResponse_items_AttributeValue_textValue_LocalizedStringArray> | null };

export type ProductListItemFragment_Product_country_AttributeValueResponse = { __typename: 'AttributeValueResponse', items: Array<ProductListItemFragment_Product_country_AttributeValueResponse_items_AttributeValue> };

export type ProductListItemFragment_Product_region_AttributeValueResponse_items_AttributeValue_textValue_LocalizedStringArray = { __typename: 'LocalizedStringArray', values?: Array<string> | null };

export type ProductListItemFragment_Product_region_AttributeValueResponse_items_AttributeValue = { __typename: 'AttributeValue', attributeId: number, textValue?: Array<ProductListItemFragment_Product_region_AttributeValueResponse_items_AttributeValue_textValue_LocalizedStringArray> | null };

export type ProductListItemFragment_Product_region_AttributeValueResponse = { __typename: 'AttributeValueResponse', items: Array<ProductListItemFragment_Product_region_AttributeValueResponse_items_AttributeValue> };

export type ProductListItemFragment_Product_volume_AttributeValueResponse_items_AttributeValue = { __typename: 'AttributeValue', attributeId: number, decimalValue?: Array<number> | null };

export type ProductListItemFragment_Product_volume_AttributeValueResponse = { __typename: 'AttributeValueResponse', items: Array<ProductListItemFragment_Product_volume_AttributeValueResponse_items_AttributeValue> };

export type ProductListItemFragment_Product_year_AttributeValueResponse_items_AttributeValue_textValue_LocalizedStringArray = { __typename: 'LocalizedStringArray', values?: Array<string> | null };

export type ProductListItemFragment_Product_year_AttributeValueResponse_items_AttributeValue = { __typename: 'AttributeValue', attributeId: number, textValue?: Array<ProductListItemFragment_Product_year_AttributeValueResponse_items_AttributeValue_textValue_LocalizedStringArray> | null };

export type ProductListItemFragment_Product_year_AttributeValueResponse = { __typename: 'AttributeValueResponse', items: Array<ProductListItemFragment_Product_year_AttributeValueResponse_items_AttributeValue> };

export type ProductListItemFragment_Product_isNew_AttributeValueResponse_items_AttributeValue_textValue_LocalizedStringArray = { __typename: 'LocalizedStringArray', values?: Array<string> | null };

export type ProductListItemFragment_Product_isNew_AttributeValueResponse_items_AttributeValue = { __typename: 'AttributeValue', attributeId: number, textValue?: Array<ProductListItemFragment_Product_isNew_AttributeValueResponse_items_AttributeValue_textValue_LocalizedStringArray> | null };

export type ProductListItemFragment_Product_isNew_AttributeValueResponse = { __typename: 'AttributeValueResponse', items: Array<ProductListItemFragment_Product_isNew_AttributeValueResponse_items_AttributeValue> };

export type ProductListItemFragment_Product_webStatusCode_AttributeValueResponse_items_AttributeValue_textValue_LocalizedStringArray = { __typename: 'LocalizedStringArray', values?: Array<string> | null };

export type ProductListItemFragment_Product_webStatusCode_AttributeValueResponse_items_AttributeValue = { __typename: 'AttributeValue', attributeId: number, textValue?: Array<ProductListItemFragment_Product_webStatusCode_AttributeValueResponse_items_AttributeValue_textValue_LocalizedStringArray> | null };

export type ProductListItemFragment_Product_webStatusCode_AttributeValueResponse = { __typename: 'AttributeValueResponse', items: Array<ProductListItemFragment_Product_webStatusCode_AttributeValueResponse_items_AttributeValue> };

export type ProductListItemFragment_Product_vatPercentage_AttributeValueResponse_items_AttributeValue = { __typename: 'AttributeValue', attributeId: number, decimalValue?: Array<number> | null };

export type ProductListItemFragment_Product_vatPercentage_AttributeValueResponse = { __typename: 'AttributeValueResponse', items: Array<ProductListItemFragment_Product_vatPercentage_AttributeValueResponse_items_AttributeValue> };

export type ProductListItemFragment_Product_analyticsBrandAttribute_AttributeValueResponse_items_AttributeValue_textValue_LocalizedStringArray = { __typename: 'LocalizedStringArray', values?: Array<string> | null };

export type ProductListItemFragment_Product_analyticsBrandAttribute_AttributeValueResponse_items_AttributeValue = { __typename: 'AttributeValue', attributeId: number, textValue?: Array<ProductListItemFragment_Product_analyticsBrandAttribute_AttributeValueResponse_items_AttributeValue_textValue_LocalizedStringArray> | null };

export type ProductListItemFragment_Product_analyticsBrandAttribute_AttributeValueResponse = { __typename: 'AttributeValueResponse', items: Array<ProductListItemFragment_Product_analyticsBrandAttribute_AttributeValueResponse_items_AttributeValue> };

export type ProductListItemFragment_Product_analyticsCategoryPath_Category_analyticsName_LocalizedString = { __typename: 'LocalizedString', value?: string | null };

export type ProductListItemFragment_Product_analyticsCategoryPath_Category = { __typename: 'Category', categoryId: number, analyticsName: Array<ProductListItemFragment_Product_analyticsCategoryPath_Category_analyticsName_LocalizedString> };

export type ProductListItemFragment = (
  { __typename: 'Product', productId: number, categoryId: number, sku: string, eanCode: string, status: ProductStatus, package: string, minimumOrderQuantity: number, incrementalOrderQuantity: number, cluster?: ProductListItemFragment_Product_cluster_Cluster | null, slug: Array<ProductListItemFragment_Product_slug_LocalizedString>, name: Array<ProductListItemFragment_Product_name_LocalizedString>, description: Array<ProductListItemFragment_Product_description_LocalizedString>, price?: ProductListItemFragment_Product_price_ProductPrice | null, color: ProductListItemFragment_Product_color_AttributeValueResponse, country: ProductListItemFragment_Product_country_AttributeValueResponse, region: ProductListItemFragment_Product_region_AttributeValueResponse, volume: ProductListItemFragment_Product_volume_AttributeValueResponse, year: ProductListItemFragment_Product_year_AttributeValueResponse, isNew: ProductListItemFragment_Product_isNew_AttributeValueResponse, webStatusCode: ProductListItemFragment_Product_webStatusCode_AttributeValueResponse, vatPercentage: ProductListItemFragment_Product_vatPercentage_AttributeValueResponse, analyticsBrandAttribute: ProductListItemFragment_Product_analyticsBrandAttribute_AttributeValueResponse, analyticsCategoryPath: Array<ProductListItemFragment_Product_analyticsCategoryPath_Category> }
  & ProductImagesFragment
);

export type SitemapClusterListItemFragment_Cluster_defaultProduct_Product = (
  { __typename: 'Product' }
  & SitemapProductListItemFragment
);

export type SitemapClusterListItemFragment = { __typename: 'Cluster', defaultProduct?: SitemapClusterListItemFragment_Cluster_defaultProduct_Product | null };

export type SitemapProductListItemFragment_Product_cluster_Cluster_slug_LocalizedString = { __typename: 'LocalizedString', language: string, value?: string | null };

export type SitemapProductListItemFragment_Product_cluster_Cluster = { __typename: 'Cluster', clusterId: number, slug: Array<SitemapProductListItemFragment_Product_cluster_Cluster_slug_LocalizedString> };

export type SitemapProductListItemFragment = { __typename: 'Product', dateCreated: string, dateChanged: string, cluster?: SitemapProductListItemFragment_Product_cluster_Cluster | null };

export type LoginMutation_login_Login_session_GCIPUser = { __typename: 'GCIPUser', accessToken: string, refreshToken: string, expirationTime?: string | null };

export type LoginMutation_login_Login = { __typename: 'Login', session?: LoginMutation_login_Login_session_GCIPUser | null };

export type LoginMutation_Mutation = { login: LoginMutation_login_Login };


export type LoginMutationVariables = Exact<{
  email: Scalars['String']['input'];
  password: Scalars['String']['input'];
}>;


export type LoginMutation = LoginMutation_Mutation;

export type RefreshTokenMutation_exchangeRefreshToken_RefreshTokenResponse = { __typename: 'RefreshTokenResponse', access_token: string, refresh_token: string, expires_in: number };

export type RefreshTokenMutation_Mutation = { exchangeRefreshToken: RefreshTokenMutation_exchangeRefreshToken_RefreshTokenResponse };


export type RefreshTokenMutationVariables = Exact<{
  refreshToken: Scalars['String']['input'];
}>;


export type RefreshTokenMutation = RefreshTokenMutation_Mutation;

export type CompanyAddressCreateMutation_companyAddressCreate_Address = { __typename: 'Address', id: number };

export type CompanyAddressCreateMutation_Mutation = { companyAddressCreate: CompanyAddressCreateMutation_companyAddressCreate_Address };


export type CompanyAddressCreateMutationVariables = Exact<{
  input: CompanyAddressCreateInput;
}>;


export type CompanyAddressCreateMutation = CompanyAddressCreateMutation_Mutation;

export type CompanyAddressDeleteMutation_Mutation = { companyAddressDelete: boolean };


export type CompanyAddressDeleteMutationVariables = Exact<{
  input: CompanyAddressDeleteInput;
}>;


export type CompanyAddressDeleteMutation = CompanyAddressDeleteMutation_Mutation;

export type CompanyAddressListQuery_addressesByCompanyId_Address = { __typename: 'Address', id: number, code?: string | null, name?: string | null, firstName?: string | null, lastName?: string | null, company?: string | null, street: string, number?: string | null, numberExtension?: string | null, postalCode: string, city: string, country: string, phone?: string | null, email?: string | null, isDefault: YesNo, type: AddressType };

export type CompanyAddressListQuery_Query = { addressesByCompanyId: Array<CompanyAddressListQuery_addressesByCompanyId_Address> };


export type CompanyAddressListQueryVariables = Exact<{
  companyId: Scalars['Float']['input'];
  type?: InputMaybe<AddressType>;
}>;


export type CompanyAddressListQuery = CompanyAddressListQuery_Query;

export type CompanyAddressUpdateMutation_companyAddressUpdate_Address = { __typename: 'Address', id: number };

export type CompanyAddressUpdateMutation_Mutation = { companyAddressUpdate: CompanyAddressUpdateMutation_companyAddressUpdate_Address };


export type CompanyAddressUpdateMutationVariables = Exact<{
  input: CompanyAddressUpdateInput;
}>;


export type CompanyAddressUpdateMutation = CompanyAddressUpdateMutation_Mutation;

export type CustomerAddressCreateMutation_customerAddressCreate_Address = { __typename: 'Address', id: number };

export type CustomerAddressCreateMutation_Mutation = { customerAddressCreate: CustomerAddressCreateMutation_customerAddressCreate_Address };


export type CustomerAddressCreateMutationVariables = Exact<{
  input: CustomerAddressCreateInput;
}>;


export type CustomerAddressCreateMutation = CustomerAddressCreateMutation_Mutation;

export type CustomerAddressDeleteMutation_Mutation = { customerAddressDelete: boolean };


export type CustomerAddressDeleteMutationVariables = Exact<{
  input: CustomerAddressDeleteInput;
}>;


export type CustomerAddressDeleteMutation = CustomerAddressDeleteMutation_Mutation;

export type CustomerAddressListQuery_addressesByCustomerId_Address = { __typename: 'Address', id: number, code?: string | null, name?: string | null, firstName?: string | null, lastName?: string | null, company?: string | null, street: string, number?: string | null, numberExtension?: string | null, postalCode: string, city: string, country: string, phone?: string | null, email?: string | null, isDefault: YesNo, type: AddressType };

export type CustomerAddressListQuery_Query = { addressesByCustomerId: Array<CustomerAddressListQuery_addressesByCustomerId_Address> };


export type CustomerAddressListQueryVariables = Exact<{
  customerId: Scalars['Float']['input'];
  type?: InputMaybe<AddressType>;
}>;


export type CustomerAddressListQuery = CustomerAddressListQuery_Query;

export type CustomerAddressUpdateMutation_customerAddressUpdate_Address = { __typename: 'Address', id: number };

export type CustomerAddressUpdateMutation_Mutation = { customerAddressUpdate: CustomerAddressUpdateMutation_customerAddressUpdate_Address };


export type CustomerAddressUpdateMutationVariables = Exact<{
  input: CustomerAddressUpdateInput;
}>;


export type CustomerAddressUpdateMutation = CustomerAddressUpdateMutation_Mutation;

export type ContactRegisterMutation_contactRegister_RegisterContactResponse_contact_Contact = { __typename: 'Contact', contactId: number };

export type ContactRegisterMutation_contactRegister_RegisterContactResponse_contact_Customer = { __typename: 'Customer' };

export type ContactRegisterMutation_contactRegister_RegisterContactResponse_contact_User = { __typename: 'User' };

export type ContactRegisterMutation_contactRegister_RegisterContactResponse_contact = ContactRegisterMutation_contactRegister_RegisterContactResponse_contact_Contact | ContactRegisterMutation_contactRegister_RegisterContactResponse_contact_Customer | ContactRegisterMutation_contactRegister_RegisterContactResponse_contact_User;

export type ContactRegisterMutation_contactRegister_RegisterContactResponse = { __typename: 'RegisterContactResponse', contact: ContactRegisterMutation_contactRegister_RegisterContactResponse_contact };

export type ContactRegisterMutation_Mutation = { contactRegister: ContactRegisterMutation_contactRegister_RegisterContactResponse };


export type ContactRegisterMutationVariables = Exact<{
  input: RegisterContactInput;
}>;


export type ContactRegisterMutation = ContactRegisterMutation_Mutation;

export type CompanyCreateMutation_companyCreate_Company = { __typename: 'Company', companyId: number };

export type CompanyCreateMutation_Mutation = { companyCreate: CompanyCreateMutation_companyCreate_Company };


export type CompanyCreateMutationVariables = Exact<{
  input: CreateCompanyInput;
}>;


export type CompanyCreateMutation = CompanyCreateMutation_Mutation;

export type CustomerRegisterMutation_customerRegister_RegisterCustomerResponse_customer_Contact = { __typename: 'Contact', email: string };

export type CustomerRegisterMutation_customerRegister_RegisterCustomerResponse_customer_Customer = { __typename: 'Customer', customerId: number, email: string };

export type CustomerRegisterMutation_customerRegister_RegisterCustomerResponse_customer_User = { __typename: 'User', email: string };

export type CustomerRegisterMutation_customerRegister_RegisterCustomerResponse_customer = CustomerRegisterMutation_customerRegister_RegisterCustomerResponse_customer_Contact | CustomerRegisterMutation_customerRegister_RegisterCustomerResponse_customer_Customer | CustomerRegisterMutation_customerRegister_RegisterCustomerResponse_customer_User;

export type CustomerRegisterMutation_customerRegister_RegisterCustomerResponse = { __typename: 'RegisterCustomerResponse', customer: CustomerRegisterMutation_customerRegister_RegisterCustomerResponse_customer };

export type CustomerRegisterMutation_Mutation = { customerRegister: CustomerRegisterMutation_customerRegister_RegisterCustomerResponse };


export type CustomerRegisterMutationVariables = Exact<{
  input: RegisterCustomerInput;
}>;


export type CustomerRegisterMutation = CustomerRegisterMutation_Mutation;

export type CustomerDeleteMutation_Mutation = { customerDelete: boolean };


export type CustomerDeleteMutationVariables = Exact<{
  customerId: Scalars['Int']['input'];
}>;


export type CustomerDeleteMutation = CustomerDeleteMutation_Mutation;

export type GetAuthByEmailQuery_authentication_GCIPUser = { __typename: 'GCIPUser', email?: string | null };

export type GetAuthByEmailQuery_Query = { authentication: GetAuthByEmailQuery_authentication_GCIPUser };


export type GetAuthByEmailQueryVariables = Exact<{
  email: Scalars['String']['input'];
}>;


export type GetAuthByEmailQuery = GetAuthByEmailQuery_Query;

export type ContactQuery_contact_Contact_company_Company_addresses_Address = { __typename: 'Address', id: number, country: string };

export type ContactQuery_contact_Contact_company_Company = { __typename: 'Company', name: string, taxNumber?: string | null, addresses: Array<ContactQuery_contact_Contact_company_Company_addresses_Address> };

export type ContactQuery_contact_Contact_attributeValues_AttributeValueResponse_items_AttributeValue_attributeDescription_AttributeDescription = { __typename: 'AttributeDescription', name: string };

export type ContactQuery_contact_Contact_attributeValues_AttributeValueResponse_items_AttributeValue_textValue_LocalizedStringArray = { __typename: 'LocalizedStringArray', language: string, values?: Array<string> | null };

export type ContactQuery_contact_Contact_attributeValues_AttributeValueResponse_items_AttributeValue = { __typename: 'AttributeValue', attributeId: number, intValue?: Array<number> | null, attributeDescription: ContactQuery_contact_Contact_attributeValues_AttributeValueResponse_items_AttributeValue_attributeDescription_AttributeDescription, textValue?: Array<ContactQuery_contact_Contact_attributeValues_AttributeValueResponse_items_AttributeValue_textValue_LocalizedStringArray> | null };

export type ContactQuery_contact_Contact_attributeValues_AttributeValueResponse = { __typename: 'AttributeValueResponse', items: Array<ContactQuery_contact_Contact_attributeValues_AttributeValueResponse_items_AttributeValue> };

export type ContactQuery_contact_Contact = { __typename: 'Contact', contactId: number, firstName: string, lastName: string, email: string, primaryLanguage?: string | null, parentCompanyId: number, company?: ContactQuery_contact_Contact_company_Company | null, attributeValues: ContactQuery_contact_Contact_attributeValues_AttributeValueResponse };

export type ContactQuery_Query = { contact: ContactQuery_contact_Contact };


export type ContactQueryVariables = Exact<{
  contactId: Scalars['Float']['input'];
}>;


export type ContactQuery = ContactQuery_Query;

export type CustomerQuery_customer_Customer_addresses_Address = { __typename: 'Address', id: number, country: string };

export type CustomerQuery_customer_Customer_attributeValues_AttributeValueResponse_items_AttributeValue_attributeDescription_AttributeDescription = { __typename: 'AttributeDescription', name: string };

export type CustomerQuery_customer_Customer_attributeValues_AttributeValueResponse_items_AttributeValue_textValue_LocalizedStringArray = { __typename: 'LocalizedStringArray', language: string, values?: Array<string> | null };

export type CustomerQuery_customer_Customer_attributeValues_AttributeValueResponse_items_AttributeValue = { __typename: 'AttributeValue', attributeId: number, intValue?: Array<number> | null, attributeDescription: CustomerQuery_customer_Customer_attributeValues_AttributeValueResponse_items_AttributeValue_attributeDescription_AttributeDescription, textValue?: Array<CustomerQuery_customer_Customer_attributeValues_AttributeValueResponse_items_AttributeValue_textValue_LocalizedStringArray> | null };

export type CustomerQuery_customer_Customer_attributeValues_AttributeValueResponse = { __typename: 'AttributeValueResponse', items: Array<CustomerQuery_customer_Customer_attributeValues_AttributeValueResponse_items_AttributeValue> };

export type CustomerQuery_customer_Customer = { __typename: 'Customer', customerId: number, firstName: string, lastName: string, email: string, primaryLanguage?: string | null, addresses: Array<CustomerQuery_customer_Customer_addresses_Address>, attributeValues: CustomerQuery_customer_Customer_attributeValues_AttributeValueResponse };

export type CustomerQuery_Query = { customer: CustomerQuery_customer_Customer };


export type CustomerQueryVariables = Exact<{
  customerId: Scalars['Float']['input'];
}>;


export type CustomerQuery = CustomerQuery_Query;

export type PasswordResetLinkMutation_Mutation = { passwordResetLink: string };


export type PasswordResetLinkMutationVariables = Exact<{
  email: Scalars['String']['input'];
  lang: Scalars['String']['input'];
  redirectUrl: Scalars['String']['input'];
}>;


export type PasswordResetLinkMutation = PasswordResetLinkMutation_Mutation;

export type ContactUpdateMutation_contactUpdate_Contact = { __typename: 'Contact', id: number };

export type ContactUpdateMutation_Mutation = { contactUpdate: ContactUpdateMutation_contactUpdate_Contact };


export type ContactUpdateMutationVariables = Exact<{
  input: UpdateContactInput;
}>;


export type ContactUpdateMutation = ContactUpdateMutation_Mutation;

export type CustomerUpdateMutation_customerUpdate_Customer = { __typename: 'Customer', id: number };

export type CustomerUpdateMutation_Mutation = { customerUpdate: CustomerUpdateMutation_customerUpdate_Customer };


export type CustomerUpdateMutationVariables = Exact<{
  input: UpdateCustomerInput;
}>;


export type CustomerUpdateMutation = CustomerUpdateMutation_Mutation;

export type AddCartItemMutation_cartAddItem_CartResponse_cart_Cart = (
  { __typename: 'Cart' }
  & Custom_CartFragment
);

export type AddCartItemMutation_cartAddItem_CartResponse = { __typename: 'CartResponse', cart: AddCartItemMutation_cartAddItem_CartResponse_cart_Cart };

export type AddCartItemMutation_Mutation = { cartAddItem: AddCartItemMutation_cartAddItem_CartResponse };


export type AddCartItemMutationVariables = Exact<{
  input: CartAddItemInput;
  locale: Scalars['String']['input'];
  analyticsLocale: Scalars['String']['input'];
}>;


export type AddCartItemMutation = AddCartItemMutation_Mutation;

export type CreateAnonCartMutation_cartStart_Cart = { __typename: 'Cart', cartId: string };

export type CreateAnonCartMutation_Mutation = { cartStart: CreateAnonCartMutation_cartStart_Cart };


export type CreateAnonCartMutationVariables = Exact<{ [key: string]: never; }>;


export type CreateAnonCartMutation = CreateAnonCartMutation_Mutation;

export type CreateAuthCartMutation_cartStart_Cart = { __typename: 'Cart', cartId: string };

export type CreateAuthCartMutation_Mutation = { cartStart: CreateAuthCartMutation_cartStart_Cart };


export type CreateAuthCartMutationVariables = Exact<{
  userId: Scalars['Int']['input'];
}>;


export type CreateAuthCartMutation = CreateAuthCartMutation_Mutation;

export type DeleteCartItemMutation_cartDeleteItem_CartResponse_cart_Cart = (
  { __typename: 'Cart' }
  & Custom_CartFragment
);

export type DeleteCartItemMutation_cartDeleteItem_CartResponse = { __typename: 'CartResponse', cart: DeleteCartItemMutation_cartDeleteItem_CartResponse_cart_Cart };

export type DeleteCartItemMutation_Mutation = { cartDeleteItem: DeleteCartItemMutation_cartDeleteItem_CartResponse };


export type DeleteCartItemMutationVariables = Exact<{
  cartId: Scalars['String']['input'];
  itemId: Scalars['Int']['input'];
  locale: Scalars['String']['input'];
  analyticsLocale: Scalars['String']['input'];
}>;


export type DeleteCartItemMutation = DeleteCartItemMutation_Mutation;

export type DeleteCartMutation_cartDelete_CartDeleteResponse = { __typename: 'CartDeleteResponse', success: boolean, message: string };

export type DeleteCartMutation_Mutation = { cartDelete: DeleteCartMutation_cartDelete_CartDeleteResponse };


export type DeleteCartMutationVariables = Exact<{
  cartId: Scalars['String']['input'];
}>;


export type DeleteCartMutation = DeleteCartMutation_Mutation;

export type GetCartQuery_cart_Cart = (
  { __typename: 'Cart' }
  & Custom_CartFragment
);

export type GetCartQuery_Query = { cart: GetCartQuery_cart_Cart };


export type GetCartQueryVariables = Exact<{
  cartId: Scalars['String']['input'];
  locale: Scalars['String']['input'];
  analyticsLocale: Scalars['String']['input'];
}>;


export type GetCartQuery = GetCartQuery_Query;

export type CartSetUserMutation_cartSetUser_CartResponse_cart_Cart = { __typename: 'Cart', cartId: string, userId: number };

export type CartSetUserMutation_cartSetUser_CartResponse = { __typename: 'CartResponse', cart: CartSetUserMutation_cartSetUser_CartResponse_cart_Cart };

export type CartSetUserMutation_Mutation = { cartSetUser: CartSetUserMutation_cartSetUser_CartResponse };


export type CartSetUserMutationVariables = Exact<{
  input: CartSetUserInput;
}>;


export type CartSetUserMutation = CartSetUserMutation_Mutation;

export type UpdateCartItemMutation_cartUpdateItem_CartResponse_cart_Cart = (
  { __typename: 'Cart' }
  & Custom_CartFragment
);

export type UpdateCartItemMutation_cartUpdateItem_CartResponse = { __typename: 'CartResponse', cart: UpdateCartItemMutation_cartUpdateItem_CartResponse_cart_Cart };

export type UpdateCartItemMutation_Mutation = { cartUpdateItem: UpdateCartItemMutation_cartUpdateItem_CartResponse };


export type UpdateCartItemMutationVariables = Exact<{
  input: CartUpdateItemInput;
  locale: Scalars['String']['input'];
  analyticsLocale: Scalars['String']['input'];
}>;


export type UpdateCartItemMutation = UpdateCartItemMutation_Mutation;

export type RootCategoriesQuery_categories_CategoryResponse_items_Category_name_LocalizedString = { __typename: 'LocalizedString', language: string, value?: string | null };

export type RootCategoriesQuery_categories_CategoryResponse_items_Category_description_LocalizedString = { __typename: 'LocalizedString', language: string, value?: string | null };

export type RootCategoriesQuery_categories_CategoryResponse_items_Category_shortDescription_LocalizedString = { __typename: 'LocalizedString', language: string, value?: string | null };

export type RootCategoriesQuery_categories_CategoryResponse_items_Category_slug_LocalizedString = { __typename: 'LocalizedString', language: string, value?: string | null };

export type RootCategoriesQuery_categories_CategoryResponse_items_Category_products_ProductsResponse = { __typename: 'ProductsResponse', itemsFound: number };

export type RootCategoriesQuery_categories_CategoryResponse_items_Category = { __typename: 'Category', categoryId: number, defaultLanguage: string, name: Array<RootCategoriesQuery_categories_CategoryResponse_items_Category_name_LocalizedString>, description: Array<RootCategoriesQuery_categories_CategoryResponse_items_Category_description_LocalizedString>, shortDescription: Array<RootCategoriesQuery_categories_CategoryResponse_items_Category_shortDescription_LocalizedString>, slug: Array<RootCategoriesQuery_categories_CategoryResponse_items_Category_slug_LocalizedString>, products: RootCategoriesQuery_categories_CategoryResponse_items_Category_products_ProductsResponse };

export type RootCategoriesQuery_categories_CategoryResponse = { __typename: 'CategoryResponse', itemsFound: number, items: Array<RootCategoriesQuery_categories_CategoryResponse_items_Category> };

export type RootCategoriesQuery_Query = { categories: RootCategoriesQuery_categories_CategoryResponse };


export type RootCategoriesQueryVariables = Exact<{
  parentCategoryId?: InputMaybe<Array<Scalars['Int']['input']>>;
}>;


export type RootCategoriesQuery = RootCategoriesQuery_Query;

export type PaymentCreateMutation_paymentCreate_Payment = { __typename: 'Payment', id: string };

export type PaymentCreateMutation_Mutation = { paymentCreate: PaymentCreateMutation_paymentCreate_Payment };


export type PaymentCreateMutationVariables = Exact<{
  input: CreatePaymentInput;
}>;


export type PaymentCreateMutation = PaymentCreateMutation_Mutation;

export type ProcessCartMutation_cartProcess_CartProcessResponse_response_CartResponseData = { __typename: 'CartResponseData', data: Record<string, unknown>, messages: Array<string> };

export type ProcessCartMutation_cartProcess_CartProcessResponse_order_Order = { __typename: 'Order', id: number, uuid: string };

export type ProcessCartMutation_cartProcess_CartProcessResponse = { __typename: 'CartProcessResponse', cartOrderId: number, response: ProcessCartMutation_cartProcess_CartProcessResponse_response_CartResponseData, order: ProcessCartMutation_cartProcess_CartProcessResponse_order_Order };

export type ProcessCartMutation_Mutation = { cartProcess: ProcessCartMutation_cartProcess_CartProcessResponse };


export type ProcessCartMutationVariables = Exact<{
  input: CartProcessInput;
}>;


export type ProcessCartMutation = ProcessCartMutation_Mutation;

export type UpdateCartAddressMutation_cartUpdateAddress_CartResponse_cart_Cart = { __typename: 'Cart', cartId: string };

export type UpdateCartAddressMutation_cartUpdateAddress_CartResponse = { __typename: 'CartResponse', cart: UpdateCartAddressMutation_cartUpdateAddress_CartResponse_cart_Cart };

export type UpdateCartAddressMutation_Mutation = { cartUpdateAddress: UpdateCartAddressMutation_cartUpdateAddress_CartResponse };


export type UpdateCartAddressMutationVariables = Exact<{
  input: CartUpdateAddressInput;
}>;


export type UpdateCartAddressMutation = UpdateCartAddressMutation_Mutation;

export type UpdateCartShippingCostMutation_cartUpdate_CartResponse_cart_Cart = { __typename: 'Cart', cartId: string };

export type UpdateCartShippingCostMutation_cartUpdate_CartResponse = { __typename: 'CartResponse', cart: UpdateCartShippingCostMutation_cartUpdate_CartResponse_cart_Cart };

export type UpdateCartShippingCostMutation_Mutation = { cartUpdate: UpdateCartShippingCostMutation_cartUpdate_CartResponse };


export type UpdateCartShippingCostMutationVariables = Exact<{
  cartId: Scalars['String']['input'];
  shippingCost?: InputMaybe<Scalars['Float']['input']>;
}>;


export type UpdateCartShippingCostMutation = UpdateCartShippingCostMutation_Mutation;

export type UpdateCartMutation_cartUpdate_CartResponse_cart_Cart = { __typename: 'Cart', cartId: string };

export type UpdateCartMutation_cartUpdate_CartResponse = { __typename: 'CartResponse', cart: UpdateCartMutation_cartUpdate_CartResponse_cart_Cart };

export type UpdateCartMutation_Mutation = { cartUpdate: UpdateCartMutation_cartUpdate_CartResponse };


export type UpdateCartMutationVariables = Exact<{
  input: CartUpdateInput;
}>;


export type UpdateCartMutation = UpdateCartMutation_Mutation;

export type OrderQuery_order_Order_total_OrderTotals_taxPercentages_OrderTotalTaxPercentage = { __typename: 'OrderTotalTaxPercentage', percentage: number, total: number };

export type OrderQuery_order_Order_total_OrderTotals = { __typename: 'OrderTotals', gross: number, net: number, tax: number, taxPercentages: Array<OrderQuery_order_Order_total_OrderTotals_taxPercentages_OrderTotalTaxPercentage> };

export type OrderQuery_order_Order_postageData_OrderPostageData = { __typename: 'OrderPostageData', method: string, taxPercentage: number, requestDate?: string | null, gross: number, net: number, tax: number };

export type OrderQuery_order_Order_addresses_Address = { __typename: 'Address', id: number, type: AddressType, code?: string | null, name?: string | null, lastName?: string | null, firstName?: string | null, street: string, number?: string | null, numberExtension?: string | null, postalCode: string, city: string, country: string, phone?: string | null, email?: string | null, notes?: string | null };

export type OrderQuery_order_Order_items_OrderItem = (
  { __typename: 'OrderItem' }
  & OrderItemFragment
);

export type OrderQuery_order_Order = { __typename: 'Order', id: number, userId: number, externalId?: string | null, source: string, reference?: string | null, status: string, remarks?: string | null, extra3?: string | null, email: string, total: OrderQuery_order_Order_total_OrderTotals, postageData: OrderQuery_order_Order_postageData_OrderPostageData, addresses: Array<OrderQuery_order_Order_addresses_Address>, items: Array<OrderQuery_order_Order_items_OrderItem> };

export type OrderQuery_Query = { order: OrderQuery_order_Order };


export type OrderQueryVariables = Exact<{
  orderUUID?: InputMaybe<Scalars['String']['input']>;
  locale: Scalars['String']['input'];
}>;


export type OrderQuery = OrderQuery_Query;

export type OrdersQuery_orders_OrderResponse_items_Order_postageData_OrderPostageData = { __typename: 'OrderPostageData', method: string };

export type OrdersQuery_orders_OrderResponse_items_Order_total_OrderTotals = { __typename: 'OrderTotals', gross: number, net: number };

export type OrdersQuery_orders_OrderResponse_items_Order_items_OrderItem = (
  { __typename: 'OrderItem' }
  & OrderItemFragment
);

export type OrdersQuery_orders_OrderResponse_items_Order = { __typename: 'Order', id: number, uuid: string, externalId?: string | null, date: string, reference?: string | null, status: string, postageData: OrdersQuery_orders_OrderResponse_items_Order_postageData_OrderPostageData, total: OrdersQuery_orders_OrderResponse_items_Order_total_OrderTotals, items: Array<OrdersQuery_orders_OrderResponse_items_Order_items_OrderItem> };

export type OrdersQuery_orders_OrderResponse = { __typename: 'OrderResponse', items: Array<OrdersQuery_orders_OrderResponse_items_Order> };

export type OrdersQuery_Query = { orders: OrdersQuery_orders_OrderResponse };


export type OrdersQueryVariables = Exact<{
  input: OrderSearchArguments;
  locale: Scalars['String']['input'];
}>;


export type OrdersQuery = OrdersQuery_Query;

export type PaymentQuery_payment_Payment = { __typename: 'Payment', method: string };

export type PaymentQuery_Query = { payment?: PaymentQuery_payment_Payment | null };


export type PaymentQueryVariables = Exact<{
  orderId: Scalars['Float']['input'];
}>;


export type PaymentQuery = PaymentQuery_Query;

export type AttributeDescriptionsQuery_attributeDescription_AttributeDescriptionResponse_items_AttributeDescription_description_LocalizedString = { __typename: 'LocalizedString', language: string, value?: string | null };

export type AttributeDescriptionsQuery_attributeDescription_AttributeDescriptionResponse_items_AttributeDescription = { __typename: 'AttributeDescription', searchId: string, isPublic: boolean, isSearchable: boolean, description: Array<AttributeDescriptionsQuery_attributeDescription_AttributeDescriptionResponse_items_AttributeDescription_description_LocalizedString> };

export type AttributeDescriptionsQuery_attributeDescription_AttributeDescriptionResponse = { __typename: 'AttributeDescriptionResponse', items: Array<AttributeDescriptionsQuery_attributeDescription_AttributeDescriptionResponse_items_AttributeDescription> };

export type AttributeDescriptionsQuery_Query = { attributeDescription: AttributeDescriptionsQuery_attributeDescription_AttributeDescriptionResponse };


export type AttributeDescriptionsQueryVariables = Exact<{ [key: string]: never; }>;


export type AttributeDescriptionsQuery = AttributeDescriptionsQuery_Query;

export type ProductDefaultPricesQuery_defaultPrice_ProductDefaultPrice = { __typename: 'ProductDefaultPrice', productId?: number | null, net?: number | null, gross?: number | null, taxCode?: string | null };

export type ProductDefaultPricesQuery_Query = { defaultPrice: Array<ProductDefaultPricesQuery_defaultPrice_ProductDefaultPrice> };


export type ProductDefaultPricesQueryVariables = Exact<{
  productIds: Array<Scalars['Int']['input']>;
  taxZone: Scalars['String']['input'];
}>;


export type ProductDefaultPricesQuery = ProductDefaultPricesQuery_Query;

export type GetClusterQuery_cluster_Cluster_defaultProduct_Product = (
  { __typename: 'Product' }
  & ProductDetailFragment
);

export type GetClusterQuery_cluster_Cluster = { __typename: 'Cluster', defaultProduct?: GetClusterQuery_cluster_Cluster_defaultProduct_Product | null };

export type GetClusterQuery_Query = { cluster: GetClusterQuery_cluster_Cluster };


export type GetClusterQueryVariables = Exact<{
  clusterId: Scalars['Int']['input'];
  locale: Scalars['String']['input'];
  analyticsLocale: Scalars['String']['input'];
}>;


export type GetClusterQuery = GetClusterQuery_Query;

export type GetProductsQuery_products_ProductsResponse_items_Cluster = { __typename: 'Cluster' };

export type GetProductsQuery_products_ProductsResponse_items_Product = (
  { __typename: 'Product' }
  & ProductListItemFragment
);

export type GetProductsQuery_products_ProductsResponse_items = GetProductsQuery_products_ProductsResponse_items_Cluster | GetProductsQuery_products_ProductsResponse_items_Product;

export type GetProductsQuery_products_ProductsResponse = { __typename: 'ProductsResponse', itemsFound: number, items: Array<GetProductsQuery_products_ProductsResponse_items> };

export type GetProductsQuery_Query = { products: GetProductsQuery_products_ProductsResponse };


export type GetProductsQueryVariables = Exact<{
  locale: Scalars['String']['input'];
  analyticsLocale: Scalars['String']['input'];
  productIds?: InputMaybe<Array<Scalars['Int']['input']>>;
  page?: InputMaybe<Scalars['Int']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
}>;


export type GetProductsQuery = GetProductsQuery_Query;

export type SearchProductsClustersQuery_products_ProductsResponse_items_Cluster = (
  { __typename: 'Cluster' }
  & ClusterListItemFragment
);

export type SearchProductsClustersQuery_products_ProductsResponse_items_Product = { __typename: 'Product' };

export type SearchProductsClustersQuery_products_ProductsResponse_items = SearchProductsClustersQuery_products_ProductsResponse_items_Cluster | SearchProductsClustersQuery_products_ProductsResponse_items_Product;

export type SearchProductsClustersQuery_products_ProductsResponse_filters_AttributeFilter_textFilter_AttributeTextFilter = { __typename: 'AttributeTextFilter', value: string, isSelected: boolean, count: number, countActive?: number | null };

export type SearchProductsClustersQuery_products_ProductsResponse_filters_AttributeFilter_integerRangeFilter_AttributeIntegerRangeFilter = { __typename: 'AttributeIntegerRangeFilter', min: number, max: number };

export type SearchProductsClustersQuery_products_ProductsResponse_filters_AttributeFilter_decimalRangeFilter_AttributeDecimalRangeFilter = { __typename: 'AttributeDecimalRangeFilter', min: number, max: number };

export type SearchProductsClustersQuery_products_ProductsResponse_filters_AttributeFilter = { __typename: 'AttributeFilter', id: string, searchId: string, description: string, type: AttributeType, textFilter?: Array<SearchProductsClustersQuery_products_ProductsResponse_filters_AttributeFilter_textFilter_AttributeTextFilter> | null, integerRangeFilter?: SearchProductsClustersQuery_products_ProductsResponse_filters_AttributeFilter_integerRangeFilter_AttributeIntegerRangeFilter | null, decimalRangeFilter?: SearchProductsClustersQuery_products_ProductsResponse_filters_AttributeFilter_decimalRangeFilter_AttributeDecimalRangeFilter | null };

export type SearchProductsClustersQuery_products_ProductsResponse = { __typename: 'ProductsResponse', page: number, pages: number, start: number, end: number, offset: number, itemsFound: number, minPrice: number, maxPrice: number, items: Array<SearchProductsClustersQuery_products_ProductsResponse_items>, filters?: Array<SearchProductsClustersQuery_products_ProductsResponse_filters_AttributeFilter> | null };

export type SearchProductsClustersQuery_Query = { products: SearchProductsClustersQuery_products_ProductsResponse };


export type SearchProductsClustersQueryVariables = Exact<{
  locale: Scalars['String']['input'];
  analyticsLocale: Scalars['String']['input'];
  categoryId?: InputMaybe<Scalars['Int']['input']>;
  term?: InputMaybe<Scalars['String']['input']>;
  sku?: InputMaybe<Array<Scalars['String']['input']>>;
  clusterIds?: InputMaybe<Array<Scalars['Int']['input']>>;
  textFilters?: InputMaybe<Array<TextFilterInput>>;
  rangeFilters?: InputMaybe<Array<RangeFilterInput>>;
  priceFilter?: InputMaybe<PriceFilterInput>;
  page?: InputMaybe<Scalars['Int']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
  status?: InputMaybe<Array<ProductStatus>>;
  sort?: InputMaybe<Array<SortInput>>;
}>;


export type SearchProductsClustersQuery = SearchProductsClustersQuery_Query;

export type SearchSuggestionClustersQuery_products_ProductsResponse_items_Cluster = (
  { __typename: 'Cluster' }
  & ClusterListItemFragment
);

export type SearchSuggestionClustersQuery_products_ProductsResponse_items_Product = { __typename: 'Product' };

export type SearchSuggestionClustersQuery_products_ProductsResponse_items = SearchSuggestionClustersQuery_products_ProductsResponse_items_Cluster | SearchSuggestionClustersQuery_products_ProductsResponse_items_Product;

export type SearchSuggestionClustersQuery_products_ProductsResponse = { __typename: 'ProductsResponse', itemsFound: number, items: Array<SearchSuggestionClustersQuery_products_ProductsResponse_items> };

export type SearchSuggestionClustersQuery_Query = { products: SearchSuggestionClustersQuery_products_ProductsResponse };


export type SearchSuggestionClustersQueryVariables = Exact<{
  locale: Scalars['String']['input'];
  analyticsLocale: Scalars['String']['input'];
  term?: InputMaybe<Scalars['String']['input']>;
  searchFields?: InputMaybe<Array<SearchFieldsInput>>;
  status?: InputMaybe<Array<ProductStatus>>;
  textFilters?: InputMaybe<Array<TextFilterInput>>;
  sort?: InputMaybe<Array<SortInput>>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
}>;


export type SearchSuggestionClustersQuery = SearchSuggestionClustersQuery_Query;

export type AuthenticationAccountDatesQuery_authentication_GCIPUser_metadata_GCIPMetadata = { __typename: 'GCIPMetadata', creationTime?: string | null, lastSignInTime?: string | null, lastRefreshTime?: string | null };

export type AuthenticationAccountDatesQuery_authentication_GCIPUser = { __typename: 'GCIPUser', metadata?: AuthenticationAccountDatesQuery_authentication_GCIPUser_metadata_GCIPMetadata | null };

export type AuthenticationAccountDatesQuery_Query = { authentication: AuthenticationAccountDatesQuery_authentication_GCIPUser };


export type AuthenticationAccountDatesQueryVariables = Exact<{
  email: Scalars['String']['input'];
}>;


export type AuthenticationAccountDatesQuery = AuthenticationAccountDatesQuery_Query;

export type ForceOrderSetAsExportedMutation_orderUpdate_Order = { __typename: 'Order', id: number };

export type ForceOrderSetAsExportedMutation_Mutation = { orderUpdate: ForceOrderSetAsExportedMutation_orderUpdate_Order };


export type ForceOrderSetAsExportedMutationVariables = Exact<{
  orderId: Scalars['Int']['input'];
  exportStatus?: InputMaybe<OrderExportStatus>;
  exportMessage?: InputMaybe<Scalars['String']['input']>;
  exportedAt?: InputMaybe<Scalars['String']['input']>;
}>;


export type ForceOrderSetAsExportedMutation = ForceOrderSetAsExportedMutation_Mutation;

export type OrderSetStatusMutation_orderSetStatus_Order = { __typename: 'Order', id: number };

export type OrderSetStatusMutation_Mutation = { orderSetStatus: OrderSetStatusMutation_orderSetStatus_Order };


export type OrderSetStatusMutationVariables = Exact<{
  input: OrderSetStatusInput;
}>;


export type OrderSetStatusMutation = OrderSetStatusMutation_Mutation;

export type PaymentUpdateMutation_paymentUpdate_Payment = { __typename: 'Payment', id: string };

export type PaymentUpdateMutation_Mutation = { paymentUpdate: PaymentUpdateMutation_paymentUpdate_Payment };


export type PaymentUpdateMutationVariables = Exact<{
  search: SearchByInput;
  input: UpdatePaymentInput;
}>;


export type PaymentUpdateMutation = PaymentUpdateMutation_Mutation;

export type GetSitemapProductsQuery_products_ProductsResponse_items_Cluster = (
  { __typename: 'Cluster' }
  & SitemapClusterListItemFragment
);

export type GetSitemapProductsQuery_products_ProductsResponse_items_Product = { __typename: 'Product' };

export type GetSitemapProductsQuery_products_ProductsResponse_items = GetSitemapProductsQuery_products_ProductsResponse_items_Cluster | GetSitemapProductsQuery_products_ProductsResponse_items_Product;

export type GetSitemapProductsQuery_products_ProductsResponse = { __typename: 'ProductsResponse', items: Array<GetSitemapProductsQuery_products_ProductsResponse_items> };

export type GetSitemapProductsQuery_Query = { products: GetSitemapProductsQuery_products_ProductsResponse };


export type GetSitemapProductsQueryVariables = Exact<{
  page?: InputMaybe<Scalars['Int']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<Array<SortInput>>;
}>;


export type GetSitemapProductsQuery = GetSitemapProductsQuery_Query;

export type GetPaymentStatusQuery_payment_Payment = { __typename: 'Payment', id: string, paymentId?: string | null, status: PaymentStatuses, amount: number };

export type GetPaymentStatusQuery_Query = { payment?: GetPaymentStatusQuery_payment_Payment | null };


export type GetPaymentStatusQueryVariables = Exact<{
  orderId?: InputMaybe<Scalars['Float']['input']>;
  paymentId?: InputMaybe<Scalars['String']['input']>;
}>;


export type GetPaymentStatusQuery = GetPaymentStatusQuery_Query;

export type GetClusterUrlDataQuery_products_ProductsResponse_items_Cluster_slug_LocalizedString = { __typename: 'LocalizedString', language: string, value?: string | null };

export type GetClusterUrlDataQuery_products_ProductsResponse_items_Cluster = { __typename: 'Cluster', clusterId: number, slug: Array<GetClusterUrlDataQuery_products_ProductsResponse_items_Cluster_slug_LocalizedString> };

export type GetClusterUrlDataQuery_products_ProductsResponse_items_Product = { __typename: 'Product' };

export type GetClusterUrlDataQuery_products_ProductsResponse_items = GetClusterUrlDataQuery_products_ProductsResponse_items_Cluster | GetClusterUrlDataQuery_products_ProductsResponse_items_Product;

export type GetClusterUrlDataQuery_products_ProductsResponse = { __typename: 'ProductsResponse', items: Array<GetClusterUrlDataQuery_products_ProductsResponse_items> };

export type GetClusterUrlDataQuery_Query = { products: GetClusterUrlDataQuery_products_ProductsResponse };


export type GetClusterUrlDataQueryVariables = Exact<{
  clusterCode?: InputMaybe<Scalars['String']['input']>;
  languageCode?: InputMaybe<Scalars['String']['input']>;
}>;


export type GetClusterUrlDataQuery = GetClusterUrlDataQuery_Query;

export const ProductImagesFragmentDoc = gql`
    fragment ProductImages on Product {
  media {
    images {
      items {
        alt(language: $locale) {
          language
          value
        }
        images {
          language
          originalUrl
          mimeType
        }
      }
    }
  }
}
    `;
export const ProductListItemFragmentDoc = gql`
    fragment ProductListItem on Product {
  __typename
  productId
  categoryId
  cluster {
    clusterId
    sku
    name(language: $locale) {
      language
      value
    }
    slug(language: $locale) {
      language
      value
    }
    analyticsName: name(language: $analyticsLocale) {
      value
    }
  }
  sku
  eanCode
  slug(language: $locale) {
    language
    value
  }
  name(language: $locale) {
    language
    value
  }
  description(language: $locale) {
    language
    value
  }
  price {
    net
    gross
  }
  status
  package
  minimumOrderQuantity: minimumQuantity
  incrementalOrderQuantity: unit
  color: attributeValues(filter: {offset: 1, name: "BC_KLEUR"}) {
    items {
      attributeId
      textValue(language: $locale) {
        values
      }
    }
  }
  country: attributeValues(filter: {offset: 1, name: "BC_LAND"}) {
    items {
      attributeId
      textValue(language: $locale) {
        values
      }
    }
  }
  region: attributeValues(filter: {offset: 1, name: "BC_STREEK"}) {
    items {
      attributeId
      textValue(language: $locale) {
        values
      }
    }
  }
  volume: attributeValues(filter: {offset: 1, name: "BC_BASISEENHEIDINHOUDL"}) {
    items {
      attributeId
      decimalValue
    }
  }
  year: attributeValues(filter: {offset: 1, name: "BC_JAARGANG"}) {
    items {
      attributeId
      textValue(language: $locale) {
        values
      }
    }
  }
  isNew: attributeValues(filter: {offset: 1, name: "BC_NIEUW"}) {
    items {
      attributeId
      textValue(language: $locale) {
        values
      }
    }
  }
  webStatusCode: attributeValues(filter: {offset: 1, name: "BC_WEBSTATUSCODE"}) {
    items {
      attributeId
      textValue(language: $locale) {
        values
      }
    }
  }
  vatPercentage: attributeValues(filter: {offset: 1, name: "BC_BTW"}) {
    items {
      attributeId
      decimalValue
    }
  }
  analyticsBrandAttribute: attributeValues(filter: {offset: 1, name: "BC_DOMEIN"}) {
    items {
      attributeId
      textValue(language: $analyticsLocale) {
        values
      }
    }
  }
  analyticsCategoryPath: categoryPath {
    categoryId
    analyticsName: name(language: $analyticsLocale) {
      value
    }
  }
  ...ProductImages
}
    ${ProductImagesFragmentDoc}`;
export const ClusterListItemFragmentDoc = gql`
    fragment ClusterListItem on Cluster {
  __typename
  clusterId
  sku
  products {
    ...ProductListItem
  }
  defaultProduct {
    ...ProductListItem
  }
}
    ${ProductListItemFragmentDoc}`;
export const Custom_CartAddressFragmentDoc = gql`
    fragment Custom_CartAddress on CartAddress {
  code
  lastName
  firstName
  street
  number
  numberExtension
  postalCode
  city
  country
  phone
  email
  company
  notes
  icp
}
    `;
export const Custom_CartFragmentDoc = gql`
    fragment Custom_Cart on Cart {
  cartId
  userId
  channelId
  notes
  extra3
  reference
  orderStatus
  actionCode
  total {
    totalGross
    totalNet
  }
  postageData {
    shippingMethod
    requestDate
    postage
    postageNet
  }
  taxLevels {
    taxCode
    price
  }
  invoiceAddress {
    ...Custom_CartAddress
  }
  deliveryAddress {
    ...Custom_CartAddress
  }
  items {
    id
    productId
    taxCode
    quantity
    price
    priceNet
    totalPrice
    totalPriceNet
    totalSum
    totalSumNet
    sum
    sumNet
    notes
    product {
      ...ProductListItem
    }
    childItems {
      id
      productId
      taxCode
      quantity
      price
      priceNet
      totalPrice
      totalPriceNet
      product {
        id
        sku
        package
      }
    }
  }
}
    ${Custom_CartAddressFragmentDoc}
${ProductListItemFragmentDoc}`;
export const OrderItemFragmentDoc = gql`
    fragment OrderItem on OrderItem {
  id
  class
  parentOrderItemId
  productId
  sku
  name
  quantity
  package
  price
  priceTotal
  priceNet
  priceTotalNet
  tax
  product {
    package
    cluster {
      sku
    }
    country: attributeValues(filter: {offset: 1, name: "BC_LAND"}) {
      items {
        attributeId
        textValue(language: $locale) {
          values
        }
      }
    }
    region: attributeValues(filter: {offset: 1, name: "BC_STREEK"}) {
      items {
        attributeId
        textValue(language: $locale) {
          values
        }
      }
    }
    volume: attributeValues(filter: {offset: 1, name: "BC_BASISEENHEIDINHOUDL"}) {
      items {
        attributeId
        decimalValue
      }
    }
    year: attributeValues(filter: {offset: 1, name: "BC_JAARGANG"}) {
      items {
        attributeId
        textValue(language: $locale) {
          values
        }
      }
    }
  }
}
    `;
export const ProductAttributesFragmentDoc = gql`
    fragment ProductAttributes on Product {
  attributeValues(filter: {isPublic: true, offset: 100}) {
    itemsFound
    items {
      attributeId
      textValue(language: $locale) {
        language
        values
      }
      enumValue
      intValue
      decimalValue
      dateValue
      attributeDescription {
        name
        id
        searchId
        description(language: $locale) {
          language
          value
        }
        type
        typeParam
        group
      }
    }
  }
}
    `;
export const ProductDocumentsFragmentDoc = gql`
    fragment ProductDocuments on Product {
  media {
    documents {
      items {
        documents {
          language
          originalUrl
          mimeType
        }
      }
    }
  }
}
    `;
export const ProductDetailFragmentDoc = gql`
    fragment ProductDetail on Product {
  ...ProductListItem
  ...ProductAttributes
  ...ProductDocuments
  shortDescription(language: $locale) {
    language
    value
  }
}
    ${ProductListItemFragmentDoc}
${ProductAttributesFragmentDoc}
${ProductDocumentsFragmentDoc}`;
export const SitemapProductListItemFragmentDoc = gql`
    fragment SitemapProductListItem on Product {
  __typename
  cluster {
    clusterId
    slug {
      language
      value
    }
  }
  dateCreated
  dateChanged
}
    `;
export const SitemapClusterListItemFragmentDoc = gql`
    fragment SitemapClusterListItem on Cluster {
  __typename
  defaultProduct {
    ...SitemapProductListItem
  }
}
    ${SitemapProductListItemFragmentDoc}`;
export const LoginDocument = gql`
    mutation Login($email: String!, $password: String!) {
  login(input: {email: $email, password: $password}) {
    session {
      accessToken
      refreshToken
      expirationTime
    }
  }
}
    `;
export const RefreshTokenDocument = gql`
    mutation refreshToken($refreshToken: String!) {
  exchangeRefreshToken(input: {refreshToken: $refreshToken}) {
    access_token
    refresh_token
    expires_in
  }
}
    `;
export const CompanyAddressCreateDocument = gql`
    mutation companyAddressCreate($input: CompanyAddressCreateInput!) {
  companyAddressCreate(input: $input) {
    id
  }
}
    `;
export const CompanyAddressDeleteDocument = gql`
    mutation companyAddressDelete($input: CompanyAddressDeleteInput!) {
  companyAddressDelete(input: $input)
}
    `;
export const CompanyAddressListDocument = gql`
    query companyAddressList($companyId: Float!, $type: AddressType) {
  addressesByCompanyId(companyId: $companyId, type: $type) {
    id
    code
    name
    firstName
    lastName
    company
    street
    number
    numberExtension
    postalCode
    city
    country
    phone
    email
    isDefault
    type
  }
}
    `;
export const CompanyAddressUpdateDocument = gql`
    mutation companyAddressUpdate($input: CompanyAddressUpdateInput!) {
  companyAddressUpdate(input: $input) {
    id
  }
}
    `;
export const CustomerAddressCreateDocument = gql`
    mutation customerAddressCreate($input: CustomerAddressCreateInput!) {
  customerAddressCreate(input: $input) {
    id
  }
}
    `;
export const CustomerAddressDeleteDocument = gql`
    mutation customerAddressDelete($input: CustomerAddressDeleteInput!) {
  customerAddressDelete(input: $input)
}
    `;
export const CustomerAddressListDocument = gql`
    query customerAddressList($customerId: Float!, $type: AddressType) {
  addressesByCustomerId(customerId: $customerId, type: $type) {
    id
    code
    name
    firstName
    lastName
    company
    street
    number
    numberExtension
    postalCode
    city
    country
    phone
    email
    isDefault
    type
  }
}
    `;
export const CustomerAddressUpdateDocument = gql`
    mutation customerAddressUpdate($input: CustomerAddressUpdateInput!) {
  customerAddressUpdate(input: $input) {
    id
  }
}
    `;
export const ContactRegisterDocument = gql`
    mutation contactRegister($input: RegisterContactInput!) {
  contactRegister(input: $input) {
    contact {
      __typename
      ... on Contact {
        contactId
      }
    }
  }
}
    `;
export const CompanyCreateDocument = gql`
    mutation companyCreate($input: CreateCompanyInput!) {
  companyCreate(input: $input) {
    companyId
  }
}
    `;
export const CustomerRegisterDocument = gql`
    mutation customerRegister($input: RegisterCustomerInput!) {
  customerRegister(input: $input) {
    customer {
      __typename
      email
      ... on Customer {
        customerId
      }
    }
  }
}
    `;
export const CustomerDeleteDocument = gql`
    mutation customerDelete($customerId: Int!) {
  customerDelete(customerId: $customerId)
}
    `;
export const GetAuthByEmailDocument = gql`
    query getAuthByEmail($email: String!) {
  authentication(email: $email) {
    email
  }
}
    `;
export const ContactDocument = gql`
    query contact($contactId: Float!) {
  contact(contactId: $contactId) {
    __typename
    contactId
    firstName
    lastName
    email
    primaryLanguage
    parentCompanyId
    company {
      name
      taxNumber
      addresses(type: invoice) {
        id
        country
      }
    }
    attributeValues(
      filter: {name: ["BC_CUSTOMER_CODE", "BC_EXAMPLE_VALUE", "APP_CART_ID"]}
    ) {
      items {
        __typename
        attributeId
        attributeDescription {
          name
        }
        textValue {
          language
          values
        }
        intValue
      }
    }
  }
}
    `;
export const CustomerDocument = gql`
    query customer($customerId: Float!) {
  customer(customerId: $customerId) {
    __typename
    customerId
    firstName
    lastName
    email
    primaryLanguage
    addresses(isDefault: Y) {
      id
      country
    }
    attributeValues(filter: {name: ["BC_EXAMPLE_VALUE", "APP_CART_ID"]}) {
      items {
        __typename
        attributeId
        attributeDescription {
          name
        }
        textValue {
          language
          values
        }
        intValue
      }
    }
  }
}
    `;
export const PasswordResetLinkDocument = gql`
    mutation passwordResetLink($email: String!, $lang: String!, $redirectUrl: String!) {
  passwordResetLink(
    input: {email: $email, language: $lang, redirectUrl: $redirectUrl}
  )
}
    `;
export const ContactUpdateDocument = gql`
    mutation contactUpdate($input: UpdateContactInput!) {
  contactUpdate(input: $input) {
    id
  }
}
    `;
export const CustomerUpdateDocument = gql`
    mutation customerUpdate($input: UpdateCustomerInput!) {
  customerUpdate(input: $input) {
    id
  }
}
    `;
export const AddCartItemDocument = gql`
    mutation addCartItem($input: CartAddItemInput!, $locale: String!, $analyticsLocale: String!) {
  cartAddItem(input: $input) {
    cart {
      ...Custom_Cart
    }
  }
}
    ${Custom_CartFragmentDoc}`;
export const CreateAnonCartDocument = gql`
    mutation createAnonCart {
  cartStart {
    cartId
  }
}
    `;
export const CreateAuthCartDocument = gql`
    mutation createAuthCart($userId: Int!) {
  cartStart(userId: $userId) {
    cartId
  }
}
    `;
export const DeleteCartItemDocument = gql`
    mutation deleteCartItem($cartId: String!, $itemId: Int!, $locale: String!, $analyticsLocale: String!) {
  cartDeleteItem(input: {cartId: $cartId, itemId: $itemId}) {
    cart {
      ...Custom_Cart
    }
  }
}
    ${Custom_CartFragmentDoc}`;
export const DeleteCartDocument = gql`
    mutation deleteCart($cartId: String!) {
  cartDelete(cartId: $cartId) {
    success
    message
  }
}
    `;
export const GetCartDocument = gql`
    query getCart($cartId: String!, $locale: String!, $analyticsLocale: String!) {
  cart(cartId: $cartId) {
    ...Custom_Cart
  }
}
    ${Custom_CartFragmentDoc}`;
export const CartSetUserDocument = gql`
    mutation cartSetUser($input: CartSetUserInput!) {
  cartSetUser(input: $input) {
    cart {
      cartId
      userId
    }
  }
}
    `;
export const UpdateCartItemDocument = gql`
    mutation updateCartItem($input: CartUpdateItemInput!, $locale: String!, $analyticsLocale: String!) {
  cartUpdateItem(input: $input) {
    cart {
      ...Custom_Cart
    }
  }
}
    ${Custom_CartFragmentDoc}`;
export const RootCategoriesDocument = gql`
    query rootCategories($parentCategoryId: [Int!]) {
  categories(filter: {parentCategoryId: $parentCategoryId}) {
    itemsFound
    items {
      categoryId
      defaultLanguage
      name {
        language
        value
      }
      description {
        language
        value
      }
      shortDescription {
        language
        value
      }
      slug {
        language
        value
      }
      products {
        itemsFound
      }
    }
  }
}
    `;
export const PaymentCreateDocument = gql`
    mutation paymentCreate($input: CreatePaymentInput!) {
  paymentCreate(input: $input) {
    id
  }
}
    `;
export const ProcessCartDocument = gql`
    mutation processCart($input: CartProcessInput!) {
  cartProcess(input: $input) {
    cartOrderId
    response {
      data
      messages
    }
    order {
      id
      uuid
    }
  }
}
    `;
export const UpdateCartAddressDocument = gql`
    mutation updateCartAddress($input: CartUpdateAddressInput!) {
  cartUpdateAddress(input: $input) {
    cart {
      cartId
    }
  }
}
    `;
export const UpdateCartShippingCostDocument = gql`
    mutation updateCartShippingCost($cartId: String!, $shippingCost: Float) {
  cartUpdate(input: {cartId: $cartId, postageData: {postage: $shippingCost}}) {
    cart {
      cartId
    }
  }
}
    `;
export const UpdateCartDocument = gql`
    mutation updateCart($input: CartUpdateInput!) {
  cartUpdate(input: $input) {
    cart {
      cartId
    }
  }
}
    `;
export const OrderDocument = gql`
    query order($orderUUID: String, $locale: String!) {
  order(orderUUID: $orderUUID) {
    id
    userId
    externalId
    source
    reference
    status
    remarks
    extra3
    email
    total {
      gross
      net
      tax
      taxPercentages {
        percentage
        total
      }
    }
    postageData {
      method
      taxPercentage
      requestDate
      gross
      net
      tax
    }
    addresses {
      id
      type
      code
      name
      lastName
      firstName
      street
      number
      numberExtension
      postalCode
      city
      country
      phone
      email
      notes
    }
    items {
      ...OrderItem
    }
  }
}
    ${OrderItemFragmentDoc}`;
export const OrdersDocument = gql`
    query orders($input: OrderSearchArguments!, $locale: String!) {
  orders(input: $input) {
    items {
      id
      uuid
      externalId
      date
      reference
      status
      postageData {
        method
      }
      total {
        gross
        net
      }
      items {
        ...OrderItem
      }
    }
  }
}
    ${OrderItemFragmentDoc}`;
export const PaymentDocument = gql`
    query payment($orderId: Float!) {
  payment(searchBy: {orderId: $orderId}) {
    method
  }
}
    `;
export const AttributeDescriptionsDocument = gql`
    query attributeDescriptions {
  attributeDescription(input: {class: "product", offset: 100}) {
    items {
      searchId
      isPublic
      isSearchable
      description {
        language
        value
      }
    }
  }
}
    `;
export const ProductDefaultPricesDocument = gql`
    query productDefaultPrices($productIds: [Int!]!, $taxZone: String!) {
  defaultPrice(productIds: $productIds, taxZone: $taxZone) {
    productId
    net
    gross
    taxCode
  }
}
    `;
export const GetClusterDocument = gql`
    query getCluster($clusterId: Int!, $locale: String!, $analyticsLocale: String!) {
  cluster(clusterId: $clusterId, language: $locale) {
    defaultProduct {
      ...ProductDetail
    }
  }
}
    ${ProductDetailFragmentDoc}`;
export const GetProductsDocument = gql`
    query getProducts($locale: String!, $analyticsLocale: String!, $productIds: [Int!], $page: Int, $pageSize: Int) {
  products(
    language: $locale
    class: product
    classId: $productIds
    page: $page
    offset: $pageSize
  ) {
    itemsFound
    items {
      ...ProductListItem
    }
  }
}
    ${ProductListItemFragmentDoc}`;
export const SearchProductsClustersDocument = gql`
    query searchProductsClusters($locale: String!, $analyticsLocale: String!, $categoryId: Int, $term: String, $sku: [String!], $clusterIds: [Int!], $textFilters: [TextFilterInput!], $rangeFilters: [RangeFilterInput!], $priceFilter: PriceFilterInput, $page: Int, $pageSize: Int, $status: [ProductStatus!], $sort: [SortInput!]) {
  products(
    language: $locale
    categoryId: $categoryId
    term: $term
    sku: $sku
    class: cluster
    classId: $clusterIds
    textFilters: $textFilters
    rangeFilters: $rangeFilters
    price: $priceFilter
    page: $page
    offset: $pageSize
    status: $status
    sort: $sort
  ) {
    page
    pages
    start
    end
    offset
    itemsFound
    items {
      ...ClusterListItem
    }
    filters {
      id
      searchId
      description
      type
      textFilter {
        value
        isSelected
        count
        countActive
      }
      integerRangeFilter {
        min
        max
      }
      decimalRangeFilter {
        min
        max
      }
    }
    minPrice
    maxPrice
  }
}
    ${ClusterListItemFragmentDoc}`;
export const SearchSuggestionClustersDocument = gql`
    query searchSuggestionClusters($locale: String!, $analyticsLocale: String!, $term: String, $searchFields: [SearchFieldsInput!], $status: [ProductStatus!], $textFilters: [TextFilterInput!], $sort: [SortInput!], $pageSize: Int) {
  products(
    language: $locale
    term: $term
    searchFields: $searchFields
    status: $status
    class: cluster
    textFilters: $textFilters
    sort: $sort
    offset: $pageSize
  ) {
    itemsFound
    items {
      ...ClusterListItem
    }
  }
}
    ${ClusterListItemFragmentDoc}`;
export const AuthenticationAccountDatesDocument = gql`
    query authenticationAccountDates($email: String!) {
  authentication(email: $email) {
    metadata {
      creationTime
      lastSignInTime
      lastRefreshTime
    }
  }
}
    `;
export const ForceOrderSetAsExportedDocument = gql`
    mutation forceOrderSetAsExported($orderId: Int!, $exportStatus: OrderExportStatus, $exportMessage: String, $exportedAt: String) {
  orderUpdate(
    orderId: $orderId
    order: {exportStatus: $exportStatus, exportMessage: $exportMessage, exportedAt: $exportedAt}
  ) {
    id
  }
}
    `;
export const OrderSetStatusDocument = gql`
    mutation orderSetStatus($input: OrderSetStatusInput!) {
  orderSetStatus(input: $input) {
    id
  }
}
    `;
export const PaymentUpdateDocument = gql`
    mutation paymentUpdate($search: SearchByInput!, $input: UpdatePaymentInput!) {
  paymentUpdate(searchBy: $search, input: $input) {
    id
  }
}
    `;
export const GetSitemapProductsDocument = gql`
    query getSitemapProducts($page: Int, $pageSize: Int, $sort: [SortInput!]) {
  products(offset: $pageSize, page: $page, sort: $sort) {
    items {
      ...SitemapClusterListItem
    }
  }
}
    ${SitemapClusterListItemFragmentDoc}`;
export const GetPaymentStatusDocument = gql`
    query getPaymentStatus($orderId: Float, $paymentId: String) {
  payment(searchBy: {paymentId: $paymentId, orderId: $orderId}) {
    id
    paymentId
    status
    amount
  }
}
    `;
export const GetClusterUrlDataDocument = gql`
    query getClusterUrlData($clusterCode: String, $languageCode: String) {
  products(class: cluster, term: $clusterCode) {
    items {
      __typename
      ... on ICluster {
        clusterId
        slug(language: $languageCode) {
          language
          value
        }
      }
    }
  }
}
    `;

export type SdkFunctionWrapper = <T>(action: (requestHeaders?:Record<string, string>) => Promise<T>, operationName: string, operationType?: string, variables?: any) => Promise<T>;


const defaultWrapper: SdkFunctionWrapper = (action, _operationName, _operationType, _variables) => action();

export function getSdk(client: GraphQLClient, withWrapper: SdkFunctionWrapper = defaultWrapper) {
  return {
    Login(variables: LoginMutationVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<LoginMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<LoginMutation>(LoginDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'Login', 'mutation', variables);
    },
    refreshToken(variables: RefreshTokenMutationVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<RefreshTokenMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<RefreshTokenMutation>(RefreshTokenDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'refreshToken', 'mutation', variables);
    },
    companyAddressCreate(variables: CompanyAddressCreateMutationVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<CompanyAddressCreateMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<CompanyAddressCreateMutation>(CompanyAddressCreateDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'companyAddressCreate', 'mutation', variables);
    },
    companyAddressDelete(variables: CompanyAddressDeleteMutationVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<CompanyAddressDeleteMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<CompanyAddressDeleteMutation>(CompanyAddressDeleteDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'companyAddressDelete', 'mutation', variables);
    },
    companyAddressList(variables: CompanyAddressListQueryVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<CompanyAddressListQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<CompanyAddressListQuery>(CompanyAddressListDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'companyAddressList', 'query', variables);
    },
    companyAddressUpdate(variables: CompanyAddressUpdateMutationVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<CompanyAddressUpdateMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<CompanyAddressUpdateMutation>(CompanyAddressUpdateDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'companyAddressUpdate', 'mutation', variables);
    },
    customerAddressCreate(variables: CustomerAddressCreateMutationVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<CustomerAddressCreateMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<CustomerAddressCreateMutation>(CustomerAddressCreateDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'customerAddressCreate', 'mutation', variables);
    },
    customerAddressDelete(variables: CustomerAddressDeleteMutationVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<CustomerAddressDeleteMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<CustomerAddressDeleteMutation>(CustomerAddressDeleteDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'customerAddressDelete', 'mutation', variables);
    },
    customerAddressList(variables: CustomerAddressListQueryVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<CustomerAddressListQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<CustomerAddressListQuery>(CustomerAddressListDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'customerAddressList', 'query', variables);
    },
    customerAddressUpdate(variables: CustomerAddressUpdateMutationVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<CustomerAddressUpdateMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<CustomerAddressUpdateMutation>(CustomerAddressUpdateDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'customerAddressUpdate', 'mutation', variables);
    },
    contactRegister(variables: ContactRegisterMutationVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<ContactRegisterMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<ContactRegisterMutation>(ContactRegisterDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'contactRegister', 'mutation', variables);
    },
    companyCreate(variables: CompanyCreateMutationVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<CompanyCreateMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<CompanyCreateMutation>(CompanyCreateDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'companyCreate', 'mutation', variables);
    },
    customerRegister(variables: CustomerRegisterMutationVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<CustomerRegisterMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<CustomerRegisterMutation>(CustomerRegisterDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'customerRegister', 'mutation', variables);
    },
    customerDelete(variables: CustomerDeleteMutationVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<CustomerDeleteMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<CustomerDeleteMutation>(CustomerDeleteDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'customerDelete', 'mutation', variables);
    },
    getAuthByEmail(variables: GetAuthByEmailQueryVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<GetAuthByEmailQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<GetAuthByEmailQuery>(GetAuthByEmailDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'getAuthByEmail', 'query', variables);
    },
    contact(variables: ContactQueryVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<ContactQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<ContactQuery>(ContactDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'contact', 'query', variables);
    },
    customer(variables: CustomerQueryVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<CustomerQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<CustomerQuery>(CustomerDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'customer', 'query', variables);
    },
    passwordResetLink(variables: PasswordResetLinkMutationVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<PasswordResetLinkMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<PasswordResetLinkMutation>(PasswordResetLinkDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'passwordResetLink', 'mutation', variables);
    },
    contactUpdate(variables: ContactUpdateMutationVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<ContactUpdateMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<ContactUpdateMutation>(ContactUpdateDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'contactUpdate', 'mutation', variables);
    },
    customerUpdate(variables: CustomerUpdateMutationVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<CustomerUpdateMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<CustomerUpdateMutation>(CustomerUpdateDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'customerUpdate', 'mutation', variables);
    },
    addCartItem(variables: AddCartItemMutationVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<AddCartItemMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<AddCartItemMutation>(AddCartItemDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'addCartItem', 'mutation', variables);
    },
    createAnonCart(variables?: CreateAnonCartMutationVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<CreateAnonCartMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<CreateAnonCartMutation>(CreateAnonCartDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'createAnonCart', 'mutation', variables);
    },
    createAuthCart(variables: CreateAuthCartMutationVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<CreateAuthCartMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<CreateAuthCartMutation>(CreateAuthCartDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'createAuthCart', 'mutation', variables);
    },
    deleteCartItem(variables: DeleteCartItemMutationVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<DeleteCartItemMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<DeleteCartItemMutation>(DeleteCartItemDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'deleteCartItem', 'mutation', variables);
    },
    deleteCart(variables: DeleteCartMutationVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<DeleteCartMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<DeleteCartMutation>(DeleteCartDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'deleteCart', 'mutation', variables);
    },
    getCart(variables: GetCartQueryVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<GetCartQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<GetCartQuery>(GetCartDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'getCart', 'query', variables);
    },
    cartSetUser(variables: CartSetUserMutationVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<CartSetUserMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<CartSetUserMutation>(CartSetUserDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'cartSetUser', 'mutation', variables);
    },
    updateCartItem(variables: UpdateCartItemMutationVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<UpdateCartItemMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<UpdateCartItemMutation>(UpdateCartItemDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'updateCartItem', 'mutation', variables);
    },
    rootCategories(variables?: RootCategoriesQueryVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<RootCategoriesQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<RootCategoriesQuery>(RootCategoriesDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'rootCategories', 'query', variables);
    },
    paymentCreate(variables: PaymentCreateMutationVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<PaymentCreateMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<PaymentCreateMutation>(PaymentCreateDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'paymentCreate', 'mutation', variables);
    },
    processCart(variables: ProcessCartMutationVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<ProcessCartMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<ProcessCartMutation>(ProcessCartDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'processCart', 'mutation', variables);
    },
    updateCartAddress(variables: UpdateCartAddressMutationVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<UpdateCartAddressMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<UpdateCartAddressMutation>(UpdateCartAddressDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'updateCartAddress', 'mutation', variables);
    },
    updateCartShippingCost(variables: UpdateCartShippingCostMutationVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<UpdateCartShippingCostMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<UpdateCartShippingCostMutation>(UpdateCartShippingCostDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'updateCartShippingCost', 'mutation', variables);
    },
    updateCart(variables: UpdateCartMutationVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<UpdateCartMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<UpdateCartMutation>(UpdateCartDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'updateCart', 'mutation', variables);
    },
    order(variables: OrderQueryVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<OrderQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<OrderQuery>(OrderDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'order', 'query', variables);
    },
    orders(variables: OrdersQueryVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<OrdersQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<OrdersQuery>(OrdersDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'orders', 'query', variables);
    },
    payment(variables: PaymentQueryVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<PaymentQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<PaymentQuery>(PaymentDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'payment', 'query', variables);
    },
    attributeDescriptions(variables?: AttributeDescriptionsQueryVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<AttributeDescriptionsQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<AttributeDescriptionsQuery>(AttributeDescriptionsDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'attributeDescriptions', 'query', variables);
    },
    productDefaultPrices(variables: ProductDefaultPricesQueryVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<ProductDefaultPricesQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<ProductDefaultPricesQuery>(ProductDefaultPricesDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'productDefaultPrices', 'query', variables);
    },
    getCluster(variables: GetClusterQueryVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<GetClusterQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<GetClusterQuery>(GetClusterDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'getCluster', 'query', variables);
    },
    getProducts(variables: GetProductsQueryVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<GetProductsQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<GetProductsQuery>(GetProductsDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'getProducts', 'query', variables);
    },
    searchProductsClusters(variables: SearchProductsClustersQueryVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<SearchProductsClustersQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<SearchProductsClustersQuery>(SearchProductsClustersDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'searchProductsClusters', 'query', variables);
    },
    searchSuggestionClusters(variables: SearchSuggestionClustersQueryVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<SearchSuggestionClustersQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<SearchSuggestionClustersQuery>(SearchSuggestionClustersDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'searchSuggestionClusters', 'query', variables);
    },
    authenticationAccountDates(variables: AuthenticationAccountDatesQueryVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<AuthenticationAccountDatesQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<AuthenticationAccountDatesQuery>(AuthenticationAccountDatesDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'authenticationAccountDates', 'query', variables);
    },
    forceOrderSetAsExported(variables: ForceOrderSetAsExportedMutationVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<ForceOrderSetAsExportedMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<ForceOrderSetAsExportedMutation>(ForceOrderSetAsExportedDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'forceOrderSetAsExported', 'mutation', variables);
    },
    orderSetStatus(variables: OrderSetStatusMutationVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<OrderSetStatusMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<OrderSetStatusMutation>(OrderSetStatusDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'orderSetStatus', 'mutation', variables);
    },
    paymentUpdate(variables: PaymentUpdateMutationVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<PaymentUpdateMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<PaymentUpdateMutation>(PaymentUpdateDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'paymentUpdate', 'mutation', variables);
    },
    getSitemapProducts(variables?: GetSitemapProductsQueryVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<GetSitemapProductsQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<GetSitemapProductsQuery>(GetSitemapProductsDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'getSitemapProducts', 'query', variables);
    },
    getPaymentStatus(variables?: GetPaymentStatusQueryVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<GetPaymentStatusQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<GetPaymentStatusQuery>(GetPaymentStatusDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'getPaymentStatus', 'query', variables);
    },
    getClusterUrlData(variables?: GetClusterUrlDataQueryVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<GetClusterUrlDataQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<GetClusterUrlDataQuery>(GetClusterUrlDataDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'getClusterUrlData', 'query', variables);
    }
  };
}
export type Sdk = ReturnType<typeof getSdk>;